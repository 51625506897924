//import React, { lazy } from 'react';
import { lazy } from 'react';
import {MAIN_LANG} from './main_lang';


//import Home from '../pages/home/home';
//import GPS from '../pages/gps/gps';
//import Trips from '../pages/trips/trips';
//import Hours from '../pages/hours/hours';
//import Help from '../pages/help/help';
export const Home =        lazy(() => import('../pages/home/home'));
export const Track =       lazy(() => import('../pages/tracking/tracking'));
export const Behavior =    lazy(() => import('../pages/behavior/behavior'));
//export const Fleet =       lazy(() => import('../pages/fleet/fleet'));
export const Health =      lazy(() => import('../pages/health/health'));
export const AlertManager= lazy(() => import('../pages/alertmgr/alertmgr'));
export const FleetManager= lazy(() => import('../pages/fleetmgr/fleetmgr'));
export const UserManager = lazy(() => import('../pages/usermgr/usermgr'));
export const UserSettings= lazy(() => import('../pages/user/user'));
export const Help =        lazy(() => import('../pages/help/help'));

const msg_main = MAIN_LANG.EN; 

// Fillout your page array here:
const DimePages = 
    [   {key: "h1", order:  1, menu:  true, admin: false, path: "/",         
            title: msg_main.HOME_PAGE,      page_obj: Home,         icon: msg_main.ICON_HOME,       descr: msg_main.HOME_PAGE_DESC },
        {key: "t1", order:  2, menu:  true, admin: false, path: "/tracking", 
            title: msg_main.TRACKING_PAGE,  page_obj: Track,        icon: msg_main.ICON_ROUTE,      descr: msg_main.TRACK_PAGE_DESC },
        {key: "a1", order:  3, menu:  true, admin: false, path: "/behavior",   
            title: msg_main.ALERTS_PAGE,  page_obj: Behavior,     icon: msg_main.ICON_CAR_CRASH,    descr: msg_main.BEHAVE_PAGE_DESC },
        {key: "v1", order:  6, menu:  true, admin: false, path: "/health",   
            title: msg_main.HEALTH_PAGE,    page_obj: Health,       icon: msg_main.ICON_AMBULANCE,  descr: msg_main.HEALTH_PAGE_DESC },
        {key: "am", order:  7, menu:  true, admin:  true, path: "/alertmgr", 
            title: msg_main.ALERTMGR_PAGE,  page_obj: AlertManager, icon: msg_main.ICON_ALERT_GEAR, descr: msg_main.ALERT_MGR_PAGE_DESC },
        {key: "fm", order:  8, menu:  true, admin:  true, path: "/fleetmgr", 
            title: msg_main.FLEETMGR_PAGE,  page_obj: FleetManager, icon: msg_main.ICON_TRUCK_GEAR, descr: msg_main.FLEET_MGR_PAGE_DESC },
        {key: "um", order:  9, menu:  true, admin:  true, path: "/usermgr",  
            title: msg_main.USERMGR_PAGE,   page_obj: UserManager,  icon: msg_main.ICON_USER_GEAR,  descr: msg_main.USER_MGR_PAGE_DESC },
        {key: "u1", order: 10, menu: false, admin: false, path: "/user",     
            title: msg_main.USER_PAGE,      page_obj: UserSettings, icon: msg_main.ICON_USER_GEAR, descr: msg_main.USER_PAGE_DESC},
        {key: "hp", order: 99, menu: false, admin: false, path: "/help",     
            title: msg_main.HELP_PAGE,      page_obj: Help,         icon: "", descr: "" },
    ];

    /*  *******************************************************
        key:  any unique id
        path:  path from root
        label:  text to display on menu button/pill/tab
        title:  the hovertext (title="" attribute for html)
        page:  the actual component reference imported at top
        icon:  menu icon
    *********************************************************** */

export default DimePages;