import { createStore, combineReducers, compose } from 'redux';
import userReducer from './reducers/user';
import { loadState, saveState} from './localStorage';
import _ from 'lodash';  //libary for throttle()

const rootReducer = combineReducers({
	user: userReducer
});

const persistedState = loadState();  //load redux store from localStorage
const composeEnhancers = compose(persistedState);

const store = createStore(rootReducer, composeEnhancers);

// store.subscribe(() => {
// 	saveState({
// 	  user: store.getState().user
// 	});
//   });

//save redux to localStorage, but throttle so it doesn't fire more than every 1 second
store.subscribe(_.throttle(() => {
	saveState({
		user: store.getState().user
	});
}, 1000));  

const configureStore = () => {
	//return createStore(rootReducer, composeEnhancers());
	return store;
};

export default configureStore;