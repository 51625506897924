import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {    faExclamationCircle, faSignInAlt, faCog, faBell, faBars, faClock, faTools, faCircle, faAmbulance, faCalendarAlt, 
            faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faGlobe, faTachometerAlt, faRoute, faCircleNotch, 
            faTruck, faTruckPickup, faUser, faTrafficLight, faCrosshairs, faHome, faSatelliteDish, faSatellite, faCarCrash, 
            faChevronDown, faChevronUp, faMapMarkerAlt, faDrawPolygon, faVectorSquare, faSquare, faTrashAlt, faEdit, faBan, 
            faCheckCircle, faTimesCircle, faBackspace, faCommentAlt, faInfoCircle, faAsterisk, faStar
            //faWaveSquare, faFileMedicalAlt, faChartLine, faDatabase, faBox, 
        } from '@fortawesome/free-solid-svg-icons';

const LOCAL_THISYEAR = new Date().getFullYear();
// const COPYRIGHT_YEAR = (LOCAL_THISYEAR===2020 ? "2020" : "2020-" + LOCAL_THISYEAR);
const COPYRIGHT_YEAR = "2019-" + LOCAL_THISYEAR;

export const MAIN_LANG = {
    EN:{
        // date-fns formats
        FORMAT_DATETIME: "M/d/yyyy h:mma",              //   9/29/2020 4:45AM
        FORMAT_DATETIME_YY: "M/d/yy h:mma",              //   9/29/20 4:45AM
        FORMAT_DATETIME_SEC: "M/d/yyyy h:mm:ssaaaaa",   //   9/29/2020 4:45:21a
        FORMAT_DATETIME_SECAP: "M/d/yyyy h:mm:ss aaaa",   //   9/29/2020 4:45:21 a.m.
        FORMAT_TIME_SEC: "h:mm:ssaaaaa",   //   4:45:21a
        FORMAT_DATETIME_ISO: "yyyy-MM-dd'T'hh:mm:ss.SSSS00",   //   2021-04-11T18:53:55.153600
        FORMAT_DATE: "M/d/yyyy",                        //   9/29/2020
        FORMAT_HOUR_1224: "12",  //used to display alert hours as am/pm or 24-hr (possible values: 12 or 24)
        LOCALE: 'us-EN', 
        CAL_FORMAT_DATE: "MM/DD/YYYY",                        //   09/01/2020
        MONTH_LABELS: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        DAY_LABELS: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        WEEK_START_DAY: 0,  //0-Sunday, 1-Monday for EU, etc
        DISTANCE_UNIT: "mi", //mi = miles, km = kilometers

        TITLE: "DIME Portal",
        SHORT_NAME: "DIME",
        LONG_NAME: "Diagnostic Information Management Environment", 

        //page links
        HOME_PAGE: "Live Fleet View",  // "Dashboard",  "Command Center",
        TRACKING_PAGE: "Vehicle Tracking",
        TRIPS_PAGE: "Fleet Summary",
        HOURS_PAGE: "Hours of Operation",
        ALERTS_PAGE: "Driver Behavior",
        USER_PAGE: "User Maintenance", 
        HEALTH_PAGE: "Vehicle Health",
        ALERTMGR_PAGE: "Alert Manager",
        // FLEETMGR_PAGE: "Fleet Manager",
        // USERMGR_PAGE: "User Manager", 
        FLEETMGR_PAGE: "Vehicle Setup",
        USERMGR_PAGE: "Administration", 
        HELP_PAGE: "Help",

        //status messages
        LOADING: " Loading page...",
        LOADING_LOGIN: "loading...",
        ERROR_404: "Oops, that's not a page. 404 ERROR",
        REFRESH_APPVERSION: "A new version of this application is available.",
        REFRESH_RELOAD: "Reload New Version",
        REFRESH_CTRLF5: "...or press CTRL+F5 (Windows), COMMAND+SHIFT+R (Mac) to force browser to reload.",

        //login/logout
        USERNAME: "Username/email",
        PASSWORD: "Password",
        LOGIN: "Login",
        LOGIN_TITLE: "Please Login",
        LOGOUT: "Logout",
        COPYRIGHT: "Copyright © " + COPYRIGHT_YEAR + " GC Associates USA, LLC. All rights reserved.",
        //COPYRIGHT: "Copyright © 2020 GC Associates USA, LLC. All rights reserved.",
        NOT_AUTHORIZED: "You are not authorized to access this system. Please contact the System Administrator. ",
        REQUIRES_AUTH: "This website requires authorization. Click here:",
        CONTACT_SYS: "Need access? Please contact the System Administrator. ",

        // Buttons
        SAVE_BUTTON: "Save",
        CANCEL_BUTTON: "Cancel",
        CREATE_BUTTON: "Create",
        DELETE_BUTTON: "Delete",
        SEARCH_BUTTON: "Search",
        RESET_BUTTON: "reset",  //yes, this is lowercase
        CLOSE_BUTTON: "Close", 
        CLOSE_TAB: "Close Tab", 
        YES_BUTTON: "Yes", 
        NO_BUTTON: "No", 
        ON_SWITCH: "On", 
        OFF_SWITCH: "Off", 

        // Dropdowns
        CHOOSE_FLEET: "Choose Fleet ",
        CREATE_EDIT_FLEETS: "Manage Fleet Vehicles... ",
        FIND_VEHICLE: "Find a Vehicle ",
        SELECT_VEHICLE: "Select a Vehicle ",
        ALL_VEHICLES: "All Vehicles",
        UNASSIGNED_FLEET: "Unassigned Vehicles", 
        CLEAR_FILTER: "Clear Filter", 
        SELECT_ALERTTYPE: "Select Alert Type", 
        ALL_ALERTTYPES: "All Alert Types", 
        NO_DATE: "No Date", 
        ALL_DATES: "All Dates", 
        ALL_BUFFERS: "All Buffers",

        //misc
        HYPHEN: <> &ndash; </>,
        
        // Icons (incase we want to change from FontAwesome, put all icons here)
        ICON_SPINNER: <FontAwesomeIcon icon={faCircleNotch} spin />,
        ICON_HOME: <FontAwesomeIcon icon={faHome} />,
        ICON_CALENDAR: <FontAwesomeIcon icon={faCalendarAlt} transform="shrink-1 up-1 right-1" />,
        ICON_TOOLS: <FontAwesomeIcon icon={faTools} />,
        ICON_EXCLAMATION: <FontAwesomeIcon icon={faExclamationCircle} />,
        ICON_LOGOUT: <FontAwesomeIcon icon={faSignInAlt} />,
        ICON_LOGOUT_SWING: <FontAwesomeIcon className="hrd-icon-swing" icon={faSignInAlt} />,  //swing animation on hover, like the pro-slider icons
        ICON_GEAR: <FontAwesomeIcon icon={faCog} />,
        ICON_GEAR_SWING: <FontAwesomeIcon className="hrd-icon-swing" icon={faCog} />,
        ICON_ALERT: <FontAwesomeIcon icon={faBell} />,
        ICON_ALERT_SWING: <FontAwesomeIcon className="hrd-icon-swing" icon={faBell} />,
        ICON_ALERT_REDDOT: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faBell} /><FontAwesomeIcon icon={faCircle} color="tomato" transform="shrink-7 down-7 left-6"/></span>,
        //ICON_ALERT_REDDOT_SWING: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faBell} className="hrd-icon-swing" /><FontAwesomeIcon icon={faCircle} color="tomato" transform="shrink-7 down-7 left-6"/></span>,
        ICON_ALERT_REDDOT_SWING: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faBell} className="hrd-icon-swing" /><FontAwesomeIcon icon={faCircle} color="#f00a00" transform="shrink-7 up-6 right-6"/></span>,
        ICON_ALERT_YELLOWDOT_SWING: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faBell} className="hrd-icon-swing" /><FontAwesomeIcon icon={faCircle} color="#ffaa00" transform="shrink-7 up-6 right-6"/></span>,
        ICON_ALERT_GEAR: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faBell} transform="shrink-2 up-2 left-4" /><FontAwesomeIcon icon={faCog} transform="shrink-3 down-4 right-5"/></span>,
        ICON_BARS: <FontAwesomeIcon icon={faBars} />,
        ICON_ARROW_LEFT: <FontAwesomeIcon icon={faAngleLeft} />,
        ICON_ARROW_RIGHT: <FontAwesomeIcon icon={faAngleRight} />,
        ICON_ARROW_DOUBLE_LEFT: <FontAwesomeIcon icon={faAngleDoubleLeft} />,
        ICON_ARROW_DOUBLE_RIGHT: <FontAwesomeIcon icon={faAngleDoubleRight} />,
        ICON_ARROW_LEFT_SWING: <FontAwesomeIcon className="hrd-icon-swing" icon={faAngleLeft} />,
        ICON_ARROW_RIGHT_SWING: <FontAwesomeIcon className="hrd-icon-swing" icon={faAngleRight} />,
        ICON_ARROW_DOUBLE_LEFT_SWING: <FontAwesomeIcon className="hrd-icon-swing" icon={faAngleDoubleLeft} />,
        ICON_ARROW_DOUBLE_RIGHT_SWING: <FontAwesomeIcon className="hrd-icon-swing" icon={faAngleDoubleRight} />,
        ICON_GLOBE: <FontAwesomeIcon icon={faGlobe} />,
        ICON_MAP_MARKER: <FontAwesomeIcon icon={faMapMarkerAlt} />,
        ICON_MAP_MARKER_REDX: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faMapMarkerAlt} transform="shrink-4 left-0.5" opacity="1" /><FontAwesomeIcon icon={faBan} transform="grow-3" color="firebrick" opacity="0.8" /></span>, 
        ICON_MAP_MARKER_FADE: <FontAwesomeIcon icon={faMapMarkerAlt} color="#ababab" />,
        ICON_MAP_POPUP: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faCommentAlt} /><FontAwesomeIcon icon={faCommentAlt}  color="#ffffff" transform="shrink-2" opacity="0.9" /></span>,
        ICON_INFO: <FontAwesomeIcon icon={faInfoCircle} />,
        ICON_SATELLITE: <FontAwesomeIcon icon={faSatellite} />,
        ICON_SATELLITEDISH: <FontAwesomeIcon icon={faSatelliteDish} />,
        ICON_TACH: <FontAwesomeIcon icon={faTachometerAlt} />,
        ICON_ROUTE: <FontAwesomeIcon icon={faRoute} />,
        ICON_CLOCK: <FontAwesomeIcon icon={faClock} />,
        ICON_TRUCK: <FontAwesomeIcon icon={faTruck} />,
        ICON_TRUCK_GEAR: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faTruck} transform="shrink-2 up-2 left-4" /><FontAwesomeIcon icon={faCog} transform="shrink-3 down-4 right-7"/></span>,
        //ICON_TRUCK_404: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faTruck} /><span className="fa-layers-text" style={{color: "whitesmoke", fontSize: "0.5rem", marginLeft: "-5px", marginTop: "-2px"}} data-fa-transform="shrink-1 down-3">404</span></span>,
        ICON_AMBULANCE: <FontAwesomeIcon icon={faAmbulance} />,
        ICON_USER_GEAR: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faUser} transform="shrink-2 up-2 left-4" /><FontAwesomeIcon icon={faCog} transform="shrink-3 down-4 right-5"/></span>,
        ICON_TRAFFIC_LIGHT: <FontAwesomeIcon icon={faTrafficLight} />,
        ICON_TRAFFIC_LIGHT_LG: <FontAwesomeIcon icon={faTrafficLight} transform="grow-3" />,
        ICON_TRAFFIC_CROSSHAIRS: <FontAwesomeIcon icon={faCrosshairs} />,
        ICON_TRAFFIC_CROSSHAIRS_LG: <FontAwesomeIcon icon={faCrosshairs} transform="grow-4" />,
        ICON_MAP_GEOFENCE: <FontAwesomeIcon icon={faDrawPolygon} />,
        ICON_MAP_GEOFENCE_LG: <FontAwesomeIcon icon={faDrawPolygon} transform="grow-4" />,
        ICON_MAP_DRAW_SQUARE: <FontAwesomeIcon icon={faVectorSquare} />,
        ICON_MAP_DRAW_POLYGON: <FontAwesomeIcon icon={faDrawPolygon} transform="grow-3" />,
        ICON_MAP_DRAW_CIRCLE: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faCircle} transform="grow-2" /><FontAwesomeIcon icon={faCircle} color="#d5d5e6" transform="shrink-1" /><FontAwesomeIcon icon={faCircle} transform="shrink-11" /><FontAwesomeIcon icon={faCircle} transform="shrink-11 left-8" /></span>,
        ICON_MAP_DRAW_CLEAR: <FontAwesomeIcon icon={faBackspace} transform="shrink-1" />,
        ICON_CAR_CRASH: <FontAwesomeIcon icon={faCarCrash} />,
        ICON_TRUCK_TRUCK: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faTruckPickup} transform="shrink-2 up-4 left-4" /><FontAwesomeIcon icon={faTruckPickup} transform="shrink-3 down-5 right-5"/></span>,
        ICON_TRUCK_CANCEL: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faTruck} transform="shrink-7" /><FontAwesomeIcon icon={faBan} transform="grow-4" color="firebrick" /></span>,
        ICON_TRUCK_ASSIGN: <FontAwesomeIcon icon={faTruck} transform="shrink-3"/>,
        // ICON_SORT_UP: <FontAwesomeIcon icon={faChevronUp} />,
        // ICON_SORT_DOWN: <FontAwesomeIcon icon={faChevronDown} />,
        ICON_COLUMN_SORT_UP: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faChevronUp} transform="up-1" /><FontAwesomeIcon opacity="0.4" icon={faChevronDown} transform="down-12"/></span>,
        ICON_COLUMN_SORT_DOWN: <span className="fa-layers fa-fw"><FontAwesomeIcon opacity="0.4" icon={faChevronUp} transform="up-1" /><FontAwesomeIcon icon={faChevronDown} transform="down-12"/></span>,
        ICON_COLUMN_SORT_NONE: <span className="fa-layers fa-fw"><FontAwesomeIcon opacity="0.4" icon={faChevronUp} transform="up-1" /><FontAwesomeIcon opacity="0.4" icon={faChevronDown} transform="down-12"/></span>,
        ICON_DELETE: <FontAwesomeIcon icon={faTrashAlt} />,
        ICON_EDIT: <FontAwesomeIcon icon={faEdit} />,
        ICON_GEOFENCE_INSIDE: <FontAwesomeIcon icon={faBan} opacity="0.8" transform="grow-4" color="firebrick"/>,
        ICON_GEOFENCE_OUTSIDE: <FontAwesomeIcon icon={faCheckCircle} opacity="0.8" transform="grow-4" color="#008000"/>, //color="#008000"
        // ICON_GEOFENCE_INSIDE: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faDrawPolygon} transform="right-2 up-1" color="firebrick" opacity="0.8" /><FontAwesomeIcon icon={faCompressArrowsAlt} transform="grow-4" color="dodgerblue" opacity="0.9"/></span> ,
        // ICON_GEOFENCE_INSIDE: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faCircle} transform="grow-5" color="firebrick" opacity="0.8" /><FontAwesomeIcon icon={faHandPaper} transform="shrink-2" color="#ffffff" /></span> ,
        // ICON_GEOFENCE_OUTSIDE: <FontAwesomeIcon icon={faExpandArrowsAlt} color="dodgerblue"/>, //color="#008000"
        // ICON_BLACKBOX: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faBox} transform="grow-6"/><FontAwesomeIcon icon={faDatabase} opacity="0.8" color="#efefef" transform="shrink-3 down-2"  /></span>,  // faDatabase, faBox,
        //ICON_BLACKBOX: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faBox} transform="grow-7 up-1"/><FontAwesomeIcon icon={faWaveSquare} color="#efefef" transform="shrink-6 down-4"  /></span>,  // faDatabase, faBox,
        // ICON_BLACKBOX: <FontAwesomeIcon icon={faBox} />, 
        //ICON_MEDICAL_FILE: <FontAwesomeIcon icon={faFileMedicalAlt} />, 
        //ICON_CHART_BUFFER: <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faChartLine} transform="grow-2"/><FontAwesomeIcon icon={faSquare} style={{transform: "rotate(45deg)"}} transform="shrink-8 right-7 up-5" color="#efefef" /></span>, //transform="shrink-8 right-6 up-5"   transform={{ rotate: 45 }}
        //ICON_CHART_BUFFER: <FontAwesomeIcon icon={faChartLine}/>,
        //ICON_DATA: <FontAwesomeIcon icon={faDatabase} />, 
        ICON_CIRCLEX: <FontAwesomeIcon icon={faTimesCircle} />, 
        ICON_ASTERISK: <FontAwesomeIcon icon={faAsterisk} />, 
        ICON_BLUESTAR: <FontAwesomeIcon icon={faStar} transform="shrink-4" color="dodgerblue" />, 
                
        MAP_ICON_4X4: "4x4",
        MAP_ICON_BUS: "Bus",
        MAP_ICON_CAR: "Car",
        MAP_ICON_PICKUP: "Pickup",
        MAP_ICON_RESCUE: "Rescue Vehicle",
        MAP_ICON_SPORTU: "Sport Utility",
        MAP_ICON_TRACTOR: "Construction",
        MAP_ICON_TRUCK: "Truck",
        MAP_ICON_VAN: "Van",
        MAP_ICON_COLOR_YELLOW: "Behavior Alert", 
        MAP_ICON_COLOR_BROWN: "Information", 
        MAP_ICON_COLOR_ALERT: "Vehicle Fault", 
        MAP_ICON_COLOR_RED: "Vehicle Fault", 
        MAP_ICON_COLOR_GRAY: "Parked", 
        MAP_ICON_COLOR_GREEN: "Normal", 
        MAP_ICON_COLOR_CYAN: "Idling", 

        MAP_ICON_LEGEND_YELLOW: "Driver Behavior Alert", 
        MAP_ICON_LEGEND_BROWN: "Information", 
        MAP_ICON_LEGEND_ALERT: "Vehicle Health Fault", 
        MAP_ICON_LEGEND_GRAY: "Parked/Off", 
        MAP_ICON_LEGEND_GREEN: "Normal Status", 
        MAP_ICON_LEGEND_CYAN: "Idling/Stationary", 
        MAP_LEGEND_MARKERS: <>Vehicle<br/>Markers</>,
        MAP_LEGEND_POINTS: "Point Markers",
        MAP_LEGEND_NOTE: "Darkened markers represent vehicles dormant more than a day.",
        MAP_LEGEND_LIVE: "Live", 
        MAP_LEGEND_DORMANT: "Dormant", 

        MAP_FENCE_INSIDE: "Inside Alarm Geofence",
        MAP_FENCE_OUTSIDE: "Outside Alarm Geofence",

        ALERT_TYPE_MAXSPEED: "Max-speed Violation", 
        ALERT_TYPE_SPEEDING: "Speeding Violation", 
        ALERT_TYPE_GEOFENCE: "Geofence Violation", 
        ALERT_TYPE_HOURS: "Hours of Operation", 
        ALERT_TYPE_HARD: "Hard Driving", 
        ALERT_TYPE_FAULT: "Vehicle Fault", 
        ALERT_TYPE_ACCIDENT: "Accident Detection", 

        ALERT_TYPE_MAXSPEED_SHORT: "Speed", 
        ALERT_TYPE_SPEEDING_SHORT: "Speed", 
        ALERT_TYPE_GEOFENCE_SHORT: "Geofence", 
        ALERT_TYPE_HOURS_SHORT: "Hours", 
        ALERT_TYPE_HARD_SHORT: "Hard Drv", 
        ALERT_TYPE_FAULT_SHORT: "Fault", 
        ALERT_TYPE_ACCIDENT_SHORT: "Accident",      
        
        ALERT_MGS_GEOFENCE_INSIDE: "Inside GeoFence",
        ALERT_MGS_GEOFENCE_OUTSIDE: "Outside GeoFence",
        ALERT_MGS_GEOFENCE_RADIUS: "Circle Radius",
        ALERT_MGS_HOURS_DAYLIMIT_SHRT: "Daily Limit",
        ALERT_MGS_HOURS_DAYLIMIT: "Over Daily Limit",
        ALERT_MGS_HOURS_DAYLIMIT_HOURS: "hour(s)",
        ALERT_MGS_HOURS_ALLOW: "Violation Hour",
        ALERT_MGS_HOURS_SAFE: "Safe Hours of Operations",
        ALERT_MGS_FAULT: "Fault",
        ALERT_MGS_TRIGGER: "Trigger",

        //Pages
        //Home Page
        HOME_PAGE_DESC: "Map current position of all vehicles.", 
        HOME_PLEASE_CHOOSE_FLEET: "Please choose a fleet.",
        HOME_TRAFFIC: "traffic",
        HOME_SHOW_TRAFFIC: "Show Traffic",
        HOME_HIDE_TRAFFIC: "Hide Traffic",
        HOME_CENTER: "center",
        HOME_CENTER_MAP: "Set Map on Fleet Center",
        HOME_SEE_ALL: "see all",
        HOME_SEE_MAP: "Center Map on All Vehicles",
        HOME_GEOFENCE: "fences",
        HOME_SHOW_GEOFENCE: "Show Geofences",
        HOME_HIDE_GEOFENCE: "Hide Geofences",
        HOME_LOADING_MAP: "Loading map...",
        HOME_LOADING_MAP_ERROR: "Error Loading map.",
        HOME_MAP_NO_VEHICLES: "No vehicles found.",

        HOME_POPUP_SPEED: "Speed",
        HOME_POPUP_MAXSPEED: "Max Speed",
        HOME_POPUP_AMBERLAMP: "Engine Light",
        HOME_POPUP_LAMPON: "On",
        HOME_POPUP_LAMPOFF: "Off",
        HOME_POPUP_DEVICE_NBR: "Device Serial Nbr",
        HOME_POPUP_FILE: "File Id",
        HOME_POPUP_FILETS: "S3 File Time",
        HOME_POPUP_DIMETS: "Dime Event Time",
                
        HOME_POPUP_STATUS: "Status",
        HOME_POPUP_FLEETSTATUS: "Fleet Status",
        HOME_CLICK_FOR_INFO: "Click marker for vehicle info.", 
        HOME_TRACK_VEHICLE_LINK: "Track Vehicle Route", 

        //Vehicle Tracking Page
        TRACK_PAGE_DESC: "Map individual vehicle routes and alerts.", 
        TRACK_MAP_SELECT_MSG: "Select a fleet, vehicle, and route day.",
        TRACK_MAP_NO_DATE: "No route day data for this vehicle yet. No map to display.",
        TRACK_CENTER: "center",
        TRACK_CENTER_MAP: "Set Map on Fleet Center",
        TRACK_SEE_ALL: "fit all",
        TRACK_SEE_MAP: "Fit Map on All Route Points",
        TRACK_GEOFENCE: "fences",
        TRACK_SHOW_GEOFENCE: "Show Geofences",
        TRACK_HIDE_GEOFENCE: "Hide Geofences",     
        TRACK_ROUTE_START: "start",   
        TRACK_ROUTE_END: "end",
        TRACK_ROUTE_MILEAGE: "mileage",
        TRACK_HDR_TIME: "Time",
        TRACK_HDR_SPEED: "Speed",
        TRACK_HDR_ALERT_TYPE: "Type", 
        TRACK_HDR_ALERT_TIME: "Alert Time", 
        TRACK_PAGE_OF: "Page %1 of %2",  // where the %1 is the current page, and %2 is the total page count
        TRACK_ROUTE_START_LEGEND: "Start Route",   
        TRACK_ROUTE_END_LEGEND: "End Route",
        TRACK_ROUTE_NO_GPS: "GPS NOT AVAILABLE!", 
        TRACK_ROUTE_MAP_POPUP: "Route Point Info",
        TRACK_ROUTE_FOOTNOTE_MULTISAF: "SAF Point in Multi-file",

        //Driver Behavior Page
        BEHAVE_PAGE_DESC: "Explore alerts triggered by driver.", 
        BEHAVE_HDR_ALERT_TIME: "Alert Time", 
        BEHAVE_HDR_ALERT_TYPE: "Type", 
        BEHAVE_HDR_ALERT_NAME: "Alert Name", 
        BEHAVE_HDR_VEHICLE_NAME: "Vehicle", 
        BEHAVE_HDR_DRIVER: "Driver", 
        BEHAVE_HRD_MAP: "Map",
        BEHAVE_HDR_ALERT_MSG: "Alert Message", 
        BEHAVE_PAGE_OF: "Page %1 of %2",  // where the %1 is the current page, and %2 is the total page count
        BEHAVE_NO_RESULTS: "No behavior alerts found", 
        BEHAVE_NO_RESULTS_FOR_FILTER: " for: ", 
        BEHAVE_NO_RESULTS_FOR_GENERIC: " for this fleet/vehicle.",
        BEHAVE_SEARCHING: "Searching alerts...", 

        //Vehicle Health Page
        HEALTH_PAGE_DESC: "Explore faults triggered by the vehicle.", 
        HEALTH_HDR_ALERT_TIME: "Alert Time", 
        HEALTH_HDR_ALERT_TYPE: "Type", 
        HEALTH_HDR_ALERT_NAME: "Alert Name", 
        HEALTH_HDR_VEHICLE_NAME: "Vehicle", 
        HEALTH_HDR_DRIVER: "Driver", 
        HEALTH_HRD_MAP: "Map",
        HEALTH_HRD_BUFFER: "Buffer",
        HEALTH_HDR_ALERT_MSG: "Alert Message", 
        HEALTH_PAGE_OF: "Page %1 of %2",  // where the %1 is the current page, and %2 is the total page count
        HEALTH_NO_RESULTS: "No vehicle faults found",  // where the %1 is the current page, and %2 is the total page count
        HEALTH_NO_RESULTS_FOR_FILTER: " for: ", 
        HEALTH_NO_RESULTS_FOR_GENERIC: " for this fleet/vehicle.",

        HEALTH_SEARCHING: "Searching alerts...", 
        HEALTH_TAB_FLEET: "Fleet",
        HEALTH_TAB_EVENT_TIME: "Event Time",
        HEALTH_TAB_EVENT_TIME_LABEL: "Event\nTime",
        HEALTH_TAB_EVENT_BUFFER: "Event Buffer Window",
        HEALTH_TAB_LOADING_BUFFER: "Loading buffer events...",
        HEALTH_TAB_NO_BUFFERS: "No buffer data found.",
        HEALTH_TAB_MULTIPLE_EVENTS: "Multiple",
        HEALTH_TAB_TRIGGER_EVENT: "TR",
        HEALTH_TAB_FAULT_EVENT: "Fault ",

        //Alert Manager Page
        ALERT_MGR_PAGE_DESC: "Create, edit, and manage driver behavior alerts.", 
        ALERT_MGR_BUTTON_CREATEGEO: "Create Geofence",
        ALERT_MGR_BUTTON_CREATEHOURS: "Create Hours Alarm",
        ALERT_MGR_CREATE_GEOALARM: "Create New Geofence Alarm",
        ALERT_MGR_CREATE_HOURSALARM: "Create New Hours of Operation Alarm",
        ALERT_MGR_EDIT_GEOALARM: "Edit Geofence Alarm",
        ALERT_MGR_EDIT_HOURSALARM: "Edit Hours of Operation Alarm",
        ALERT_MGR_ENTER_ALARMNAME: "Enter Alarm Name (required)",
        ALERT_MGR_NAME_REQUIRED: "<< name required",
        ALERT_MGR_REQUIRED: "<< required",
        ALERT_MGR_ALARM: "Alarm",
        ALERT_MGR_ALARM_INSIDE: "inside",
        ALERT_MGR_ALARM_OUTSIDE: "outside",
        ALERT_MGR_ALARM_TITLE_INSIDE: "Set Inside Alarm",
        ALERT_MGR_ALARM_TITLE_OUTSIDE: "Set Outside Alarm",
        ALERT_MGR_GEO_DEF: "Geofence Definition",
        ALERT_MGR_GEO_ADD_SQUARE: "Add Square Fence",
        ALERT_MGR_GEO_ADD_POLY: "Add Polygon Fence",
        ALERT_MGR_GEO_ADD_CIRCLE: "Add Circle Fence",
        ALERT_MGR_GEO_ADD_CLEAR: "Clear Fence",
        ALERT_MGR_RADIUS: "radius",
        ALERT_MGR_GEO_INSTRUCTIONS: <>1. Center and zoom map over general area.<br/>2. Add geofence shape from toolbar. <FontAwesomeIcon icon={faVectorSquare} transform="shrink-3" />, <FontAwesomeIcon icon={faDrawPolygon} />, etc.<br/>3. Drag shape anchors to adjust size.</>, 
        ALERT_MGR_GEO_ALARM: <><FontAwesomeIcon icon={faCircle} color="#e00a00" /> Inside Alarm sends alert when vehicle <em>enters</em> geofence.<br/><FontAwesomeIcon icon={faSquare} color="#008000" /> Outside Alarm sends alert when vehicle <em>exits</em> geofence.</>, 
        ALERT_MGR_NO_RESULTS_FOR_GENERIC: "No alerts found for this fleet.", 
        ALERT_MGR_NO_RESULTS_FOR_FILTER: "No alerts found for fleet: ", 
        ALERT_MGR_SEARCHING: "Searching fleet alerts...", 
        ALERT_MGR_HDR_ALERT_NAME: "Alert Name", 
        ALERT_MGR_HDR_ALERT_TYPE: "Type", 
        ALERT_MGR_HDR_CREATE_TIME: "Create Time", 
        ALERT_MGR_HDR_DEFINITION: "Definition", 
        ALERT_MGR_HDR_OTHER_FLEETS: "Other Fleets", 
        ALERT_MGR_HDR_EDIT: "Edit", 
        ALERT_MGR_HDR_EDITALERT: "Edit Alert", 
        ALERT_MGR_HDR_DELETEALERT: "Delete Alert", 
        ALERT_MGR_DELETE_TITLE: "Delete This Alert", 
        ALERT_MGR_DELETE_RUSURE: "Are you sure you want to delete?",
        ALERT_MGR_HRS_TYPE: "Hours of Operation Condition",
        ALERT_MGR_HRS_DAYLIMIT: "Daily Limit",
        ALERT_MGR_HRS_ALLOW: "Allowed Hours",
        ALERT_MGR_HRS_HOUR: "hour",
        ALERT_MGR_HRS_HOURS: "hours",
        ALERT_MGR_HRS_NUMBERHOURS: "Max Driving Hours per Day",
        ALERT_MGR_HRS_ALLOWHOURS: "Choose Driving Hours Allow",
        ALERT_MGR_HRS_DEF_DAYLIMIT: "Driving hours in one day that will trigger alarm when limit exceeded.",
        ALERT_MGR_HRS_DEF_ALLOW: "Alarm will send alert when driving during any of the non-selected hours.",

        //Fleet Manager Page
        FLEET_MGR_PAGE_DESC: "Create, edit, and manage fleet vehicles.", 
        FLEET_MGR_BUTTON_CREATEVEH: "Add New Vehicle",
        FLEET_MGR_NO_RESULTS_FOR_GENERIC: "No vehicles found for this fleet.", 
        FLEET_MGR_NO_RESULTS_FOR_FILTER: "No vehicles found for fleet: ", 
        FLEET_MGR_SEARCHING: "Searching fleet vehicles...", 
        FLEET_MGR_HDR_NAME: "Vehicle",
        FLEET_MGR_HDR_VIN: "VIN",
        FLEET_MGR_HDR_DRIVER: "Driver",
        FLEET_MGR_HDR_YEAR: "Year",
        FLEET_MGR_HDR_MAKE: "Make",
        FLEET_MGR_HDR_MODEL: "Model",
        FLEET_MGR_HDR_DEVICE: "Device s/n",
        FLEET_MGR_HDR_LAST_TIME: "Last Call",
        FLEET_MGR_HDR_EDIT: "Edit", 
        FLEET_MGR_EDIT_VEHICLE_HOVER: "Edit Vehicle", 
        FLEET_MGR_DEL_VEHICLE_HOVER: "Remove Vehicle from Fleet", 
        FLEET_MGR_ASSIGN_VEHICLE_HOVER: "Assign Vehicle to Fleet", 
        FLEET_MGR_CREATE_VEHICLE: "Create New Vehicle",
        FLEET_MGR_EDIT_VEHICLE: "Edit Vehicle",
        FLEET_MGR_ENTER_VEHNAME: "Enter Vehicle Name (required)",
        FLEET_MGR_ENTER: "Enter", 
        FLEET_MGR_VEH_INSTRUCTIONS: <span style={{color: "firebrick"}}>Vehicles are created automatically when device is plugged in and powered on.<br /></span>, 
        FLEET_MGR_DEVICE_INSTRUCTIONS: <>*Device s/n must match exactly to the serial number printed on unit.</>, 
        FLEET_MGR_NOTE_REMOVE: "Remove Vehicle from Fleet", 
        FLEET_MGR_NOTE_ASSIGN: "Assign Vehicle to a Fleet", 
        FLEET_MGR_DELETE_TITLE: "Remove Fleet Assignment", 
        FLEET_MGR_DELETE_RUSURE: "Are you sure you want to unassign this vehicle?",      
        FLEET_MGR_HDR_VEHICLE: "Vehicle",   
        FLEET_MGR_HDR_FROMFLEET: "From Fleet",   
        FLEET_MGR_ASSIGN_TITLE: "Assign Vehicle to Fleet", 
        FLEET_MGR_CHOOSE_FLEEET_ASSIGN: "Choose Fleet to Assign", 
        FLEET_MGR_DEVICE_VERSION: "Device Software Version", 
        FLEET_MGR_CURRENT_VERSION: "Current Version", 
        FLEET_MGR_PENDING_DOWNLOAD: "Pending Download", 
        FLEET_MGR_DEVICE_DIME: "DIME", 
        FLEET_MGR_DEVICE_REED: "REED", 


        //User Manager Page
        USER_MGR_PAGE_DESC: "Manage user accounts and fleet assignments.", 
        USER_MGR_BUTTON_CREATEUSER: "Create User",
        USER_MGR_USER_HELP_INFO: <>Assign users to multiple fleets to view maps and receive fleet specific alerts.<br />Admins can view all fleet maps, but need to be assigned a fleet to receive alerts.</>,
        USER_MGR_BUTTON_ADD_FLEET: "Add Fleet", 
        USER_MGR_SELECT_FLEET: "Select a Fleet",
        USER_MGR_ALL_FLEETS: "All Admins and Users", 
        USER_MGR_HDR_FLEETS: "Fleet Assignments",
        USER_MGR_HDR_EDIT: "Edit", 
        USER_MGR_HDR_USER: "User",
        USER_MGR_HDR_USERTYPE: "Usertype",
        USER_MGR_EDIT_FLEET_HOVER: "Edit Fleet Name", 
        USER_MGR_FLEET_NO_RESULTS_FOR_GENERIC: "No fleets found.", 
        USER_MGR_USER_NO_RESULTS_FOR_GENERIC: "Click on a fleet to show users.", 
        USER_MGR_USER_NO_RESULTS_FOR_FILTER: "No users found for fleet: ", 
        USER_MGR_EDIT_USER_HOVER: "Edit User", 
        USER_MGR_UNASSIGN_VEHICLE_HOVER: "Remove User from Fleet", 
        USER_MGR_ASSIGN_VEHICLE_HOVER: "Assign User to Fleet", 
        USER_MGR_DELETE_USER_HOVER: "Permanently Delete User", 
        USER_MGR_DELETED: "deleted", 
        USER_MGR_SEARCHING_FLEETS: "Searching fleets...", 
        USER_MGR_SEARCHING_USERS: "Searching users...", 
        USER_MGR_CREATE_USER: "Create New User",
        USER_MGR_EDIT_USER: "Edit User",    
        USER_MGR_ENTER_EMAIL: "Enter User Email (required)",    
        USER_MGR_EMAIL_REQUIRED: "<< required or invalid",
        USER_MGR_REQUIRED: "<< required",
        USER_MGR_MOBILE_REQUIRED: "<< mobile# required",
        USER_MGR_ENTER_NAME: "Enter Friendly Name (required)",  
        USER_MGR_FORM_EMAIL: "Email",
        USER_MGR_FORM_USERNAME: "Name",
        USER_MGR_FORM_IS_ACTIVE: "Active",
        USER_MGR_FORM_USERTYPE: "User Type",
        USER_MGR_FORM_ALERT_EMAIL: "Send Alert Email",
        USER_MGR_FORM_ALERT_SMS: "Send Alert Text Message",
        USER_MGR_FORM_ENTER_SMS: "Enter Mobile Number",
        USER_MGR_FORM_ENTER_SMS_REQUIRED: "Enter Mobile Number (required)",
        USER_MGR_FORM_SMS_NBR: "Mobile Phone",
        USER_MGR_FORM_EMAIL_EXISTS: "Email Already In Use",
        USER_MGR_FORM_EMAIL_INVALID: "Invalid Email Format",
        USER_MGR_FORM_EMAIL_VALIDATE_ERR: "Unable to Validate, Try Again",
        USER_MGR_FORM_LAST_LOGIN: "Last Login",
        USER_MGR_FORM_NBR_LOGINS: "Total Logins",
        USER_MGR_FORM_FLEETS: "Assigned Fleets",

        USER_MGR_UNASSIGN_TITLE: "Remove User from Fleet", 
        USER_MGR_UNASSIGN_RUSURE: "Are you sure you want unassign this user?",      
        USER_MGR_HDR_FROMFLEET: "From Fleet",   
        USER_MGR_ASSIGN_TITLE: "Assign User to Fleet", 
        USER_MGR_CHOOSE_FLEEET_ASSIGN: "Choose Fleet to Assign", 
        USER_MGR_DELETE_TITLE: "Delete User", 
        USER_MGR_DELETE_RUSURE: <>Are you sure you want to permantly delete this user?<br/><i>This can not be undone!</i></>,      
        USER_MGR_DELETE_CONFIRM: "I Confirm", 
        USER_MGR_GENERIC_ERROR: "ERROR occurred! Please see your administrator.", 
        USER_MGR_NOTE_REMOVE: "Remove User from Fleet", 
        USER_MGR_NOTE_ASSIGN: "Assign User to a Fleet", 
        USER_MGR_NOTE_DELETE: "Permanantly Delete User from System", 

        USER_MGR_CREATE_FLEET: "Create New Fleet",
        USER_MGR_EDIT_FLEET: "Edit Fleet",
        USER_MGR_ENTER_FLEETNAME: "Enter Fleet Name (required)",

        USER_MGR_FLEET_LAT: "Default Map Lat",
        USER_MGR_FLEET_LNG: "Default Map Lng",
        USER_MGR_FLEET_ZOOM: "Default Map Zoom",
        
        USER_MGR_FLEET_LAT_PH: "Latitude Number",
        USER_MGR_FLEET_LNG_PH: "Longitude Number",
        USER_MGR_FLEET_ZOOM_PH: "Choose Level",
        USER_MGR_FLEET_CROSSHAIR: <>Move crosshair to set default map latitude and longitude.<br/><br/>Use zoom control to adjust default map zoom.</>,

        //User Settings Page
        USER_PAGE_DESC: "Update my settings.", 
        USER_PAGE_YOUREMAIL: "Your Email",
        USER_PAGE_SEEYOURADMIN: "Please see your Administrator if this email is incorrect.",
        USER_PAGE_ALERTEMAIL_INFO: "Check to receive emails on fleet alerts",
        USER_PAGE_ALERTSMS_INFO: "Check to receive mobile text messages on fleet alerts. Mobile number required.",

        USER_PAGE_NAME: "Friendly Name",
        USER_PAGE_REQUIRED: "<< required",
        USER_PAGE_ENTER_NAME: "Enter Friendly Name (required)",  
        USER_PAGE_ALERT_EMAIL: "Send Alert Email",
        USER_PAGE_ALERT_SMS: "Send Alert Text Message",
        USER_PAGE_ENTER_SMS: "Enter Mobile Number",
        USER_PAGE_ENTER_SMS_REQUIRED: "Enter Mobile Number (required)",
        USER_PAGE_MOBILE_REQUIRED: "<< mobile# required",
        USER_PAGE_LAST_LOGIN: "Last Login",
        USER_PAGE_NBR_LOGINS: "Total Logins",
        USER_PAGE_FLEETS: "Fleet Assignments",
        USER_PAGE_FLEETS_NONE: "No assigned fleets. See your system Administrator.",
        USER_PAGE_RESET_PWD: "Reset Password",
        
        //Help Page 

        //Dime Datapoints
        DIME_DATAPOINT_UNAVAILABLE: "unavailable", 
        DIME_DATAPOINTS: [
            // only these datapoints will display in map InfoWindow's
            //new dime datapoints
            61,   // "DIME Microcontroller Temperature",  (same as 2 above)
            62,   // "DIME Modem PMIC Temperature",
            63,   // "DIME Modem XO Temperature",
            64,   // "DIME Modem PA Temperature",
            65,   // "Dime Accelerometer Temperature",
            66,   // "DIME Accelerometer X-Axis Angular Rate",
            67,   // "DIME Accelerometer Y-Axis Angular Rate",
            68,   // "DIME Accelerometer Z-Axis Angular Rate",
            69,   // "Dime Accelerometer X-Axis Acceleration",
            70,   // "Dime Accelerometer Y-Axis Acceleration",
            71,   // "Dime Accelerometer Z-Axis Acceleration",
            94,   // "DIME Input Battery Voltage"
            95,   // "DIME Analog Input #2 Voltage"
            96,   // "DIME Analog Input #3 Voltage"
            97,   // "DIME Analog Input #4 Voltage"
            98,   // "DIME Analog Input #5 Voltage"
            99,   // "Control Module Voltage"

            // new ODB-II datapoints
            72,   // Engine Coolant Temperature
            73,   // Fuel Pressure (gauge)
            74,   // Engine RPM
            75,   // Vehicle Speed Sensor
            76,   // Intake Air Temperature
            77,   // Absolute Throttle Position
            78,   // Distance Traveled While MIL is Activated
            79,   // Fuel Level Input
            80,   // Barometric Pressure
            81,   // Ambient Air Temperature
            82,   // Engine Oil Temperature

            //83,   // MIL/amber lamp  #don't show this 
            84,   // Accelerator Pedal Position

            // Max over 60sec ids
            100,   // "DIME Accelerometer X-Axis Angular Rate Max over 60sec",
            101,   // "DIME Accelerometer Y-Axis Angular Rate Max over 60sec",
            102,   // "DIME Accelerometer Z-Axis Angular Rate Max over 60sec",
            103,   // "Dime Accelerometer X-Axis Acceleration Max over 60sec",
            104,   // "Dime Accelerometer Y-Axis Acceleration Max over 60sec",
            105,   // "Dime Accelerometer Z-Axis Acceleration Max over 60sec",
            106,   // Vehicle Speed Sensor Max over 60sec

            // GPS datapoints
            10002, // gpstime
            10003, // altitudeM
            10004, // gspeedKPH
            10005, // heading
            10006, // satellites
            10007, // hdop

        ],

        DIME_DATAPOINTS_DISPLAYNAME: {
            // 0:  "speed",         //Wheel-based vehicle speed
            // 3:  "speed",         //road speed
            // 52: "speed",         //Wheel-based vehicle speed
            // 131:"speed",         //Wheel-based vehicle speed
        
            1:  "Engine Temp", //Engine Coolant Temp
            4:  "Engine Temp", //Engine Coolant Temp
            48: "Engine Temp", //Engine Coolant Temp
            132:"Engine Temp", //Engine Coolant Temp
            155:"Engine Temp", //Engine Coolant Temp
        
            2:  "Dime Temp",      //Dime internal temp
        
            5:  "Engine Speed",   //Engine Speed
            51: "Engine Speed",   //Engine Speed
            133:"Engine Speed",   //Engine Speed
        
            9:  "Battery",       //Battery Potential / Power Input
            45: "Battery",       //Battery Potential / Power Input
            137:"Battery",       //Battery Potential / Power Input
        
            10: "Engine Oil",     //Engine Oil Pressure
            54: "Engine Oil",     //Engine Oil Pressure (DIME AUX)
            138:"Engine Oil",     //Engine Oil Pressure
        
            22: "Odometer",      //Total Vehicle Distance
            156:"Odometer",      //Total Vehicle Distance
        
            // 23: "amberlamp",     //Amber lamp status
            // 111:"amberlamp",     //Amber lamp status
            // 121:"amberlamp",     //Amber lamp status
            // 83: "amberlamp",     //Malfunction Indicator Lamp (MIL) Status
        
            26: "ABS Lamp",       //ABS warning light
            158:"ABS Lamp",       //ABS warning light
        
            //new dime datapoints
            61: "Dime Temp",            // "DIME Microcontroller Temperature",  (same as 2 above)
            62: "Modem PMIC",           // "DIME Modem PMIC Temperature",
            63: "Modem XO",             // "DIME Modem XO Temperature",
            64: "Modem PA",             // "DIME Modem PA Temperature",
            65: "Accelerometer Temp",   // "Dime Accelerometer Temperature",
            66: "X-Axis Rate",          // "DIME Accelerometer X-Axis Angular Rate",
            67: "Y-Axis Rate",          // "DIME Accelerometer Y-Axis Angular Rate",
            68: "Z-Axis Rate",          // "DIME Accelerometer Z-Axis Angular Rate",
            69: "X-Axis Acceleration",  // "Dime Accelerometer X-Axis Acceleration",
            70: "Y-Axis Acceleration",  // "Dime Accelerometer Y-Axis Acceleration",
            71: "Z-Axis Acceleration",  // "Dime Accelerometer Z-Axis Acceleration",

            94: "Dime Input Battery",       // "DIME Input Battery Voltage"
            95: "Analog Input #2",          // "DIME Analog Input #2 Voltage"
            96: "Analog Input #3",          // "DIME Analog Input #3 Voltage"
            97: "Analog Input #4",          // "DIME Analog Input #4 Voltage"
            98: "Analog Input #5",          // "DIME Analog Input #5 Voltage"
            99: "Control Module Voltage",   // "Control Module Voltage"


            // new ODB-II datapoints
            72: "Engine Temp",          // Engine Coolant Temperature
            73: "Fuel Pressure",        // Fuel Pressure (gauge)
            74: "Engine Speed",         // Engine RPM
            // 75: "Speed",                // Vehicle Speed Sensor
            76: "Intake Air",           // Intake Air Temperature
            77: "Throttle Position",    // Absolute Throttle Position
            78: "MIL Active Distance",  // Distance Traveled While MIL is Activated
            79: "Fuel Level",           // Fuel Level Input
            80: "Barometric",           // Barometric Pressure
            81: "Ambient Air",          // Ambient Air Temperature
            82: "Engine Oil Temp",      // Engine Oil Temperature
            84: "Accelerator Pedal",    // Accelerator Pedal Position

            // Max over 60sec ids
            100: "Max X-Axis Rate over 60sec",          // "DIME Accelerometer X-Axis Angular Rate Max over 60sec",
            101: "Max Y-Axis Rate over 60sec",          // "DIME Accelerometer Y-Axis Angular Rate Max over 60sec",
            102: "Max Z-Axis Rate over 60sec",          // "DIME Accelerometer Z-Axis Angular Rate Max over 60sec",
            103: "Max X-Axis Accel. over 60sec",  // "Dime Accelerometer X-Axis Acceleration Max over 60sec",
            104: "Max Y-Axis Accel. over 60sec",  // "Dime Accelerometer Y-Axis Acceleration Max over 60sec",
            105: "Max Z-Axis Accel. over 60sec",  // "Dime Accelerometer Z-Axis Acceleration Max over 60sec",
            106: "Max Speed over 60sec",                // Vehicle Speed Sensor

            // GPS datapoints
            10002: "GPS Time",          // gpstime
            10003: "GPS Altitude",      // altitudeM
            10004: "GPS SpeedKPH",      // gspeedKPH
            10005: "GPS Heading",       // heading
            10006: "GPS Satelittes",    // satellites
            10007: "GPS HDOP",          // hdop

        },
        
        DIME_DATAPOINTS_ROUND: {
            // 0:  "speed",         //Wheel-based vehicle speed
            // 3:  "speed",         //road speed
            // 52: "speed",         //Wheel-based vehicle speed
            // 131:"speed",         //Wheel-based vehicle speed
        
            1:  1, //Engine Coolant Temp
            4:  1, //Engine Coolant Temp
            48: 1, //Engine Coolant Temp
            132:1, //Engine Coolant Temp
            155:1, //Engine Coolant Temp
        
            2:  1,      //Dime internal temp
        
            5:  1,   //Engine Speed
            51: 1,   //Engine Speed
            133:1,   //Engine Speed
        
            9:  2,       //Battery Potential / Power Input
            45: 2,       //Battery Potential / Power Input
            137:2,       //Battery Potential / Power Input
        
            10: 1,     //Engine Oil Pressure
            54: 1,     //Engine Oil Pressure (DIME AUX)
            138:1,     //Engine Oil Pressure
        
            22: 1,      //Total Vehicle Distance
            156:1,      //Total Vehicle Distance
        
            // 23: "amberlamp",     //Amber lamp status
            // 111:"amberlamp",     //Amber lamp status
            // 121:"amberlamp",     //Amber lamp status
        
            // 26: "ABS Lamp",       //ABS warning light
            // 158:"ABS Lamp",       //ABS warning light
        
            //new dime datapoints
            61: 1,    // "DIME Microcontroller Temperature",  (same as 2 above)
            62: 0,    // "DIME Modem PMIC Temperature",
            63: 0,    // "DIME Modem XO Temperature",
            64: 0,    // "DIME Modem PA Temperature",
            65: 1,    // "Dime Accelerometer Temperature",
            66: 6,    // "DIME Accelerometer X-Axis Angular Rate",
            67: 6,    // "DIME Accelerometer Y-Axis Angular Rate",
            68: 6,    // "DIME Accelerometer Z-Axis Angular Rate",
            69: 6,    // "Dime Accelerometer X-Axis Acceleration",
            70: 6,    // "Dime Accelerometer Y-Axis Acceleration",
            71: 6,    // "Dime Accelerometer Z-Axis Acceleration",
            94: 6,   // "DIME Input Battery Voltage"
            95: 6,   // "DIME Analog Input #2 Voltage"
            96: 6,   // "DIME Analog Input #3 Voltage"
            97: 6,   // "DIME Analog Input #4 Voltage"
            98: 6,   // "DIME Analog Input #5 Voltage"
            99: 6,   // "Control Module Voltage"

            // new ODB-II datapoints
            72: 1,  // Engine Coolant Temperature
            73: 1,  // Fuel Pressure (gauge)
            74: 1,  // Engine RPM
            //75: 1,  // Vehicle Speed Sensor
            76: 1,  // Intake Air Temperature
            77: 2,  // Absolute Throttle Position %
            78: 1,  // Distance Traveled While MIL is Activated
            79: 2,  // Fuel Level Input %
            80: 1,  // Barometric Pressure
            81: 1,  // Ambient Air Temperature
            82: 1,  // Engine Oil Temperature
            84: 0,  // Accelerator Pedal Position

            // Max over 60sec ids
            100: 6,    // "DIME Accelerometer X-Axis Angular Rate Max over 60sec",
            101: 6,    // "DIME Accelerometer Y-Axis Angular Rate Max over 60sec",
            102: 6,    // "DIME Accelerometer Z-Axis Angular Rate Max over 60sec",
            103: 6,    // "Dime Accelerometer X-Axis Acceleration Max over 60sec",
            104: 6,    // "Dime Accelerometer Y-Axis Acceleration Max over 60sec",
            105: 6,    // "Dime Accelerometer Z-Axis Acceleration Max over 60sec",
            106: 1,  // Vehicle Speed Sensor Max over 60sec

            // GPS datapoints
            //10002:  // gpstime
            10003: 0,   // altitudeM
            10004: 1,   // gspeedKPH
            10005: 1,   // heading
            10006: 0,   // satellites
            10007: 1,   // hdop


        },

    }
};
