/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:bc8c3bd0-7c27-4d10-867a-50ec3d21a10b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PXb1xZ9FI",
    "aws_user_pools_web_client_id": "42cctuquuis8km2qg97d05ce2h",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://jlfhbx3hofahzdscwrgehvd4nu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://zsnbw60ati.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "gcadimerest",
            "endpoint": "https://8043lgdqc2.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
