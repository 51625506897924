import React, {Component} from 'react';
import { withRouter, Link } from 'react-router-dom';

//redux
import { connect } from 'react-redux';
//import * as actions from '../../store/actions';

import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent , Menu, MenuItem } from 'react-pro-sidebar';
//, SubMenu
import DimePages from './dime_pages';
import {KEY_USERTYPE_ADMIN, KEY_USERTYPE_DIME} from './app_config';

//lang
import {MAIN_LANG} from './main_lang';
const msg_main = MAIN_LANG.EN; 

class DimeMenu extends Component {

    handleMobileToggle(){
        //when on mobile (small screen), make sure the fly-out menu gets closed after click (toggle is out, i.e. TRUE)
        const toggled = this.props.toggled;
        const collapsed = this.props.collapsed;
        if (toggled && collapsed){
            //bubble up to close the slideout menu (toggled)
            this.props.handleToggleSidebar(false);
        }
    }

    render(){
        const thispath = this.props.location.pathname;
        const toggled = this.props.toggled;
        const collapsed = this.props.collapsed;
        const collapse_arrow = (collapsed)
                           ? <div className="menu-collaspe-icon" onClick={() => this.props.handleCollapsedChange(false)}>{msg_main.ICON_ARROW_RIGHT}</div>
                           : <div className="menu-collaspe-icon" onClick={() => this.props.handleCollapsedChange(true)}>{msg_main.ICON_ARROW_LEFT}</div> ;
        const collapse_btn = (toggled) ? "" : collapse_arrow;  //don't show collapsing button when in mobile size
        const menu_logo = (collapsed)
                        ?   <div style={{padding: "5px 0px 5px 12px", display: "inline-flex"}} >
                                <Link to="/" title={msg_main.HOME_PAGE}><img src="/images/DIMElogo-favicon48.png" alt={msg_main.HOME_PAGE}  onClick={() => this.handleMobileToggle()} /></Link> 
                            </div>
                        :   <div style={{padding: "3px 0px 3px 3px", display: "inline-flex"}} >
                                <Link to="/" title={msg_main.HOME_PAGE}><img src="/images/DIMElogo_100.png" alt={msg_main.HOME_PAGE} /></Link> 
                                <div className="menu-portal-title">PORTAL</div>
                            </div> ;

//<a href="/" title={msg_main.HOME_PAGE}><img src="/images/DIMElogo_100.png" alt={msg_main.HOME_PAGE} /></a> 

        //build menu (  filter out those only shown in side-bar menu, and sort by configured order)
        //console.log("this.props.user", this.props.user);
        const PagesFiltered = (this.props.user.usertype===KEY_USERTYPE_ADMIN || this.props.user.usertype===KEY_USERTYPE_DIME)
        //const PagesFiltered = (this.props.user)
                            ? DimePages.filter(el => el.menu===true)  // get all visible pages for admins
                            : DimePages.filter(el => el.menu===true && el.admin===false);
        const PagesSorted = PagesFiltered.sort((a,b) => (a.order > b.order) ? 1 : -1); 
        const MenuItems = PagesSorted.map( (Page) => 
                <MenuItem className={(Page.path===thispath) ? "pro-active-bk" : ""} key={Page.key} icon={Page.icon}>{Page.title} <Link to={Page.path} title={Page.title}  /></MenuItem>
            );
                            
        return (
            <ProSidebar collapsed={collapsed} breakPoint="sm" toggled={this.props.toggled} onClick={() => this.handleMobileToggle()}>
                <SidebarHeader>
                    {menu_logo}                    
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="round" popperArrow={true} >
                        {MenuItems}
                    </Menu>
                </SidebarContent>
                <SidebarFooter>
                    {collapse_btn}
                </SidebarFooter>
            </ProSidebar>
        );
    }
}
const mapStateToProps = (state) => {
	return { user: state.user };
};

export default withRouter(connect(mapStateToProps)(DimeMenu));

/*

                        <MenuItem icon={msg_main.ICON_TACH}>Dashboard</MenuItem>
                        <SubMenu title="GPS Tracking" icon={msg_main.ICON_GLOBE}>
                            <MenuItem>Component 1</MenuItem>
                            <MenuItem>Component 2</MenuItem>
                        </SubMenu>
                        <SubMenu title="Routes" icon={msg_main.ICON_ROUTE}>
                            <MenuItem>Current Trips</MenuItem>
                            <MenuItem>Route Analysis</MenuItem>
                        </SubMenu>                        
                        <MenuItem icon={msg_main.ICON_CLOCK}>Hours of Operation</MenuItem>


*/