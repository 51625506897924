import * as actionTypes from './actionTypes';

export const user_login = (user) => {
  return {
    type: actionTypes.ACTION_LOGIN,
    payload: user
  };
};

export const user_login_err = (user) => {
  return {
    type: actionTypes.ACTION_LOGIN_ERR,
    payload: user
  };
};

export const user_logout = () => {
  return {
    type: actionTypes.ACTION_LOGOUT
  };
};

export const user_load = (user) => {
  return {
    type: actionTypes.ACTION_LOGIN_LOAD,
    payload: user
  };
};

export const user_save = (user) => {
  return {
    type: actionTypes.ACTION_USERSAVE,
    payload: user
  };
};

// export const user_pagechange = (user) => {
//   return {
//     type: actionTypes.ACTION_PAGECHANGE,
//     payload: user
//   };
// };

