import React, { PureComponent } from 'react';
import ClearCache from 'react-clear-cache';
// see https://www.npmjs.com/package/react-clear-cache

//redux
import { connect } from 'react-redux';
//import * as actions from '../../store/actions';
import {KEY_DBCONFIG_APP_VERSION, APP_VERSION} from '../main/app_config';

// Bootstrap componenents
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import {MAIN_LANG} from '../main/main_lang';
const msg_main = MAIN_LANG.EN; 

class VersionRefresh extends PureComponent {
    // 2/11/21 - fjp - Replaced the basic windows-dot-reload with plugin react-cache-clear
    //                 It also has a property isLatestVersion that checks the actual build version.
    //                 Let's leave the version control with db/app version mechanism for now, if we
    //                 like this plugin, we can switch to it's full features (with possibly the auto refresh)

    // refreshClick(){
    //     window.location.reload();
    //     //window.location.replace("/");
    // }

    render() {
        let refreshOutput = "";
        if (this.props.user.configs.length){
            const db_version =  this.props.user.configs.find(el => el.key===KEY_DBCONFIG_APP_VERSION).value;
            //console.log("VersionRefresh DBConfigs version", db_version);
            //console.log("VersionRefresh App version", APP_VERSION);
            //if (db_version !== APP_VERSION){
            if (db_version > APP_VERSION){
                refreshOutput = <Row className="justify-content-sm-center alert-hdr-row" >
                                    <Col sm="auto">
                                        <ClearCache>
                                            {({ /* isLatestVersion, */ emptyCacheStorage }) => (
                                                <Alert variant="primary" className="alert-hdr-msg">{msg_main.ICON_EXCLAMATION} {msg_main.REFRESH_APPVERSION}
                                                    <button onClick={e => {
                                                                        e.preventDefault();
                                                                        //this.props.onUserLogout(); 
                                                                        emptyCacheStorage();
                                                                        //window.location.replace(window.location);
                                                                    }}
                                                        className="alert-hdr-link" >{msg_main.REFRESH_RELOAD}</button>
                                                    <div className="alert-hdr-msg-sm">{msg_main.REFRESH_CTRLF5}</div>
                                                </Alert>
                                            )}
                                        </ClearCache>
                                    </Col>
                                </Row>;
            }

        }

        return (
            <>{refreshOutput}</>
        );
    }    
}

// final export
const mapStateToProps = (state) => {
	return { user: state.user };
};

// const mapDispatchToProps = (dispatch) => {
//     return { 
//         //onUserLogin: (user) => dispatch(actions.user_login(user)),
//         //onUserLoad: (user) => dispatch(actions.user_load(user)),
//         onUserLogout: () => dispatch(actions.user_logout()),
//     };
// }

//export default connect(mapStateToProps, mapDispatchToProps)(VersionRefresh);
export default connect(mapStateToProps)(VersionRefresh);
//export default VersionRefresh;