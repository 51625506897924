import * as actionTypes from '../actions/actionTypes';

const initialState = {
	tenant: "",     // userpool group
	tenantname: "",  // business name
	tenantmap: {},  // map center and zoop level
	userid: "",    // dynamoDB table record id
	username: "",  // short name  (chop email at @)
	email: "",     // userpool username (really the email)
	usertype: "",
	login_err: "",
	configs_loaded: false,
	configs: {},
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_LOGIN:
			return {
				...state,
				tenant: action.payload.tenant,
				tenantname: action.payload.tenantname,
				tenantmap: action.payload.tenantmap,
				userid: action.payload.userid,
				username: action.payload.username,
				email: action.payload.email,
				usertype: action.payload.usertype,
				login_err: "",
			};
		case actionTypes.ACTION_LOGIN_LOAD:
			return {
				...state,
				configs_loaded: true,
				configs: action.payload.configs,
			};
		case actionTypes.ACTION_LOGIN_ERR:
			return {
				...state,
				tenant: "",
				tenantname: "",
				tenantmap: {},
				userid: "",
				username: "",
				email: "",
				usertype: "",
				login_err: action.payload.login_err,
				configs_loaded: false,
				configs: {},
			};
		case actionTypes.ACTION_LOGOUT:
			return {
				...state,
				tenant: "",
				tenantname: "",
				tenantmap: {},
				userid: "",
				username: "",
				email: "",
				usertype: "",
				login_err: "",
				configs_loaded: false,
				configs: {},
			};
		case actionTypes.ACTION_USERSAVE:
			// pretty much the same as login (gets called from /user page)
			return {
				...state,
				tenant: action.payload.tenant,
				tenantname: action.payload.tenantname,
				tenantmap: action.payload.tenantmap,
				userid: action.payload.userid,
				username: action.payload.username,
				email: action.payload.email,
				usertype: action.payload.usertype,
				login_err: "",
			};
		default:
			return state;
	}
};

export default reducer;
