import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";

//cookie wrapper HOC
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

// setup Redux wrapper for entire App
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';

// Amplify, Auth
//import Amplify, {Auth, Hub} from 'aws-amplify';
//import Amplify, { Analytics } from 'aws-amplify';
  // Commenting out Analytics until bug is fixed :: "AWSPinpointProvider - updateEndpoint failed Error: Exceeded maximum endpoint per user count: 15"
import Amplify from 'aws-amplify';
import config from './aws-exports';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

//google analytics
import ReactGA from 'react-ga';

//bootstrap
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

//dime components
import DimeHeader from './components/main/dime_header';
import DimeMenu from './components/main/dime_menu';
import DimePages from './components/main/dime_pages';
//import {verifySession} from './components/shared/verify';
import { COOKIE_COLLAPSE, COOKIE_RESET, APP_GA, APP_VERSION } from './components/main/app_config';

// default page routes
import Page404 from './components/shared/404';
import Loading from './components/shared/loading';

// css available to all pages
import 'bootstrap/dist/css/bootstrap.min.css';  //bootstrap
import './components/main/dime_menu.scss'  //all the pro-sidebar css
import './components/main/main.css';  //css becomes available in all pages

// lang
import {MAIN_LANG} from './components/main/main_lang';
const msg_main = MAIN_LANG.EN; 

// setup Redux wrapper for entire App
const store = configureStore();

Amplify.configure(config);  //default configs autogenerated by CLI, plus any manual

/*

// AWS PinPoint Analytics (todo, pull below values from env-config.js/app_config.js when dev/prod established)
const analyticsConfig = {
  AWSPinpoint: {
        appId: config.aws_mobile_analytics_app_id,
        region: config.aws_mobile_analytics_app_region,
        mandatorySignIn: false,
  }
}

try{
  // Google Analytics ( https://levelup.gitconnected.com/using-google-analytics-with-react-3d98d709399b )
  Analytics.configure(analyticsConfig);
  Analytics.autoTrack('session', { enable: true, provider: 'AWSPinpoint' });  // this on by default, but put here for notice
  Analytics.autoTrack('pageView', { enable: true, eventName: 'pageView', type: 'SPA', provider: 'AWSPinpoint',
    getUrl: () => {
        return window.location.origin + window.location.pathname;
    }
  });
} catch(e){
  //console.log(e);
}
*/

if (process.env.NODE_ENV === 'production'){
  //console.log("APP_GA", APP_GA);
  ReactGA.initialize(APP_GA);
}


class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props){
    //super(props);
    super();
    const { cookies } = props;
    const collapsed_cookieval = (cookies.get(COOKIE_COLLAPSE)==="1") ? true : false;
    this.state = {authState: "", user: "", toggled: false, collapsed: collapsed_cookieval };

/*
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          console.log('hub: signIn');
          console.log(data.payload.data);
          //this.setState({ authState: 'signedIn', checkRedirect: true});
          this.setState({ authState: appConst.AUTHSTATE_SIGNEDIN});
          break;
        case 'signIn_failure':
            //console.log('hub: signIn_failure');
            //console.log(data.payload.data);
            //this.setState({ authState: 'signIn' });
            //this.props.onUserLoginErr({login_err: appConst.APP_NOT_AUTH});
          break;
        default:
          //console.log('hub: ' + data.payload.event);
          //console.log(data.payload.data);
          break;
      }

    });

    this.state = {
      authState: appConst.AUTHSTATE_LOADING,
      //checkRedirect: false
    };  
*/
  }


  componentDidMount() {
    //console.log("App.js componentDidMount");
    onAuthUIStateChange((nextAuthState, authData) => {
      const authState = this.state.authState;
      //console.log("old authstate", authState);
      if (authState !== nextAuthState){
        this.setState({authState: nextAuthState, user: authData});
        console.log("componentDidMount onAuthUIStateChange", nextAuthState);
      }      
    });  
  }
  componentDidUpdate() {
    //console.log("App.js componentDidUpdate");
    //yep, same as componentDidMount (useEffects always an option)
    onAuthUIStateChange((nextAuthState, authData) => {
      const authState = this.state.authState;
      if (authState !== nextAuthState){
        this.setState({authState: nextAuthState, user: authData});
        console.log("componentDidUpdate onAuthUIStateChange", nextAuthState);
      }
    });    
  }  


  //pro-sidebar functions hides/opens responsive menu
  handleCollapsedChange = (collapseIt) => {
    //collapse = menu is icons only (user clicks the left/right arrows in menu footer)
    this.setState({collapsed: collapseIt} );
    //save this UI setting to cookies
    const {cookies} = this.props;
    const cookieval = (collapseIt) ? "1" : "0";  //text the cookie
    cookies.set(COOKIE_COLLAPSE, cookieval, { path: '/', sameSite: 'Strict' });
    //this.setState({collapsed: collapseIt}, cookies.set(COOKIE_COLLAPSE, collapseIt, { path: '/', sameSite: 'Strict' }) );
  };

  handleToggleSidebar = (toggleIt) => {
    //toggle = in small responsive (mobile); the menu is hidden, but flies in when 3-bar icon clicked
    const newcollapse = (toggleIt) ? true : this.state.collapsed; //force collapse if the toggle is choose (when on sm/xs)
    this.setState({toggled: toggleIt, collapsed: newcollapse});
    //this.setState({toggled: toggleIt});
  };

  render() {
    const authState = this.state.authState;
    const user = this.state.user;
    const toggled = this.state.toggled;
    const collapsed = this.state.collapsed;

    if (authState === AuthState.SignedIn && user) {
  

      const userAuthPayload = (user.signInUserSession.accessToken.payload) ? user.signInUserSession.accessToken.payload : "";

      //build Routes
      const RoutesSorted = DimePages.sort((a,b) => (a.order > b.order) ? 1 : -1); 
      const DimeRoutes = RoutesSorted.map( (rte) => 
            <Route exact path={rte.path} component={rte.page_obj} key={rte.key} />
          );      
      //console.log("DimeRoutes", DimeRoutes);

      return (
        <Provider store={store} >
          <Router>
            <div className={`app ${toggled ? 'toggled' : ''}`}>
              <DimeMenu toggled={toggled} collapsed={collapsed} handleCollapsedChange={this.handleCollapsedChange} handleToggleSidebar={this.handleToggleSidebar} />
              <main>
                <header style={{paddingBottom: "0px", marginBottom: "0px"}}>
                  <DimeHeader authState={authState} userAuthPayload={userAuthPayload}
                    toggled={toggled} handleToggleSidebar={this.handleToggleSidebar} />
                </header>
                <div className="dime-content">
                  <Suspense fallback={<Loading />}>
                    <Switch>
                        {DimeRoutes}
                        <Route component={Page404} />
                    </Switch>
                  </Suspense>
                </div>
                <footer><div className="amp-auth-footer">{msg_main.LONG_NAME}<br/>{msg_main.COPYRIGHT} <span className="version-footer"> v{APP_VERSION}</span></div></footer>
              </main>
            </div>
          </Router>
        </Provider>
      );

    } else {

      // flip to resetting password if option chosen on user.js page
      const {cookies} = this.props;
      const resetcookie = cookies.get(COOKIE_RESET);
      const initstate = (resetcookie==='resetpassword') ? 'forgotpassword' : 'signin';


      return (       
        <div className="amp-auth-wrapper"> 
          <div className="dime-logo-shim" >
            <a href="http://www.dimeportal.com" title={msg_main.TITLE}><img src="/images/DIMElogo_100.png" alt={msg_main.TITLE} /></a>
            <div className="amp-auth-portal-title">PORTAL</div>
            <div className="amp-auth-infomessage">
              <b>In the News...</b>
              <a className="amp-auth-infolink" target="_blank"  rel="noopener noreferrer"
                href="/docs/ToggleMagazineGCAOsentoski.pdf">Toggle Magazine: GCA's CTO Discusses commercial DIME</a>
            </div>
          </div>        
          <AmplifyAuthenticator usernameAlias="email" className="amp-auth-body" initialAuthState={initstate} >
            <AmplifySignIn slot="sign-in" className="amp-auth-signin-override" usernameAlias="email" headerText="DIME Portal Login" hideSignUp={true}  />
          </AmplifyAuthenticator>
          <div className="amp-auth-footer">{msg_main.LONG_NAME}<br/>{msg_main.COPYRIGHT} <span className="version-footer"> v{APP_VERSION}</span></div>
        </div>
      );
    }

  }
}

export default withCookies(App);
/*
//to test Loading spinner
  <Route exact path="/loading" component={Loading} />
*/

/*
// to add redux to any component - you will need these pieces
//  NOTE: can not add them here to <App /> since that is where the <Provider> wrapper exists - otherwise put in index.js
import { connect } from 'react-redux';
import * as actions from './store/actions';

const mapStateToProps = (state) => {
	return { user: state.user };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        onUserLogin: (user) => dispatch(actions.user_login(user)),
        onUserLoginErr: (user) => dispatch(actions.user_login_err(user)),
        onUserLogout: () => dispatch(actions.user_logout()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
*/
