import {Auth} from 'aws-amplify';

//redux
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

// Quick call to Auth.currentSession to validate if we still have a good session
//  Call before any user interaction
//  If the session expired, bounce back to home page and let Authenticator handle login screen

export function verifySession() {
    //console.log("verifySession");
    Auth.currentSession().then(session => {
        //console.log("verifySession", session);
        if (session.refreshToken.token) {
            return true;
        };
        // no refresh token
        this.props.onUserLogout();     //clear out redux
        window.location.replace("/");  //reload to homepage
    }).catch(e => {
        //console.log(e);  
        try{
            this.props.onUserLogout();      //clear out redux
        } catch(e){
            //console.log(e)
        }
        window.location.replace("/");  //reload to homepage
    });
}  

// const mapStateToProps = (state) => {
// 	return { user: state.user };
// };

const mapDispatchToProps = (dispatch) => {
    return { 
        //onUserLogin: (user) => dispatch(actions.user_login(user)),
        //onUserLoad: (user) => dispatch(actions.user_load(user)),
        onUserLogout: () => dispatch(actions.user_logout()),
    };
}

connect(null, mapDispatchToProps)(verifySession);
//export default connect(mapStateToProps, mapDispatchToProps)(verifySession);