import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

//redux
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

//amplify auth
import { Auth } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';

//bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

//db calls
import { userLog, userGet, getDBConfigs } from '../db/userDB';
import { onCreateAlert, onUpdateAlert, userGetAlerts, userHasAlertsToday } from '../db/alertDB';

//google api script/key (load once for app here)
import { LoadScriptNext } from '@react-google-maps/api';

//dime components
import VersionRefresh from '../shared/version_refresh'
import DimePages from './dime_pages';
import { COGNITO_ADMIN_GROUP, KEY_DBCONFIG_GCP_MAP_API_ID, VERIFY_SESSION_TIMEOUT } from './app_config';
import { iconAlertColors } from '../main/dime_mapicons';
import { verifySession } from '../shared/verify';


//lang
import {MAIN_LANG} from './main_lang';
const msg_main = MAIN_LANG.EN; 

const libs = ["geometry"];

// Main Header on all pages - handles logout security, updated redux with user values, updates last-login timestamp in db, etc.
let userIsLogged = false;  //var here instead of state, so saving user log only fires once, and don't get hungup waitin on state
let configsIsLoaded = false; //same reason as userIsLogged
let alertIsLoaded = false; //same reason as userIsLogged
let onCreateAlertDispose; let onUpdateAlertDispose;  //dispose objects from subscription (also not needed for state), use to unsubscribe on UnMount
class DimeHeader extends Component {
    
    constructor(props){
        super(props);  //not really deprecated - it's just VSCode getting confused
        this.state = { hasBehaviors: false, hasFaults: false, userAlertIDFilter: [] };  
        //this.butAlertDOM = React.createRef;
        //this.butSettingDOM = React.createRef;
    }


    componentDidMount(){
        //this.checkAlerts();
        this.ti = setInterval(this.handleTimer,VERIFY_SESSION_TIMEOUT);
    }
    
    componentWillUnmount(){
        try{
            clearInterval(this.ti);
        } catch(e){
            console.log("clearInterval(this.ti)", e);
        }

        //clean up subscriptions
        try{
            if (onCreateAlertDispose){
                onCreateAlertDispose.unsubscribe();
            }
        } catch(e){
            console.log("onCreateAlertDispose.unsubscribe", e);
        }

        try{
            if (onUpdateAlertDispose){
                onUpdateAlertDispose.unsubscribe();
            }
        } catch(e){
            console.log("onUpdateAlertDispose.unsubscribe", e);
        }        
    }

    handleTimer = () => {
        //console.log("session check");
        verifySession();
    }

    handleLogoutClick = () => {
        Auth.signOut().then(() => {
            console.log("logged out");
            this.props.onUserLogout();
            window.location.replace("/");  //send back to home
        }).catch(e => {
            console.log(e);
            this.props.onUserLogout();
            window.location.replace("/");  //send back to home
        });
    }

    handleAlertClick = () => {
        this.setState({hasBehaviors: false});
        this.butAlertDOM.blur();   //all this to loose the focus after button click
        const AlertPage = DimePages.filter(el => el.key==="a1")[0];
        this.props.history.push(AlertPage.path);
        this.setState({hasBehaviors: false});
    }

    handleHealthClick = () => {
        this.butAlertDOM.blur();   //all this to loose the focus after button click
        const AlertPage = DimePages.filter(el => el.key==="v1")[0];
        this.props.history.push(AlertPage.path);
        this.setState({hasFaults: false});
    }    

    handleSettingsClick = () => {
        this.butSettingDOM.blur();   //all this to loose the focus after button click
        const AlertPage = DimePages.filter(el => el.key==="u1")[0];
        this.props.history.push(AlertPage.path);
    }

    async setLoginUser(user) {
        //return;
        // user is the payload object from ..session.accessToken
        const email = (user['username']) ? (user['username']) : "";
        const username = (email) ? email.split("@")[0] : "";
        //const tenant = (user['cognito:groups']) ? (user['cognito:groups'])[0]: "";
        var tenant="";

        if (user['cognito:groups']){
            for(var i=0; i<user['cognito:groups'].length; i++){
                //get the cognito group. a user can only be in possibly two groups
                //   1. their main tenant group
                //   2. and possibly also in the Cognito AppAdmin group (skip this one if it comes up first)
                if (user['cognito:groups'][i]!==COGNITO_ADMIN_GROUP){
                    tenant = user['cognito:groups'][i];
                    break;
                }
            }
        }
        
        // console.log("email", email);
        // console.log("username", username);
        // console.log("tenant", tenant);
        // console.log("user['cognito:groups']", user['cognito:groups']);
        
    
        //sets lastlogin timestamp, and creates new user in DB if first time in
        if (email && username && tenant){
            //save redux first without tenant name
            
            this.props.onUserLogin({username: username, email: email, tenant: tenant, tenantname: "", tenantmap: {}, usertype: "", userid: ""});  

            const ret_user = await userGet(email);
            //const userid = (ret_user) ? ret_user.userid : "nofind";  //let the missing id fall thru to userLog (it will create the new user there)
            //console.log(ret_user);

            var userid = "nofind";  //let the missing id fall thru to userLog (it will create the new user there)
            if (ret_user){
                userid = ret_user.userid; 
                
                //bug out on inactive user
                if (ret_user.is_active===false){
                    console.log("inactive user");
                    this.handleLogoutClick();
                    return;
                }    
            }

            const ret = await userLog(userid, email, username, tenant);  
            if (ret){
                //tenant name returns after login
                const tenantmap = {lat: parseFloat(ret.tenantlat), lng: parseFloat(ret.tenantlng), zoom: parseInt(ret.tenantzoom)};
                this.props.onUserLogin({tenant: tenant, tenantname: ret.tenantname, tenantmap: tenantmap, 
                                        userid: ret.userid, username: ret.username, email: email, usertype: ret.usertype});        
            }


        } else {
            console.log("bad user information, check email and tenant");
            this.handleLogoutClick();
            //this.props.onUserLogout();
            //window.location.replace("/");
        }

    }        

    async loadDBConfigs(){
        const configs_loaded = this.props.user.configs_loaded;
        if (configs_loaded===false){
            //get configs if they are not loaded (need them only once)
            const ret = await getDBConfigs();
            this.props.onUserLoad({configs: ret});              
        }
    }

   
    async checkAlerts(){
        try{

            // for user, check if any alerts in their assigned fleets have alerts
            const userAlertIDFilter = this.state.userAlertIDFilter;
            //console.log("userAlertIDFilter", userAlertIDFilter);

            var ret; var localbehavior=false; var localfault=false;
            if (userAlertIDFilter.length){
                for(let i=0; i<userAlertIDFilter.length; i++){
                    
                    if(localbehavior===false && iconAlertColors[userAlertIDFilter[i].alertType]==="yellow"){
                        ret = await userHasAlertsToday(userAlertIDFilter[i].alertID);
                        //driver warnings
                        localbehavior = ret;  //so this only gets called once when it finds one
                        if (ret!==this.state.hasBehaviors){
                            this.setState({hasBehaviors: ret});
                        }   
                    } 
                    if(localfault===false && iconAlertColors[userAlertIDFilter[i].alertType]==="alert"){
                        ret = await userHasAlertsToday(userAlertIDFilter[i].alertID);
                        //vehicle faults
                        localfault = ret; //so this only gets called once when it finds one
                        if (ret!==this.state.hasFaults){
                            this.setState({hasFaults: ret});
                        }                          
                    }
                }
            }


        } catch(e){
            //console.log(e);
        }

    }        
    // async checkAlerts(){
    //     //if (this.props.user.usertype===KEY_USERTYPE_USER){
    //         // for user, check if any alerts in their assigned fleets have alerts
    //         const userAlertIDFilter = this.state.userAlertIDFilter;
    //         //console.log("userAlertIDFilter", userAlertIDFilter);

    //         if (userAlertIDFilter.length){
    //             const ret = await userhasBehaviorsToday(userAlertIDFilter);
    //             if (ret!==this.state.hasBehaviors){
    //                 this.setState({hasBehaviors: ret});
    //             }                 
    //         }
    //     //} else {
    //         // //admins get a simpler query - just check if any alerts for entire tenant
    //         // const tenant = this.props.user.tenant;
    //         // const ret2 = await tenantHasCriticalAlerts(tenant);
    //         // if (ret2!==this.state.hasBehaviors){
    //         //     this.setState({hasBehaviors: ret2});
    //         // }    
    //     //}
    // }

    async startAlertSubscriptions(){

        if (this.props.user.userid){ 

            //build the AlertID filter first
            const userAlerts = await userGetAlerts(this.props.user.userid);
            //console.log("startAlertSubscriptions userAlerts", userAlerts);
            let uniqueAlertIDs={}; let userAlertIDFilter=[];
            if (userAlerts.length){
                //SORT ALERTS by CRITICAL first...  use iconAlertColors (critical ones get "alert", otherwise "yellow" ...so this will work well)
                const userAlertsSort = userAlerts.sort((a,b) => (iconAlertColors[a.alertType] > iconAlertColors[b.alertType]) ? 1 : -1); 

                for(let i=0; i<userAlertsSort.length; i++){
                    //loop through them all to make an array of the unique id's
                    //uniqueAlertIDs[userAlerts[i].alertID] = userAlerts[i].alertID;
                    //uniqueAlertIDs[userAlertsSort[i].alertID] = {eq: {alerttype: userAlertsSort[i].alertType, alertID: userAlertsSort[i].alertID }};
                    uniqueAlertIDs[userAlertsSort[i].alertID] = {alertID: userAlertsSort[i].alertID, alertType: userAlertsSort[i].alertType };
                }
                
                //walk through new array of AlertIDs
                var i=0;
                Object.keys(uniqueAlertIDs).forEach((key) => {
                    userAlertIDFilter[i]=uniqueAlertIDs[key];
                    i++;
                });                

                //walk through new array of AlertIDs
                // Object.keys(uniqueAlertIDs).forEach((key) => {
                //     // make GraphQL eq filter
                //     //userAlertIDFilter += `{alertID: {eq: "` + key + `"}}, `;
                //     // {eq: {alerttype: HOURS, alertID: "80c0ba63-f3d6-4653-89b9-4cff7a2608a9"}}
                //     //userAlertIDFilter += `{eq: {alerttype: HOURS, alertID: "80c0ba63-f3d6-4653-89b9-4cff7a2608a9"}} `;
                // });

                //console.log("userAlertIDFilter", userAlertIDFilter);
                this.setState({userAlertIDFilter: userAlertIDFilter}, this.checkAlerts);  //also kick off checkAlerts, to wait on setState to complete

            }
        }

        try {
            const onCreateAlertSubscription = await onCreateAlert();
            onCreateAlertDispose = onCreateAlertSubscription.subscribe({
                next: () => {
                    //console.log("onCreateAlertSubscription", onCreateAlertSubscription);
                    this.checkAlerts();
                }
            });      
        } catch(e){
            //console.log(e);
        }

        try {
            const onUpdateAlertSubscription = await onUpdateAlert();
            onUpdateAlertDispose = onUpdateAlertSubscription.subscribe({
                next: () => {
                    //console.log("onUpdateAlertSubscription", onUpdateAlertSubscription);
                    this.checkAlerts();
                }
            });             
        } catch(e){
            //console.log(e);
        }
    }    


    render() {
        const authState = this.props.authState;
        const userAuthPayload = this.props.userAuthPayload

        //console.log('DimeHeader render, authState: ' + authState);
        if(authState===AuthState.SignedIn && userAuthPayload){
            if (userIsLogged===false && !this.props.user.email){
                userIsLogged=true;  //keeps from reloading this until props/state fire
                this.setLoginUser(userAuthPayload)
            }
            if (configsIsLoaded===false && this.props.user.configs_loaded===false){
                configsIsLoaded=true;  //keeps from reloading this until props/state fire
                this.loadDBConfigs();
            }      
            if (alertIsLoaded===false && this.props.user.tenant){
                alertIsLoaded=true;
                this.startAlertSubscriptions();  //listen for any alert updates (sets the red dot on the bell icon)
                this.checkAlerts();  //kick it off manually first time
            } 
            
        } else {
            //just in case a new authstate comes down from App
            this.handleLogoutClick();
        }
        //console.log("this.props.user", this.props.user);

        const logout_msg = (this.props.user.username) ? msg_main.LOGOUT + ", " + this.props.user.username : msg_main.LOGOUT;
        const tenantname = (this.props.user.tenantname) ? this.props.user.tenantname : "";

        const toggleIt = !this.props.toggled;

        //  console.log("hasBehaviors", this.state.hasBehaviors);
        //  console.log("hasFaults", this.state.hasFaults);

        var alert_icon = msg_main.ICON_ALERT_SWING;
        var alert_icon_disabled = true;
        var alert_title = "";
        var alert_link = null;
        if (this.state.hasBehaviors || this.state.hasFaults){
            alert_icon_disabled = false
            if (this.state.hasBehaviors){
                //first set if any behaviors
                alert_icon = msg_main.ICON_ALERT_YELLOWDOT_SWING;
                alert_title = msg_main.ALERTS_PAGE;
                alert_link = this.handleAlertClick;
            }
            if (this.state.hasFaults){
                //set or override if there are faults
                alert_icon = msg_main.ICON_ALERT_REDDOT_SWING;
                alert_title = msg_main.HEALTH_PAGE;
                alert_link = this.handleHealthClick;
            }
        }

        const mapkey = (this.props.user.configs.length) ? this.props.user.configs.find(el => el.key===KEY_DBCONFIG_GCP_MAP_API_ID).value : "";
        // <LoadScriptNext />  loads the google api script for the entire site once. 

        return ( 
        <>
            <LoadScriptNext googleMapsApiKey={mapkey} libraries={libs} />
            <Row className="hdr-dime" >
                <Col className="d-block d-sm-none no-gutters" style={{maxWidth: "8px", width: "8px", padding: "0"}}></Col>
                <Col xs={1} className="d-block d-sm-none" style={{paddingTop: "8px"}}><div className="btn-toggle" onClick={() => this.props.handleToggleSidebar(toggleIt)}>{msg_main.ICON_BARS}</div></Col>
                <Col md={6} lg={9} className="hdr-left d-none d-md-block">{tenantname}</Col>
                <Col xs={10} sm={12} md={6} lg={3}  className="justify-content-end hdr-right">
                    <Button disabled={alert_icon_disabled} className="hdr-icon" onClick={alert_link} title={alert_title} ref={(butAlertDOM) => { this.butAlertDOM = butAlertDOM; }}>{alert_icon}</Button>
                    <Button className="hdr-icon" onClick={this.handleSettingsClick} title={msg_main.USER_PAGE} ref={(butSettingDOM) => { this.butSettingDOM = butSettingDOM; }}>{msg_main.ICON_GEAR_SWING}</Button>
                    <Button className="hdr-icon" onClick={this.handleLogoutClick} title={logout_msg}>{msg_main.ICON_LOGOUT_SWING}</Button>
                </Col>
            </Row>   
            <VersionRefresh/>         
        </>
        );
    }
}


const mapStateToProps = (state) => {
	return { user: state.user };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        onUserLogin: (user) => dispatch(actions.user_login(user)),
        onUserLoad: (user) => dispatch(actions.user_load(user)),
        //onUserLoginErr: (user) => dispatch(actions.user_login_err(user)),
        onUserLogout: () => dispatch(actions.user_logout()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DimeHeader));