import React, { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import {MAIN_LANG} from '../main/main_lang';
const msg_main = MAIN_LANG.EN;

class Page404 extends Component {
    render(){
        return(
            <Row className="justify-content-sm-center" >
                <Col sm="auto">
                    <Alert variant="dark" className="dime-404">{msg_main.ICON_EXCLAMATION} {msg_main.ERROR_404}</Alert>
                </Col>
            </Row>            
        );
    }
}

export default Page404;