// User Queries and Mutations
import API, { graphqlOperation } from '@aws-amplify/api'
import {DB_LIMIT_DBCONFIGS, DB_LIMIT_USERS, DB_LIMIT_FLEETS, KEY_USERTYPE_USER, /* KEY_USERTYPE_ADMIN, */ KEY_USERTYPE_DIME, ALLFLEETID} from '../main/app_config';
import { updateGcadimeUser, createGcadimeUser } from './../../graphql/mutations'
import { queryUserbyEmail, listGcadimeDbConfigs } from './../../graphql/queries'
import {Auth} from 'aws-amplify';
import MD5 from 'crypto-js/md5';

// check user to verify security (remove this)
//import { Auth } from 'aws-amplify' 
// Auth.currentCredentials()
// .then(d => console.log('data: ', d))
// .catch(e => console.log('error: ', e))


export async function userLog(userid, email, username, tenant){
    // set the last-login timestamp for the user,
    //  and more importantly, create a new user record if first time in to app
    // RETURNS: object that includes the tenant name (business name)

    try {
        const timestamp = new Date().toISOString();
        const logit = { id: userid, lastlogin: timestamp, logins: 1 };
        const ret = await API.graphql(graphqlOperation(updateGcadimeUser, { input: logit }))
        //console.log("userLog ret", ret);
        if (ret.data.updateGcadimeUser){
            const dimeuser = ret.data.updateGcadimeUser;
            //console.log("dimeuser", dimeuser);
            const ret2 = {  userid: dimeuser.id, email: dimeuser.email, username: dimeuser.username, usertype: dimeuser.usertype,
                            tenant: dimeuser.tenant, tenantname: dimeuser.client.tenantname, 
                            tenantlat: dimeuser.client.mapctr_lat, tenantlng: dimeuser.client.mapctr_lng, tenantzoom: dimeuser.client.mapctr_zoom};
            return ret2;
        }
        return;
    } catch (e) {
        //console.log("ERROR");
        //console.log(e);
        const err_check = e.errors[0].errorType;
        const data_check = e.data.updategcadimeUser;
        if (err_check === "DynamoDB:ConditionalCheckFailedException" || data_check === null) {
            //this is ok, just means no record updated
            //  go ahead and create new User record
            try {
                const createtimestamp = new Date().toISOString();
                const userhash = MD5(email + createtimestamp + "dimesalt").toString();  //one time hash
                const createuser = { email: email, username: username, userhash: userhash, alert_email: true, alert_sms: false, 
                                    tenant: tenant, gcadimeUserClientId: tenant,  logins: 1, is_active: true, 
                                    usertype: KEY_USERTYPE_USER, lastlogin: createtimestamp };
                const ret3 = await API.graphql(graphqlOperation(createGcadimeUser, { input: createuser }))
                //console.log("userLog ret3", ret3);
                if (ret3.data.createGcadimeUser){
                    const dimeuser = ret3.data.createGcadimeUser;
                    //console.log("dimeuser ret3", dimeuser);
                    const ret4 = {  userid: dimeuser.id, email: dimeuser.email, username: dimeuser.username, usertype: dimeuser.usertype, 
                                    tenant: dimeuser.tenant, tenantname: dimeuser.client.tenantname,
                                    tenantlat: dimeuser.client.mapctr_lat, tenantlng: dimeuser.client.mapctr_lng, tenantzoom: dimeuser.client.mapctr_zoom};
                    return ret4;
                }
                return;
            } catch (e) {
                console.log("userLog ERROR");
                console.log(e);
                return;
            }
        }
    }

}

export async function userGet(email){
    //console.log("called userGet");

    try{
        const ret = await API.graphql(graphqlOperation(queryUserbyEmail, {email: email}));
        //console.log("userGet", ret);
        if (ret.data.queryUserbyEmail.items[0]){
            const dimeuser = ret.data.queryUserbyEmail.items[0];
            //console.log("dimeuser", dimeuser);
            const ret2 = {userid: dimeuser.id, email: dimeuser.email, tenant: dimeuser.tenant, tenantname: dimeuser.client.tenantname, usertype: dimeuser.usertype, is_active: dimeuser.is_active};
            return ret2;
        }
        //console.log("no find user", email);
        return;
    } catch(e) {
        console.log("userGet ERROR");
        console.log(e);
        return;
    }
}

export async function getDBConfigs(){
    //after user logs in, get all the db configs the app may need (wait for it to complete)

    //check user is still logged in
    Auth.currentAuthenticatedUser()
    //  .then(data => console.log(data)) //all ok, token should have gotten refreshed, too
      .catch(err => console.log(err)  );  //don't force a refresh here, interfers with logout going back to homepage

    try {
        const ret = await API.graphql(graphqlOperation(listGcadimeDbConfigs, { limit: DB_LIMIT_DBCONFIGS }))
        //console.log("ret", ret);
        //build simple object of key::value pairs
        if (ret.data.listGcadimeDBConfigs.items[0]){
            const ret2 = ret.data.listGcadimeDBConfigs.items.map(el => ( {key: el.key, value: el.value} ));
            return ret2;
        }
        return {};
    } catch (e) {
        console.log("getDBConfigs ERROR");
        console.log(e);
        // //don't force a refresh here, interfers with logout going back to homepage // //
        //if (e.message.toUpperCase().includes("PLEASE AUTHENTICATE")){
            //AWS auth timed out if this message appears in error
            //window.location.reload(false);  
        //}    
        return {}  
    }

}

export async function getFleetUsers(fleetid, userid){
    // get all users for a fleet, used for user-mgr CRUD
    //  if fleetid = ALLFLEETID, then return all users
    //  Because of user sensitivity, adding extra validation that this is a valid admin

    try{
        var nextToken = null; var usertype=KEY_USERTYPE_USER;
        var ret; var cnt=1; var userItems; var userLoadThis;
        var retUsers = []; var user; var retUser;

        // VALIDATE THIS USER - MUST BE ADMIN OR DIME //
            const getGcadimeUser = /* Strip down getGcadimeUser GraphQL */ `
                query GetGcadimeUser($id: ID!) {
                    getGcadimeUser(id: $id) {
                        id
                        is_active
                        usertype
                    }
                }
            `;
            const valret = await API.graphql(graphqlOperation(getGcadimeUser, {id: userid}));  
            //console.log("valret", valret);
            if (valret.data.getGcadimeUser){ 
                const valuser = valret.data.getGcadimeUser;
                if (valuser.is_active){
                    usertype=valuser.usertype;
                }
            }   
            // we found a valid user, if it pulls up a USER type, then abort
            if (usertype===KEY_USERTYPE_USER){ return []; }  //no go
        // END VALIDATE THIS USER //

        const getGcadimeFleet = /* Strip down getGcadimeFleet GraphQL */ `
            query GetGcadimeFleet(
                    $id: ID!
                    $limit: Int
                    $nextToken: String
                ) {
                getGcadimeFleet(id: $id) {
                    user(limit: $limit, nextToken: $nextToken) {
                        items {
                            user {
                                id
                                email
                                username
                                is_active
                                usertype
                                alert_email
                                alert_sms
                                sms_nbr
                                logins
                                lastlogin      
                                fleet(limit: 100) {
                                    items {
                                        id
                                        fleet {
                                            id
                                            fleetname
                                        }
                                    }
                                }                                                          
                            }
                        }
                        nextToken
                    }
                }
            }            
        `;  

        const ListGcadimeUsers = /* Strip down ListGcadimeUsers GraphQL */ `
            query ListGcadimeUsers(
                $limit: Int
                $nextToken: String
            ) {
                listGcadimeUsers(limit: $limit, nextToken: $nextToken) {
                    items {
                        id
                        email
                        username
                        is_active
                        usertype
                        alert_email
                        alert_sms
                        sms_nbr
                        logins
                        lastlogin
                        fleet(limit: 100) {
                            items {
                                id
                                fleet {
                                    id
                                    fleetname
                                }
                            }
                        }                         
                    }
                    nextToken
                }
            }            
        `;          
        
        do {  //loop thru nextTokens
            if (fleetid===ALLFLEETID){
                //all users
                ret = await API.graphql(graphqlOperation(ListGcadimeUsers, {limit: DB_LIMIT_USERS, nextToken: nextToken}));   
                //console.log("listGcadimeUsers ret", ret);
                if (ret.data.listGcadimeUsers===null){ return []; }   
                nextToken = ret.data.listGcadimeUsers.nextToken;   
                userItems = ret.data.listGcadimeUsers.items;  
            } else {
                //normal fleet
                ret = await API.graphql(graphqlOperation(getGcadimeFleet, {id: fleetid, limit: DB_LIMIT_USERS, nextToken: nextToken}));   
                if (ret.data.getGcadimeFleet===null){ return []; }   
                nextToken = ret.data.getGcadimeFleet.user.nextToken;   
                userItems = ret.data.getGcadimeFleet.user.items;       
            }
            //console.log("userItems", userItems);
            
            if (userItems.length){
                for(let i=0; i<userItems.length; i++){
                    user = (fleetid===ALLFLEETID) ? userItems[i] : userItems[i].user;  //the return query is slighty different

                    userLoadThis=false;
                    if (usertype===KEY_USERTYPE_DIME){
                        //logged-in as DIME user, pull ALL! (even inactive)
                        userLoadThis=true;             
                    } else {
                        //regular admins only show Active (and never show DIME users)

                        if (user.is_active && user.usertype!==KEY_USERTYPE_DIME){
                            userLoadThis=true;
                        }    
                        
                        //NO NO NO hide Admins in Fleets...
                        // if (fleetid===ALLFLEETID){
                        //     if (user.is_active && user.usertype!==KEY_USERTYPE_DIME){
                        //         userLoadThis=true;
                        //     }
                        // } else {
                        //     //hide Dimes/Admins in Fleets...
                        //     if (user.is_active && user.usertype===KEY_USERTYPE_USER){
                        //         userLoadThis=true;
                        //     }
                        // }

                    }

                    //add this user to the final output
                    if (userLoadThis){
                        retUser = {  
                            id: user.id,
                            email: user.email,
                            username: user.username,
                            is_active: user.is_active,
                            usertype: user.usertype,
                            alert_email: user.alert_email,
                            alert_sms: user.alert_sms,
                            sms_nbr: user.sms_nbr,
                            logins: user.logins,
                            lastlogin: user.lastlogin,
                            fleetid: fleetid,
                            fleet_links: (  (user.fleet.items) 
                                            ? user.fleet.items.map((link) => ({fleet_link_id: link.id, fleetid: link.fleet.id, fleetname: link.fleet.fleetname}))
                                            : []
                                         ),                            
                        };        
                        retUsers.push(retUser);       
                    }

                }
            }

            cnt++;  if (cnt>50){  console.log("fleet safety value!!!"); break; }  //only because I get nervous

        } while (nextToken !== null);

        return retUsers;

    } catch(e) {
        console.log("getFleetUsers ERROR");
        console.log(e);
        return [];
    }
}

export async function updateUser(userid, username, usertype, alert_email, alert_sms, sms_nbr){
    // update a user

    try {
        //const timestamp = new Date().toISOString();
        const input = { id: userid, username: username, usertype: usertype, alert_email: alert_email, alert_sms: alert_sms, sms_nbr: sms_nbr };
        const ret = await API.graphql(graphqlOperation(updateGcadimeUser, { input: input }))
        //console.log("updateGcadimeUser ret", ret);
        if (ret.data.updateGcadimeUser){
            return ret;
        }
        return;
    } catch (e) {
        console.log("updateUser ERROR");
        console.log(e);
    }
    return;
}

export async function updateUserDeActivate(userid, email){
    // update a user to is_active = false!

    try {
        const timestamp = new Date().toISOString();
        const deleted_email = email + "_DELETED_" + timestamp;

        const input = { id: userid, email: deleted_email, is_active: false, alert_email: false, alert_sms: false, sms_nbr: null };
        const ret = await API.graphql(graphqlOperation(updateGcadimeUser, { input: input }))
        //console.log("updateGcadimeUser ret", ret);
        if (ret.data.updateGcadimeUser){
            return ret;
        }
        return;
    } catch (e) {
        console.log("updateUserDeActivate ERROR");
        console.log(e);
    }
    return;
}

export async function createUser(email, username, tenant, usertype, alert_email, alert_sms, sms_nbr){
    // create new user


    try {
        const createtimestamp = new Date().toISOString();
        const userhash = MD5(email + createtimestamp + "dimesalt").toString();  //one time hash
        const createuser = {email: email, username: username, userhash: userhash, usertype: usertype,
                            tenant: tenant, gcadimeUserClientId: tenant,  is_active: true, logins: 0,
                            alert_email: alert_email, alert_sms: alert_sms, sms_nbr: sms_nbr,
                            };
        const ret = await API.graphql(graphqlOperation(createGcadimeUser, { input: createuser }))
        //console.log("userLog ret3", ret3);
        if (ret.data.createGcadimeUser){
            const dimeuser = ret.data.createGcadimeUser;
            //console.log("dimeuser ret3", dimeuser);
            const newuser = {userid: dimeuser.id, email: dimeuser.email, usertype: dimeuser.usertype, 
                            tenant: dimeuser.tenant, tenantname: dimeuser.client.tenantname };
            return newuser;
        }
        return;
    } catch (e) {
        console.log("createUser ERROR");
        console.log(e);
        return {};
    }

}

export async function userUnassignFleet(fleet_link_id){
    // Unassign a User from a Fleet
    //   1. remove from table gcadimeFleetUser
    // returns the successfully deleted link

    const deleteFleet = /* Strip down deleteGcadimeFleetUser GraphQL */ `
        mutation deleteFleet(
            $inputDel: DeleteGcadimeFleetUserInput!
        ) {
            del1: deleteGcadimeFleetUser(input: $inputDel) { id }
        }
    `;
  
    try {
        const inputDel = {  id: fleet_link_id };
        const ret = await API.graphql(graphqlOperation(deleteFleet, { inputDel: inputDel }))
        //console.log("deleteFleet ret", ret);
        if (ret.data.del1){
            const delLink = ret.data.del1;
            return delLink.id;
        }
    } catch (e) {
        console.log("userUnassignFleet ERROR");
        console.log(e);
        
    }
    return null;
}

export async function userAssignFleet(userid, tenant, fleetid){
    // Aassign a User to a Fleet
    //   1. add to table gcadimeFleetVehicle
    // returns the successfully deleted link

    const assignFleet = /* Strip down createGcadimeFleetUser GraphQL */ `
        mutation assignFleet(
            $inputNew: CreateGcadimeFleetUserInput!
        ) {
            new1: createGcadimeFleetUser(input: $inputNew) { id }
        }
    `;
  
    try {
        const inputNew = {gcadimeFleetUserFleetId: fleetid, gcadimeFleetUserUserId: userid, tenant: tenant};
        const ret = await API.graphql(graphqlOperation(assignFleet, { inputNew: inputNew }))
        //console.log("assignFleet ret", ret);
        if (ret.data.new1){
            const newLink = ret.data.new1;
            return newLink.id;
        }
    } catch (e) {
        console.log("userAssignFleet ERROR");
        console.log(e);
        
    }
    return null;
}

export async function userGetFull(userid){
    //get detail user record for /user maintenance page

    // var nextToken = null; var useritem;
    // var ret; var cnt=1; var fleetItems; 
    // var fleets = []; var retUser={};
    
    const getGcadimeUser = /* Strip down getGcadimeUser GraphQL */ `
        query GetGcadimeUser(
            $id: ID!
        ) {
            getGcadimeUser(id: $id) {
                id
                email
                username
                usertype
                is_active
                logins
                alert_email
                alert_sms
                sms_nbr
                lastlogin
                fleet(limit: 100 ) {
                    items {
                        id
                        fleet {
                          id
                          fleetname
                        }
                    }
                }
            }
        }    
    `;

    try{
        const ret = await API.graphql(graphqlOperation(getGcadimeUser, {id: userid, fleet_limit: DB_LIMIT_FLEETS}));
        // console.log("getGcadimeUser ret", ret);

        if (ret.data.getGcadimeUser===null){ return {}; }   

        const user = ret.data.getGcadimeUser;

        const retUser = {  
            id: user.id,
            email: user.email,
            username: user.username,
            is_active: user.is_active,
            usertype: user.usertype,
            alert_email: user.alert_email,
            alert_sms: user.alert_sms,
            sms_nbr: user.sms_nbr,
            logins: user.logins,
            lastlogin: user.lastlogin,
            fleet_links: (  (user.fleet.items) 
                            ? user.fleet.items.map((link) => ({fleet_link_id: link.id, fleetid: link.fleet.id, fleetname: link.fleet.fleetname}))
                            : []
                            ),                            
        };                    

        return retUser;

    } catch(e) {
        console.log("userGetFull ERROR");
        console.log(e);
        return {};
    }    
}
