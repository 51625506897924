import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import * as serviceWorker from './serviceWorker';

//import Amplify from 'aws-amplify';
//import config from './aws-exports';
//Amplify.configure(config);  //default configs autogenerated by CLI, plus any manual

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// 09/08/2020 - fjp - changed to register() for prod
//serviceWorker.unregister();
if (process.env.NODE_ENV === 'production'){
  serviceWorker.register();  //use for prod build
} else {
  serviceWorker.unregister();
} 
