// User Queries and Mutations
import API, { graphqlOperation } from '@aws-amplify/api'
import { updateGcadimeAlert, createGcadimeAlert, createGcadimeFleetAlert } from './../../graphql/mutations'
//import { listGcadimeFleets } from './../../graphql/queries'
import {DB_LIMIT_FLEETS, DB_LIMIT_ALERTS, DB_LIMIT_ALERTSEARCH } from '../main/app_config';
import { lightFormat } from 'date-fns';

export async function onCreateAlert(){
    //return subscription for OnCreateGcadimeAlertMessage.
    const onCreateGcadimeAlertMessage = /* Strip down gcadimeVehicle GraphQL */ `
        subscription OnCreateGcadimeAlertMessage{
            onCreateGcadimeAlertMessage {
                id
                timestamp
                alertID
                alerttype
                vehicleID
                violationJSON
                message
                status
                lat
                lng
                placeID
            }
        }
    `;

    try{
        const subscription = API.graphql(graphqlOperation(onCreateGcadimeAlertMessage));
        return subscription;    
    } catch(e){
        console.log("onCreateAlert ERROR");
        console.log(e);
        return;        
    }
  }

  export async function onUpdateAlert(){
    //return subscription for OnCreateGcadimeAlertMessage.
    const OnUpdateGcadimeAlertMessage = /* Strip down gcadimeVehicle GraphQL */ `
        subscription OnUpdateGcadimeAlertMessage{
            onUpdateGcadimeAlertMessage {
                id
                timestamp
                alertID
                alerttype
                vehicleID
                violationJSON
                message
                status
                lat
                lng
                placeID
            }
        }
    `;

    try{
        const subscription = API.graphql(graphqlOperation(OnUpdateGcadimeAlertMessage));
        return subscription;    
    } catch(e){
        console.log("onUpdateAlert ERROR");
        console.log(e);
        return;        
    }
  }  

//   export async function tenantHasCriticalAlerts(tenant){
//     // query the gcadimeAlertMessage if *any* OPEN / Critical alerts
//     //  returns:  true or false

//     try{
// //        filter: {and: [{status: {eq: OPEN}}, { or: [{alerttype: {eq: ACCIDENT}}, {alerttype: {eq: FAULT}}] } ]}) 

//         const tenantHasCriticalAlert = /* Strip down getGcadimeFleet GraphQL */ `
//         query alertquery(
//             $tenant: String!
//         ) {
//             queryAlertByTenantStatusType(
//               tenant: $tenant, 
//               limit: 1,
//               filter: {and: [{status: {eq: OPEN}}, { or: [{alerttype: {eq: ACCIDENT}}, {alerttype: {eq: FAULT}}, {alerttype: {eq: GEOFENCE}}, {alerttype: {eq: HOURS}}] } ]}) 
//               {
//               items {
//                 id
//                 alertID
//                 status
//                 alerttype
//               }
//             }
//           }
//         `;  
//         const  ret = await API.graphql(graphqlOperation(tenantHasCriticalAlert, {tenant: tenant}));   
//         //console.log("tenantHasCriticalAlerts ret", tenantHasCriticalAlert, ret);

//         if (ret.data.queryAlertByTenantStatusType.items[0]){ 
//             if (ret.data.queryAlertByTenantStatusType.items[0].id){ 
//                 return true;
//             }
//         }   
//         return false;

//     } catch(e) {
//         console.log("tenantHasCriticalAlerts ERROR");
//         console.log(e);
//         return false;
//     }
// }  

export async function userHasAlertsToday(alertID){
    // query the gcadimeAlertMessage if *any* OPEN / Critical alerts 
    //     ...specific to a user's assigned of AlertIDs (via his fleet assignments, via userGetAlerts below)
    //  returns:  true or false

    // Parms:
    //   alertID 

    try{
        const userHasAlertsToday = /* Strip down queryAlertByAlertIDRouteDay GraphQL */ `
        query alertquery(
            $alertID: String
            $routeday: ModelStringKeyConditionInput
        ){
            queryAlertByAlertIDRouteDay(
                alertID: $alertID, 
                routeday: $routeday,
                limit: 1
            ) {
              items {
                id
                alertID
                status
                alerttype
              }
            }
          }
        `;          

        //set today "begins with" filter (chop time from ISO to make YYYY-MM-DD )
        //const today_timestamp = new Date().toISOString().substring(0, 10);
        const today_routeday = lightFormat((new Date()), "yyyy-MM-dd");
        //console.log("today_routeday", today_routeday);
        
        const ret = await API.graphql(graphqlOperation(userHasAlertsToday, {alertID: alertID, routeday: {eq: today_routeday} })); 
        //console.log("userHasAlertsToday ret", ret);

        if (ret.data.queryAlertByAlertIDRouteDay.items[0]){ 
            if (ret.data.queryAlertByAlertIDRouteDay.items[0].id){ 
                return true;
            }
        }               

        // walk through new array of AlertIDs
        //console.log("userHasCriticalAlert userAlertIDFilter", userAlertIDFilter)
        // var ret;
        // for(let i=0; i<userAlertIDFilter.length; i++){
        //     console.log("userHasCriticalAlert userAlertIDFilter", i, userAlertIDFilter[i])
        //     ret = await API.graphql(graphqlOperation(userHasAlertsToday, {alertID: userAlertIDFilter[i].alertID, timestamp: {beginsWith: today_timestamp} })); 
        //     console.log("ret", i, ret);

        //     if (ret.data.queryAlertByAlertIDTime.items[0]){ 
        //         if (ret.data.queryAlertByAlertIDTime.items[0].id){ 
        //             return ret.data.queryAlertByAlertIDTime.items[0].alerttype;
        //         }
        //     }               
        // }

        return false;

    } catch(e) {
        console.log("userHasAlertsToday ERROR");
        console.log(e);
        return false;
    }
}  


export async function userGetAlerts(userid){
    // returns alerts tied to a user, through their fleet assignment
    //  NOTE: alerts can be repeated if they are resused in multiple fleets (although each array item will be unique with fleetID)
    //  use for UserType = USER  (Admin's should just get all directly!)

    try{
        var nextFleetToken = null;
        var retFleet; var cnt=1; var fleetID; var fleetItems; 
        var nextAlertToken = null;
        var retAlert; var cnt2=1; var alertItems;
        var retAlerts = []; 

        const listUserFleets = /* custom GraphQL */ `
            query listUserFleets(
                    $userid: ID!
                    $limitFleet: Int
                    $nextFleetToken: String
                ) {
                getGcadimeUser(id: $userid){ 
                    fleet(limit: $limitFleet, nextToken: $nextFleetToken){ 
                        items{ 
                            fleet{ 
                                id
                                fleetname
                            } 
                        } 
                        nextToken
                    } 
                } 
            }
        `;  

        
        const listUserAlerts = /* custom GraphQL */ `
            query listUserAlerts(
                    $fleetid: ID!
                    $limitAlert: Int
                    $nextAlertToken: String
                ) {
                getGcadimeFleet(id: $fleetid){ 
                    alert(limit: $limitAlert, nextToken: $nextAlertToken){
                        items{
                            alert{
                                id
                                alertname
                                is_active
                                alerttype
                                alertJSON
                                timestamp
                            }
                        }
                        nextToken
                    }
                } 
            }
        `;  


        //FIRST, get all the fleets this user belongs to
        do {  //loop while there are nextFleetTokens
            
            retFleet = await API.graphql(graphqlOperation(listUserFleets,
                    {userid: userid, limitFleet: DB_LIMIT_FLEETS, nextFleetToken: nextFleetToken}
                ));   
            //console.log("listUserFleets retFleet", retFleet);

            if (retFleet.data.getGcadimeUser===null){ return []; }   //bug out, return empty array
            nextFleetToken=retFleet.data.getGcadimeUser.fleet.nextToken;

            if (retFleet.data.getGcadimeUser.fleet.items.length){
                fleetItems = retFleet.data.getGcadimeUser.fleet.items;
                //loop through each fleet
                for(let i=0; i<fleetItems.length; i++){
                    fleetID = fleetItems[i].fleet.id;
                    //console.log("fleetID::"+i,fleetID);

                    //SECOND, get all the alerts for this Fleet
                    do {  //loop while there are nextAlertTokens
                        retAlert = await API.graphql(graphqlOperation(listUserAlerts,
                            {fleetid: fleetID, limitAlert: DB_LIMIT_ALERTS, nextAlertToken: nextAlertToken}
                        ));  
                        //console.log("listUserAlerts retAlert::"+i, retAlert);
                        if (retAlert.data.getGcadimeFleet) {
                            nextAlertToken=retAlert.data.getGcadimeFleet.alert.nextToken;
                            if (retAlert.data.getGcadimeFleet.alert.items.length){
                                alertItems = retAlert.data.getGcadimeFleet.alert.items;
                                //loop through the alerts
                                for(let ii=0; ii<alertItems.length; ii++){
                                    //console.log("alertItems::"+ii, alertItems[ii]);

                                     //only return active
                                    if (alertItems[ii].alert.is_active===true){
                                       //build final output here!!
                                        retAlerts.push({
                                            fleetID: fleetID,
                                            fleetName: fleetItems[i].fleet.fleetName,
                                            alertID: alertItems[ii].alert.id,
                                            alertName: alertItems[ii].alert.alertname,
                                            //isActive: alertItems[ii].alert.is_active,
                                            alertType: alertItems[ii].alert.alerttype,
                                            alertJSON: alertItems[ii].alert.alertJSON,
                                            timestamp: alertItems[ii].alert.timestamp                                     
                                        });    
                                    }                                    
                                }
                            }
                        }
                        
                        //console.log("nextAlertToken", nextAlertToken);
                        
                        cnt2++;  if (cnt2>50){  console.log("alert safety value!!!"); break; }  //only because I get nervous
                    }  while (nextAlertToken !== null);
                }
            }

            cnt++;  if (cnt>50){  console.log("fleet safety value!!!"); break; }  //only because I get nervous
            cnt2=1;

        } while (nextFleetToken !== null);

        return retAlerts;

    } catch(e) {
        console.log("userGetAlerts ERROR");
        console.log(e);
        return [];
    }
}

export async function fleetGetAlerts(fleetid, alerttype){
    // returns alerts tied for fleet

    try{
        var nextAlertToken = null;
        var retAlert; var cnt=1; var alertItems;
        var retAlerts = []; var otherFleets; var alertTypeFilter=[];
       
        const listFleetAlerts = /* custom GraphQL */ `
            query listFleetAlerts(
                    $fleetid: ID!
                    $limitAlert: Int
                    $nextAlertToken: String
                ) {
                getGcadimeFleet(id: $fleetid){ 
                    alert(limit: $limitAlert, nextToken: $nextAlertToken){
                        items{
                            alert{
                                id
                                alertname
                                is_active
                                alerttype
                                alertJSON
                                timestamp
                                fleet{
                                    items{
                                        fleet{
                                            id
                                            fleetname
                                        }
                                    }
                                }
                            }
                        }
                        nextToken
                    }
                } 
            }
        `;  

        if (alerttype){
            if (Array.isArray(alerttype)){
                alertTypeFilter = alerttype;
            } else {
                alertTypeFilter.push(alerttype);
            }
        }    

        //get all the alerts for this Fleet
        do {  //loop while there are nextAlertTokens
            retAlert = await API.graphql(graphqlOperation(listFleetAlerts,
                {fleetid: fleetid, limitAlert: DB_LIMIT_ALERTS, nextAlertToken: nextAlertToken}
            ));  
            //console.log("listFleetAlerts retAlert", retAlert);

            if (retAlert.data.getGcadimeFleet) {
                nextAlertToken=retAlert.data.getGcadimeFleet.alert.nextToken;
                if (retAlert.data.getGcadimeFleet.alert.items.length){
                    //alertItems = retAlert.data.getGcadimeFleet.alert.items;
                    alertItems = retAlert.data.getGcadimeFleet.alert.items.filter(el => el.alert.is_active===true);

                    //loop through the alerts
                    for(let ii=0; ii<alertItems.length; ii++){
                        //console.log("alertItems::"+ii, alertItems[ii]);

                        //filter out only the supplied alerttype's (for alertmgr page, that's just behavior alerts)
                        if (alerttype===null || (alertTypeFilter.indexOf(alertItems[ii].alert.alerttype) !== -1 ) ){
                            //build final output here!!
                            
                            otherFleets=null;
                            if (alertItems[ii].alert.fleet.items){
                                const filteredFleets = alertItems[ii].alert.fleet.items.filter(el => el.fleet.id!==fleetid);
                                otherFleets = (filteredFleets.length) 
                                                ? filteredFleets.map((el) => ({id: el.fleet.id, fleetname: el.fleet.fleetname}))
                                                : null;
                            }

                            retAlerts.push({
                                fleetID: fleetid,
                                //fleetName: fleetItems[i].fleet.fleetName,
                                alertID: alertItems[ii].alert.id,
                                alertName: alertItems[ii].alert.alertname,
                                //isActive: alertItems[ii].alert.is_active,
                                alertType: alertItems[ii].alert.alerttype,
                                alertJSON: alertItems[ii].alert.alertJSON,
                                timestamp: alertItems[ii].alert.timestamp,
                                otherFleets: otherFleets,                                     
                            });    
                        }                                    
                    }
                }
            }
            
            //console.log("nextAlertToken", nextAlertToken);
            
            cnt++;  if (cnt>50){  console.log("alert safety value!!!"); break; }  //only because I get nervous
        }  while (nextAlertToken !== null);


        return retAlerts;

    } catch(e) {
        console.log("fleetGetAlerts ERROR");
        console.log(e);
        return [];
    }
}


export async function searchVehicleAlerts(vehicleid, alerttype, routeday, searchHash){
    // TO DO:: replace this with api function that queries Athena!
    // return Alert records for vehicle with various search conditions (similar to vehicleDB.getVehicleAlertPoints)
    var nextToken = null; var jsalert;
    var ret; var cnt=1; var alertItems;
    var alertTypeFilter = null; var routeDayFilter = null;
    var retAlerts = [];

    try{

        //get all the Alert Messages records for this date, sort by time (the table @key "queryAlertByVehicleIDRouteDay" is by vehicleID and sort key timestamp)
        const queryAlertByVehicleIDRouteDay = /* Strip down queryAlertByVehicleIDRouteDay GraphQL */ `
        query queryAlertByVehicleIDRouteDay(
            $vehicleID: String
            $routeday: ModelStringKeyConditionInput
            $filter: ModelgcadimeAlertMessageFilterInput
            $limit: Int
            $nextToken: String
        ) {
            queryAlertByVehicleIDRouteDay(
                vehicleID: $vehicleID
                routeday: $routeday
                filter: $filter
                limit: $limit
                nextToken: $nextToken
            ) {
                items {
                    id
                    timestamp
                    routeday
                    alerttype
                    violationJSON
                    message
                    status
                    lat
                    lng
                    eventID
                    vehicle{
                        id
                        vehiclename
                        driver
                    }
                    alert {
                        id
                        alertname
                    }
                }
                nextToken
            }
          }

        `;  

//        sortDirection: DESC


        if (routeday){
            routeDayFilter = {eq: routeday}; 
        }
        if (alerttype){
            if (Array.isArray(alerttype)){
                //   filter: {or: [{alerttype: {eq: HOURS}}, {alerttype: {eq: GEOFENCE}}] }
                alertTypeFilter = {or: []};
                for(let ia=0; ia<alerttype.length; ia++){
                    alertTypeFilter['or'].push( { alerttype: {eq: alerttype[ia]} } );
                }
            } else {
                alertTypeFilter = {alerttype: {eq: alerttype}};
            }
        }
        //console.log("searchVehicleAlerts alertTypeFilter", JSON.stringify(alertTypeFilter) );

        do {  //loop thru nextTokens
            ret = await API.graphql(graphqlOperation(queryAlertByVehicleIDRouteDay, 
                    {vehicleID: vehicleid, routeday: routeDayFilter, filter: alertTypeFilter, limit: DB_LIMIT_ALERTSEARCH, nextToken: nextToken})
                );     
            //console.log("searchVehicleAlerts ret", ret);

            if (ret.data.queryAlertByVehicleIDRouteDay===null){ return []; }          
            nextToken = ret.data.queryAlertByVehicleIDRouteDay.nextToken;
            
            if (ret.data.queryAlertByVehicleIDRouteDay.items.length){
                alertItems = ret.data.queryAlertByVehicleIDRouteDay.items;
                for(let i=0; i<alertItems.length; i++){
                    jsalert = {
                            id: alertItems[i].id, ts: alertItems[i].timestamp, routeday: alertItems[i].routeday, 
                            alertID: alertItems[i].alert.id, alertName: alertItems[i].alert.alertname, 
                            alertType: alertItems[i].alerttype, status: alertItems[i].status,
                            lat: parseFloat(alertItems[i].lat), lng: parseFloat(alertItems[i].lng), 
                            message: alertItems[i].message, violationJSON: alertItems[i].violationJSON,
                            vehicleID: alertItems[i].vehicle.id, vehicleName: alertItems[i].vehicle.vehiclename, driver: alertItems[i].vehicle.driver, 
                            eventid: alertItems[i].eventID,
                            searchhash: searchHash,
                        };
                        retAlerts.push(jsalert);
                }
            }

            cnt++;  if (cnt>150){  console.log("alert point safety value!!!"); break; }  //only because I get nervous

        } while (nextToken !== null);

        //important, (CLIENT SIDE) sort by the timestamp DESC so the oldest is at theop
        if (retAlerts.length){
            retAlerts.sort((a,b) => (a.ts < b.ts) ? 1 : -1); 
        } else {
            retAlerts.push({id: null, searchhash: searchHash});
        }

        return retAlerts;        

    } catch(e) {
        console.log("searchVehicleAlerts ERROR");
        console.log(e);
        return null;
    }
}

export async function searchFleetAlerts(fleetid, alerttype, routeday, searchHash){
    // TO DO:: replace this with api function that queries Athena!
    // return Alert records for Fleet with various search conditions (similar to searchVehicleAlerts above, but by starting at top of Fleet, get alert ids, then query)
    var alertNextToken = null; var messageNextToken = null; var jsalert;
    var ret; var cnt=1; var alertItems; var messageItems;
    var alertTypeFilter = null; var routeDayFilter = null;
    var alertIDs = []; var retAlerts = []; var vehicleInFleet = false;
    
    try{


        const listFleetAlerts  = /* Custom getGcadimeFleet GraphQL */ `
            query listFleetAlerts(
                $fleetid: ID!
                $alertNextToken: String
                $alertLimit: Int
            ) {
                getGcadimeFleet(
                    id: $fleetid
                ) {
                    alert(
                        nextToken: $alertNextToken
                        limit: $alertLimit
                    ) {
                        items {
                            alert {
                                id
                                alertname
                            }
                        }
                        nextToken
                    }
                }
            }
        `;  

        const queryAlertByAlertIDRouteDay = /* Strip down queryAlertByAlertIDRouteDay GraphQL */ `
            query queryAlertByAlertIDRouteDay(
                $alertID: String
                $routeday: ModelStringKeyConditionInput
                $filter: ModelgcadimeAlertMessageFilterInput
                $messageLimit: Int
                $messageNextToken: String
            ) {
                queryAlertByAlertIDRouteDay(
                    alertID: $alertID
                    routeday: $routeday
                    filter: $filter
                    limit: $messageLimit
                    nextToken: $messageNextToken
                ) {
                    items {
                        id
                        timestamp
                        routeday
                        alerttype
                        violationJSON
                        message
                        status
                        lat
                        lng
                        eventID
                        vehicle{
                            id
                            vehiclename
                            driver
                            fleet{
                                items{
                                  fleet{
                                    id
                                  }
                                }
                              }
                        
                        }
                    }
                    nextToken
                }
            }
        `;  

//        sortDirection: DESC


        if (routeday){
            routeDayFilter = {eq: routeday}; 
        }
        if (alerttype){
            if (Array.isArray(alerttype)){
                //   filter: {or: [{alerttype: {eq: HOURS}}, {alerttype: {eq: GEOFENCE}}] }
                alertTypeFilter = {or: []};
                for(let ia=0; ia<alerttype.length; ia++){
                    alertTypeFilter['or'].push( { alerttype: {eq: alerttype[ia]} } );
                }
            } else {
                alertTypeFilter = {alerttype: {eq: alerttype}};
            }
        }        

        //Step 1 :: Get all the Alert ID for this fleet (put ids in alertIDs array)
        do {  //loop thru alertNextToken

            ret = await API.graphql(graphqlOperation(listFleetAlerts, 
                    {   fleetid: fleetid, alertNextToken: alertNextToken, alertLimit: DB_LIMIT_ALERTS }
                ) );     
            // console.log("searchFleetAlerts listFleetAlerts ret", ret);

            if (ret.data.getGcadimeFleet===null){ return []; }          
            alertNextToken = ret.data.getGcadimeFleet.alert.nextToken;

            if (ret.data.getGcadimeFleet.alert.items.length){
                alertItems = ret.data.getGcadimeFleet.alert.items;
                for(let i=0; i<alertItems.length; i++){
                    //console.log("alertItems[" + i + "]", alertItems[i]);
                    if (alertItems[i].alert){
                        alertIDs.push({id: alertItems[i].alert.id, name: alertItems[i].alert.alertname});
                    }
                }
            }
           

            cnt++;  if (cnt>50){  console.log("alert point safety value!!!"); break; }  //only because I get nervous
            

        } while (alertNextToken !== null );


        // Step 2 :: Get alert messages
        //console.log("alertIDs", alertIDs);
        if (alertIDs.length){
            cnt=1;
            for(let ii=0; ii<alertIDs.length; ii++){

                do {  //loop thru messageNextToken
                    ret = await API.graphql(graphqlOperation(queryAlertByAlertIDRouteDay, 
                            {alertID: alertIDs[ii].id, routeday: routeDayFilter, filter: alertTypeFilter, limit: DB_LIMIT_ALERTSEARCH, messageNextToken: messageNextToken})
                        );     
                    // console.log("searchVehicleAlerts queryAlertByAlertIDRouteDay ret", ret);
    
                    if (ret.data.queryAlertByAlertIDRouteDay===null){ return []; }          
                    messageNextToken = ret.data.queryAlertByAlertIDRouteDay.nextToken;
                    
                    if (ret.data.queryAlertByAlertIDRouteDay.items.length){
                        messageItems = ret.data.queryAlertByAlertIDRouteDay.items;
                        for(let i3=0; i3<messageItems.length; i3++){
                            vehicleInFleet = false;

                            //find if this vehicle is in the selected fleet
                            if (messageItems[i3].vehicle.fleet){
                                if (messageItems[i3].vehicle.fleet.items){
                                    for(let i4=0; i4<messageItems[i3].vehicle.fleet.items.length; i4++){
                                        if (messageItems[i3].vehicle.fleet.items[i4].fleet){
                                            if (messageItems[i3].vehicle.fleet.items[i4].fleet.id===fleetid){
                                                vehicleInFleet = true;
                                                break;
                                            }
                                        }

                                    }
                                }
                            }

                            if (vehicleInFleet===true){
                                // this vehicle if in this fleet, return 
                                jsalert = {
                                    id: messageItems[i3].id, ts: messageItems[i3].timestamp, routeday: messageItems[i3].routeday,
                                    alertID: alertIDs[ii].id, alertName: alertIDs[ii].name, 
                                    alertType: messageItems[i3].alerttype, status: messageItems[i3].status,
                                    lat: parseFloat(messageItems[i3].lat), lng: parseFloat(messageItems[i3].lng), 
                                    message: messageItems[i3].message, violationJSON: messageItems[i3].violationJSON,
                                    vehicleID: messageItems[i3].vehicle.id, vehicleName: messageItems[i3].vehicle.vehiclename, driver: messageItems[i3].vehicle.driver, 
                                    eventid: messageItems[i3].eventID,
                                    searchhash: searchHash,
                                };
                                retAlerts.push(jsalert);
                            }

                        }
                    }
    
                    cnt++;  if (cnt>600){  console.log("alert point safety value!!!"); break; }  //only because I get nervous
    
                } while (messageNextToken !== null);        
    

            }
        }
        

        //important, (CLIENT SIDE) sort by the timestamp DESC so the oldest is at theop
        if (retAlerts.length){
            retAlerts.sort((a,b) => (a.ts < b.ts) ? 1 : -1); 
        } else {
            retAlerts.push({id: null, searchhash: searchHash});
        }


        return retAlerts;        

    } catch(e) {
        console.log("searchFleetAlerts ERROR");
        console.log(e);
        return null;
    }
}

export async function createFleetAlert(fleetid, alertname, tenant, alerttype, alertJSON ){
    // create a new alert and assign to fleet
    // RETURNS: object with new Alert ID and the new FleetAlert (many::many) id

    try {
        const timestamp = new Date().toISOString();
        const inputAlert = { alertname: alertname, tenant: tenant, is_active: true, alerttype: alerttype, alertJSON: alertJSON, timestamp: timestamp };
        const ret = await API.graphql(graphqlOperation(createGcadimeAlert, { input: inputAlert }))
        //console.log("createGcadimeAlert ret", ret);
        if (ret.data.createGcadimeAlert){
            const newalert = ret.data.createGcadimeAlert;
            const inputFleet = {tenant: tenant, gcadimeFleetAlertAlertId: newalert.id, gcadimeFleetAlertFleetId: fleetid};
            const ret2 = await API.graphql(graphqlOperation(createGcadimeFleetAlert, { input: inputFleet }))
            //console.log("createGcadimeFleetAlert ret2", ret2);
            if (ret2.data.createGcadimeFleetAlert){
                const newfleetalert = ret2.data.createGcadimeFleetAlert;
                const retNewIDs = {newAlertId: newalert.id, newFleetAlertID: newfleetalert.id};
                return retNewIDs;
            }
        }
        return {};
    } catch (e) {
        console.log("createFleetAlert ERROR");
        console.log(e);
        
    }
    return {};
}

export async function updateFleetAlert(alertid, alertname, alerttype, alertJSON ){
    // update existing alert
    // RETURNS: the same alert id

    try {
        const timestamp = new Date().toISOString();
        const inputAlert = { id: alertid, alertname: alertname, is_active: true, alerttype: alerttype, alertJSON: alertJSON, timestamp: timestamp };
        const ret = await API.graphql(graphqlOperation(updateGcadimeAlert, { input: inputAlert }))
        //console.log("updateGcadimeAlert ret", ret);
        if (ret.data.updateGcadimeAlert){
            const upalert = ret.data.updateGcadimeAlert;
            return upalert.id;
        }
    } catch (e) {
        console.log("updateFleetAlert ERROR");
        console.log(e);
        
    }
    return null;
}

export async function disableFleetAlert(alertid){
    // we actually "delete", but disable by setting is_active to false
    // RETURNS: object that includes the tenant name (business name)

    try {
        const inputAlert = { id: alertid, is_active: false};
        const ret = await API.graphql(graphqlOperation(updateGcadimeAlert, { input: inputAlert }))
        //console.log("updateGcadimeAlert ret", ret);
        if (ret.data.updateGcadimeAlert){
            const upalert = ret.data.updateGcadimeAlert;
            return upalert.id;
        }
    } catch (e) {
        console.log("disableFleetAlert ERROR");
        console.log(e);
        
    }
    return null;
}

export async function listAllAlerts(alerttype){
    // return all the Alert records this tenant
    //  filter by alerttype (or null to get all)
    var nextToken = null; var jsalert;
    var ret; var cnt=1; var alertItems;
    var alertTypeFilter = {};
    var retAlerts = [];

    try{

        //get all the Alert Messages records for this date, sort by time (the table @key "queryAlertByVehicleIDRouteDay" is by vehicleID and sort key timestamp)
        const listGcadimeAlerts = /* Strip down listGcadimeAlerts GraphQL */ `
        query ListGcadimeAlerts(
            $filter: ModelgcadimeAlertFilterInput
            $limit: Int
            $nextToken: String
          ) {
            listGcadimeAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                alertname
                is_active
                alerttype
              }
              nextToken
            }
          }
`;

        if (alerttype){
            if (Array.isArray(alerttype)){
                //   filter: {or: [{alerttype: {eq: HOURS}}, {alerttype: {eq: GEOFENCE}}] }
                alertTypeFilter = {or: []};
                for(let ia=0; ia<alerttype.length; ia++){
                    alertTypeFilter['or'].push( { alerttype: {eq: alerttype[ia]} } );
                }
            } else {
                alertTypeFilter['alerttype'].push( {eq: alerttype} );
            }
        }

        const filter = {...alertTypeFilter, is_active: {eq: true}}
        //console.log("listAllAlerts alertTypeFilter", JSON.stringify(filter) );

        do {  //loop thru nextTokens
            ret = await API.graphql(graphqlOperation(listGcadimeAlerts, 
                    {filter: filter, limit: DB_LIMIT_ALERTSEARCH, nextToken: nextToken})
                );     
            //console.log("listGcadimeAlerts ret", ret);

            if (ret.data.listGcadimeAlerts===null){ return []; }          
            nextToken = ret.data.listGcadimeAlerts.nextToken;
            
            if (ret.data.listGcadimeAlerts.items.length){
                alertItems = ret.data.listGcadimeAlerts.items;
                for(let i=0; i<alertItems.length; i++){
                    jsalert = { id: alertItems[i].id, alertName: alertItems[i].alertname, alertType: alertItems[i].alerttype };
                    retAlerts.push(jsalert);
                }
            }

            cnt++;  if (cnt>150){  console.log("alert point safety value!!!"); break; }  //only because I get nervous

        } while (nextToken !== null);

        return retAlerts;        

    } catch(e) {
        console.log("listAllAlerts ERROR");
        console.log(e);
        return null;
    }
}

export async function createFleetAlertLink(fleetid, alertid, tenant){
    // assign an alert to a fleet

    try {
        const inputLink = {tenant: tenant, gcadimeFleetAlertAlertId: alertid, gcadimeFleetAlertFleetId: fleetid};
        const ret = await API.graphql(graphqlOperation(createGcadimeFleetAlert, { input: inputLink }))
        //console.log("createGcadimeFleetAlert ret2", ret2);
        if (ret.data.createGcadimeFleetAlert){
            const newLink = ret.data.createGcadimeFleetAlert;
            const retIDs = {id: newLink.id, fleetID: newLink.fleet.id, alertID: newLink.alert.id};
            return retIDs;
        }
        return {};
    } catch (e) {
        console.log("createFleetAlertLink ERROR");
        console.log(e);
    }
    return {};
}