import React, { Component } from 'react';

import {MAIN_LANG} from '../main/main_lang';
const msg_main = MAIN_LANG.EN;

class Loading extends Component {
    render(){
        return(
            <div className="dime-loading">{msg_main.ICON_SPINNER}</div>
        );
    }
}

export default Loading;