export const APP_VERSION = "0.0.44";   //this must be n.n.nn so the versionRefresh comparison works (in other words, after 1.0.99: goto 1.1.01)
  //  0.0.01 - 09/08/2020 - init
  //  0.0.02 - 09/08/2020 - beta with homepage google map
  //  0.0.03 - 12/30/2020 - many dev deploys, now with home/tracking/behavior pages
  //  0.0.04 - 12/30/2020 - code complete for behavior and veh health pages
  //  0.0.05 - 01/12/2021 - alertmgr geofence mapping page-review
  //  0.0.06 - 01/15/2021 - alertmgr hours form page-review
  //  0.0.07 - 01/19/2021 - alertmgr bug fixes
  //  0.0.08 - 01/20/2021 - golfball icons (just puke a little inside your mouth)
  //  0.0.09 - 01/26/2021 - fleetmgr page
  //  0.0.10 - 02/08/2021 - usermgr user maintenance (still needs fleet maintenance???)
  //  0.0.11 - 02/09/2021 - removed relativeDates, shorter Alert msg on map popup, bug fix on 12am hoursForm
  //  0.0.12 - 02/12/2021 - ussemgr-fleet, user page, veh tracking end-caps and markers, react-clear-cache
  //  0.0.13 - 02/17/2021 - draw geofences on all maps when present/chosen
  //  0.0.14 - 02/19/2021 - bug fixes for local-tz routedays (alerts, maps, routeday dd, etc)
  //  0.0.15 - 02/23/2021 - reset pwd, switch buttons for checkboxes, fix alert tables jumping back to page 1
  //  0.0.17 - 02/24/2021 - (multiple v's) bug fix for safari date formats in route-dropdown
  //  0.0.18 - 02/27/2021 - map InfoWindow display new datapoints{} (new DIME and ODBII datastrings in SAF)
  //  0.0.19 - 03/02/2021 - manage datapoint display with mgs-main array/names/rounding; various
  //  0.0.20 - 03/03/2021 - removed Sit eng/oil/bat fields (all are now in datapoints{} )
  //  0.0.21 - 03/10/2021 - tracking page retool to SAF point centric
  //  0.0.22 - 03/16/2021 - clean up speed on tracking, fix fault descr on popups
  //  0.0.23 - 03/18/2021 - link fault-alerts to new fleets, cleanout old situation fields
  //  0.0.24 - 03/23/2021 - amberlamp now set in new element ids
  //  0.0.25 - 03/25/2021 - handle null datapoints (parsed values at 0xFF, 0xFFFF, 0xFFFFFF or 0x7FFFFFFF)
  //  0.0.26 - 03/26/2021 - handle 0 rounding of datapoints, cleaned up table gcadimeSituation (removed unwanted fields, added vin)
  //  0.0.28 - 04/13/2021 - bug fix to fleetDB buildvehecle to make sure FAULT only alerts get populated (v0.0.27 had syntax error)
  //  0.0.29 - 04/16/2021 - alertDB searchFleetAlerts filter search for vehicle specific fleets when FAULTS are in multiple fleets
  //  0.0.30 - 04/16/2021 - new global ids
  //  0.0.31 - 05/04/2021 - layer of icon markers (z-index) based on time stamp; sorting route color icon fix;
  //                        tracking.js subscript to update current vehicle (today)
  //  0.0.32 - 05/07/2021 - first cut with tabs on health page, also fixed bug on live subs on tracking
  //  0.0.33 - 05/19/2021 - first code release of DTC graphs on health page
  //  0.0.34 - 05/27/2021 - trigger alert display updates
  //  0.0.35 - 06/02/2021 - micro managing icons
  //  0.0.36 - 06/03/2021 - device serial# temporarily added to popups
  //  0.0.37 - 06/04/2021 - new datapoint for "Max over 60sec"
  //  0.0.38 - 06/08/2021 - behavior/health queries async bug fix
  //  0.0.39 - 06/18/2021 - format behav/health tables, max speed/gs/rotation
  //  0.0.40 - 06/22/2021 - preparing for Athena db buffer changes that won't break current build
  //                        buffer tab filter graphs with dropdown
  //  0.0.41 - 06/25/2021 - added other events (faults/triggers) as additional ref lines in dtc graphs
  //  0.0.42 - 07/02/2021 - geofenceForm.js bug fix with spherical.computeDistanceBetween()
  //                        display file s3 timestamp in veh popups
  //                        tracking table shows blue-star for multi-file SAF
  //  0.0.43 - 08/04/2021 - fix tracking table sorting (moved blue star to its own column)
  //                        add error rigging and restart failed subscriptions, verifySession on a timer
  //  0.0.44 - 08/20/2021 - add delay on subscription lookups to wait a few milliseconds until alerts are complete

///// env-config.js /////
  //  - pull any runtime configuration values from env-config.js (instead of .env in-case we want to change these once the build is compiled)
  // const APP_GA_LIVE = window.REACT_APP_GA;
  // const APP_GA_LOCAL3000 = "";
  // export const APP_GA = (process.env.NODE_ENV === 'production') ? APP_GA_LIVE : APP_GA_LOCAL3000 ;

//google analytics
export const APP_GA = process.env.REACT_APP_GOOGLE_ANALYTICS;

//AWS Pinpoint analytics
// to do when dev/prod environments setup

///// END env-config.js /////

// app constants
export const APP_NOT_AUTH = 'NOT_AUTHORIZED';
export const AUTHSTATE_LOADING = "loading";
export const AUTHSTATE_SIGNIN = "signin";
export const AUTHSTATE_SIGNEDIN = "signedin";
export const VERIFY_SESSION_TIMEOUT = 120000;  //1200000 = 20 mintues, 120000 = 2 mintues, 7200000 milliseconds = two hours
export const DD_FLEETMGRLINK = "fleetmgrlink";
export const COGNITO_ADMIN_GROUP = "appadmin";
// cookie constants
export const COOKIE_COLLAPSE = "dimemenucollapse";
export const COOKIE_FLEET = "dimefleet";
export const COOKIE_RESET = "dimereset";
// db contants
export const DB_LIMIT_DBCONFIGS = 20;
export const DB_LIMIT_FLEETS = 25;
export const DB_LIMIT_ALERTS = 25;
export const DB_LIMIT_VEHICLES = 25;
export const DB_LIMIT_ROUTEDAYS = 100;
export const DB_LIMIT_ALERTSEARCH = 100;
export const DB_LIMIT_USERS = 25;
export const KEY_DBCONFIG_APP_VERSION = "APP_VERSION";
export const KEY_DBCONFIG_GCP_MAP_API_ID = "GCP_MAP_API_ID";
export const KEY_USERTYPE_DIME = 'DIME';
export const KEY_USERTYPE_ADMIN = 'ADMIN';
export const KEY_USERTYPE_USER = 'USER';
// alert types
export const ALERT_TYPES = [
  // matches db enum gcadimeALERTTYPE
  "MAXSPEED", // #max speed
  "SPEEDING", //    #to be determined how to handle with sets of lat/lng points, places, snap-to-roads, etc.
  "GEOFENCE", //    #point in(or out) of polygon
  "HOURS", //       #outside hours of operation
  "HARD", //        #hard driving
  "FAULT", //       #any vehicle fault
  "ACCIDENT", //    #acceleromater   
//  "IDLE", //        #NOT IN DB, used for map icons
  "NORMAL", //      #NOT IN DB, used for map icons
];
export const ALERT_STATUS_OPEN = "OPEN";          //from gcadimeALERTSTATUS
export const ALERT_STATUS_CLEARED = "CLEARED";    //from gcadimeALERTSTATUS

export const FLEET_ASSIGN_UNASSIGNED = "UNASSIGNED";    //from gcadimeASSIGNED
export const FLEET_ASSIGN_ASSIGNED = "ASSIGNED";    //from gcadimeASSIGNED
export const UNASSIGNEDFLEETID = "UNASSIGNEDFLEETID";    //dummy fleet id for vehicles with no fleet
export const ALLFLEETID = "ALLFLEETIDS";    //dummy fleet id to single to get all fleets/user/veh/etc
