/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGcadimeDbConfig = /* GraphQL */ `
  mutation CreateGcadimeDbConfig(
    $input: CreateGcadimeDBConfigInput!
    $condition: ModelgcadimeDBConfigConditionInput
  ) {
    createGcadimeDBConfig(input: $input, condition: $condition) {
      key
      value
      timestamp
      notes
      createdAt
      updatedAt
    }
  }
`;
export const updateGcadimeDbConfig = /* GraphQL */ `
  mutation UpdateGcadimeDbConfig(
    $input: UpdateGcadimeDBConfigInput!
    $condition: ModelgcadimeDBConfigConditionInput
  ) {
    updateGcadimeDBConfig(input: $input, condition: $condition) {
      key
      value
      timestamp
      notes
      createdAt
      updatedAt
    }
  }
`;
export const deleteGcadimeDbConfig = /* GraphQL */ `
  mutation DeleteGcadimeDbConfig(
    $input: DeleteGcadimeDBConfigInput!
    $condition: ModelgcadimeDBConfigConditionInput
  ) {
    deleteGcadimeDBConfig(input: $input, condition: $condition) {
      key
      value
      timestamp
      notes
      createdAt
      updatedAt
    }
  }
`;
export const createGcadimeClient = /* GraphQL */ `
  mutation CreateGcadimeClient(
    $input: CreateGcadimeClientInput!
    $condition: ModelgcadimeClientConditionInput
  ) {
    createGcadimeClient(input: $input, condition: $condition) {
      tenant
      tenantname
      mapctr_lat
      mapctr_lng
      mapctr_zoom
      createdAt
      updatedAt
      users {
        items {
          id
          email
          username
          tenant
          userhash
          is_active
          usertype
          logins
          alert_email
          alert_sms
          sms_nbr
          lastlogin
          createdAt
          updatedAt
        }
        nextToken
      }
      devices {
        items {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateGcadimeClient = /* GraphQL */ `
  mutation UpdateGcadimeClient(
    $input: UpdateGcadimeClientInput!
    $condition: ModelgcadimeClientConditionInput
  ) {
    updateGcadimeClient(input: $input, condition: $condition) {
      tenant
      tenantname
      mapctr_lat
      mapctr_lng
      mapctr_zoom
      createdAt
      updatedAt
      users {
        items {
          id
          email
          username
          tenant
          userhash
          is_active
          usertype
          logins
          alert_email
          alert_sms
          sms_nbr
          lastlogin
          createdAt
          updatedAt
        }
        nextToken
      }
      devices {
        items {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteGcadimeClient = /* GraphQL */ `
  mutation DeleteGcadimeClient(
    $input: DeleteGcadimeClientInput!
    $condition: ModelgcadimeClientConditionInput
  ) {
    deleteGcadimeClient(input: $input, condition: $condition) {
      tenant
      tenantname
      mapctr_lat
      mapctr_lng
      mapctr_zoom
      createdAt
      updatedAt
      users {
        items {
          id
          email
          username
          tenant
          userhash
          is_active
          usertype
          logins
          alert_email
          alert_sms
          sms_nbr
          lastlogin
          createdAt
          updatedAt
        }
        nextToken
      }
      devices {
        items {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createGcadimeUser = /* GraphQL */ `
  mutation CreateGcadimeUser(
    $input: CreateGcadimeUserInput!
    $condition: ModelgcadimeUserConditionInput
  ) {
    createGcadimeUser(input: $input, condition: $condition) {
      id
      email
      username
      tenant
      userhash
      is_active
      usertype
      logins
      alert_email
      alert_sms
      sms_nbr
      lastlogin
      createdAt
      updatedAt
      client {
        tenant
        tenantname
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        createdAt
        updatedAt
        users {
          nextToken
        }
        devices {
          nextToken
        }
      }
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateGcadimeUser = /* GraphQL */ `
  mutation UpdateGcadimeUser(
    $input: UpdateGcadimeUserInput!
    $condition: ModelgcadimeUserConditionInput
  ) {
    updateGcadimeUser(input: $input, condition: $condition) {
      id
      email
      username
      tenant
      userhash
      is_active
      usertype
      logins
      alert_email
      alert_sms
      sms_nbr
      lastlogin
      createdAt
      updatedAt
      client {
        tenant
        tenantname
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        createdAt
        updatedAt
        users {
          nextToken
        }
        devices {
          nextToken
        }
      }
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteGcadimeUser = /* GraphQL */ `
  mutation DeleteGcadimeUser(
    $input: DeleteGcadimeUserInput!
    $condition: ModelgcadimeUserConditionInput
  ) {
    deleteGcadimeUser(input: $input, condition: $condition) {
      id
      email
      username
      tenant
      userhash
      is_active
      usertype
      logins
      alert_email
      alert_sms
      sms_nbr
      lastlogin
      createdAt
      updatedAt
      client {
        tenant
        tenantname
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        createdAt
        updatedAt
        users {
          nextToken
        }
        devices {
          nextToken
        }
      }
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createGcadimeDevice = /* GraphQL */ `
  mutation CreateGcadimeDevice(
    $input: CreateGcadimeDeviceInput!
    $condition: ModelgcadimeDeviceConditionInput
  ) {
    createGcadimeDevice(input: $input, condition: $condition) {
      serialno
      MINTnbr
      REEDnbr
      tenant
      devicename
      default_fleetID
      lastcheckin
      lastcheckinS3
      last_MINTnbr
      last_REEDnbr
      createdAt
      updatedAt
      client {
        tenant
        tenantname
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        createdAt
        updatedAt
        users {
          nextToken
        }
        devices {
          nextToken
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateGcadimeDevice = /* GraphQL */ `
  mutation UpdateGcadimeDevice(
    $input: UpdateGcadimeDeviceInput!
    $condition: ModelgcadimeDeviceConditionInput
  ) {
    updateGcadimeDevice(input: $input, condition: $condition) {
      serialno
      MINTnbr
      REEDnbr
      tenant
      devicename
      default_fleetID
      lastcheckin
      lastcheckinS3
      last_MINTnbr
      last_REEDnbr
      createdAt
      updatedAt
      client {
        tenant
        tenantname
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        createdAt
        updatedAt
        users {
          nextToken
        }
        devices {
          nextToken
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteGcadimeDevice = /* GraphQL */ `
  mutation DeleteGcadimeDevice(
    $input: DeleteGcadimeDeviceInput!
    $condition: ModelgcadimeDeviceConditionInput
  ) {
    deleteGcadimeDevice(input: $input, condition: $condition) {
      serialno
      MINTnbr
      REEDnbr
      tenant
      devicename
      default_fleetID
      lastcheckin
      lastcheckinS3
      last_MINTnbr
      last_REEDnbr
      createdAt
      updatedAt
      client {
        tenant
        tenantname
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        createdAt
        updatedAt
        users {
          nextToken
        }
        devices {
          nextToken
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createGcadimeSituation = /* GraphQL */ `
  mutation CreateGcadimeSituation(
    $input: CreateGcadimeSituationInput!
    $condition: ModelgcadimeSituationConditionInput
  ) {
    createGcadimeSituation(input: $input, condition: $condition) {
      situationid
      timestamp
      tenant
      situationtype
      deviceserialno
      vehicleID
      lat
      lng
      heading
      altitude
      tz_offset
      routeday
      datapoints
      speed
      speed_unit
      amberlamp
      max_speed
      max_gforce
      max_rotation
      vin
      s3key
      s3timestamp
      createdAt
      updatedAt
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
      alerts {
        items {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
      place {
        placeID
        speedlimit
        speedlimitunit
        address
        geocodeJSON
        timestamp
        createdAt
        updatedAt
        situtation {
          nextToken
        }
      }
    }
  }
`;
export const updateGcadimeSituation = /* GraphQL */ `
  mutation UpdateGcadimeSituation(
    $input: UpdateGcadimeSituationInput!
    $condition: ModelgcadimeSituationConditionInput
  ) {
    updateGcadimeSituation(input: $input, condition: $condition) {
      situationid
      timestamp
      tenant
      situationtype
      deviceserialno
      vehicleID
      lat
      lng
      heading
      altitude
      tz_offset
      routeday
      datapoints
      speed
      speed_unit
      amberlamp
      max_speed
      max_gforce
      max_rotation
      vin
      s3key
      s3timestamp
      createdAt
      updatedAt
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
      alerts {
        items {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
      place {
        placeID
        speedlimit
        speedlimitunit
        address
        geocodeJSON
        timestamp
        createdAt
        updatedAt
        situtation {
          nextToken
        }
      }
    }
  }
`;
export const deleteGcadimeSituation = /* GraphQL */ `
  mutation DeleteGcadimeSituation(
    $input: DeleteGcadimeSituationInput!
    $condition: ModelgcadimeSituationConditionInput
  ) {
    deleteGcadimeSituation(input: $input, condition: $condition) {
      situationid
      timestamp
      tenant
      situationtype
      deviceserialno
      vehicleID
      lat
      lng
      heading
      altitude
      tz_offset
      routeday
      datapoints
      speed
      speed_unit
      amberlamp
      max_speed
      max_gforce
      max_rotation
      vin
      s3key
      s3timestamp
      createdAt
      updatedAt
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
      alerts {
        items {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
      place {
        placeID
        speedlimit
        speedlimitunit
        address
        geocodeJSON
        timestamp
        createdAt
        updatedAt
        situtation {
          nextToken
        }
      }
    }
  }
`;
export const createGcadimeRouteDay = /* GraphQL */ `
  mutation CreateGcadimeRouteDay(
    $input: CreateGcadimeRouteDayInput!
    $condition: ModelgcadimeRouteDayConditionInput
  ) {
    createGcadimeRouteDay(input: $input, condition: $condition) {
      routehash
      tenant
      vehicleID
      routeday
      timestamp_start
      timestamp_end
      odometer_start
      odometer_end
      odometer_unit
      SAFcount
      SAFcount_speed
      deviceserialno
      MINTnbr
      REEDnbr
      createdAt
      updatedAt
    }
  }
`;
export const updateGcadimeRouteDay = /* GraphQL */ `
  mutation UpdateGcadimeRouteDay(
    $input: UpdateGcadimeRouteDayInput!
    $condition: ModelgcadimeRouteDayConditionInput
  ) {
    updateGcadimeRouteDay(input: $input, condition: $condition) {
      routehash
      tenant
      vehicleID
      routeday
      timestamp_start
      timestamp_end
      odometer_start
      odometer_end
      odometer_unit
      SAFcount
      SAFcount_speed
      deviceserialno
      MINTnbr
      REEDnbr
      createdAt
      updatedAt
    }
  }
`;
export const deleteGcadimeRouteDay = /* GraphQL */ `
  mutation DeleteGcadimeRouteDay(
    $input: DeleteGcadimeRouteDayInput!
    $condition: ModelgcadimeRouteDayConditionInput
  ) {
    deleteGcadimeRouteDay(input: $input, condition: $condition) {
      routehash
      tenant
      vehicleID
      routeday
      timestamp_start
      timestamp_end
      odometer_start
      odometer_end
      odometer_unit
      SAFcount
      SAFcount_speed
      deviceserialno
      MINTnbr
      REEDnbr
      createdAt
      updatedAt
    }
  }
`;
export const createGcadimeFleet = /* GraphQL */ `
  mutation CreateGcadimeFleet(
    $input: CreateGcadimeFleetInput!
    $condition: ModelgcadimeFleetConditionInput
  ) {
    createGcadimeFleet(input: $input, condition: $condition) {
      id
      fleetname
      tenant
      mapctr_lat
      mapctr_lng
      mapctr_zoom
      is_prime
      createdAt
      updatedAt
      vehicle {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      alert {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateGcadimeFleet = /* GraphQL */ `
  mutation UpdateGcadimeFleet(
    $input: UpdateGcadimeFleetInput!
    $condition: ModelgcadimeFleetConditionInput
  ) {
    updateGcadimeFleet(input: $input, condition: $condition) {
      id
      fleetname
      tenant
      mapctr_lat
      mapctr_lng
      mapctr_zoom
      is_prime
      createdAt
      updatedAt
      vehicle {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      alert {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteGcadimeFleet = /* GraphQL */ `
  mutation DeleteGcadimeFleet(
    $input: DeleteGcadimeFleetInput!
    $condition: ModelgcadimeFleetConditionInput
  ) {
    deleteGcadimeFleet(input: $input, condition: $condition) {
      id
      fleetname
      tenant
      mapctr_lat
      mapctr_lng
      mapctr_zoom
      is_prime
      createdAt
      updatedAt
      vehicle {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      alert {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createGcadimeVehicle = /* GraphQL */ `
  mutation CreateGcadimeVehicle(
    $input: CreateGcadimeVehicleInput!
    $condition: ModelgcadimeVehicleConditionInput
  ) {
    createGcadimeVehicle(input: $input, condition: $condition) {
      id
      vehiclename
      tenant
      vin
      driver
      make
      model
      year
      icon
      alerttype
      fleet_assignment
      last_updatetime
      createdAt
      updatedAt
      device {
        serialno
        MINTnbr
        REEDnbr
        tenant
        devicename
        default_fleetID
        lastcheckin
        lastcheckinS3
        last_MINTnbr
        last_REEDnbr
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
      }
      last_situation {
        situationid
        timestamp
        tenant
        situationtype
        deviceserialno
        vehicleID
        lat
        lng
        heading
        altitude
        tz_offset
        routeday
        datapoints
        speed
        speed_unit
        amberlamp
        max_speed
        max_gforce
        max_rotation
        vin
        s3key
        s3timestamp
        createdAt
        updatedAt
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alerts {
          nextToken
        }
        place {
          placeID
          speedlimit
          speedlimitunit
          address
          geocodeJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      last_alert {
        id
        tenant
        timestamp
        alertID
        alerttype
        vehicleID
        violationJSON
        message
        status
        lat
        lng
        placeID
        tz_offset
        routeday
        eventID
        createdAt
        updatedAt
        situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alert {
          id
          alertname
          tenant
          is_active
          alerttype
          alertJSON
          timestamp
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateGcadimeVehicle = /* GraphQL */ `
  mutation UpdateGcadimeVehicle(
    $input: UpdateGcadimeVehicleInput!
    $condition: ModelgcadimeVehicleConditionInput
  ) {
    updateGcadimeVehicle(input: $input, condition: $condition) {
      id
      vehiclename
      tenant
      vin
      driver
      make
      model
      year
      icon
      alerttype
      fleet_assignment
      last_updatetime
      createdAt
      updatedAt
      device {
        serialno
        MINTnbr
        REEDnbr
        tenant
        devicename
        default_fleetID
        lastcheckin
        lastcheckinS3
        last_MINTnbr
        last_REEDnbr
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
      }
      last_situation {
        situationid
        timestamp
        tenant
        situationtype
        deviceserialno
        vehicleID
        lat
        lng
        heading
        altitude
        tz_offset
        routeday
        datapoints
        speed
        speed_unit
        amberlamp
        max_speed
        max_gforce
        max_rotation
        vin
        s3key
        s3timestamp
        createdAt
        updatedAt
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alerts {
          nextToken
        }
        place {
          placeID
          speedlimit
          speedlimitunit
          address
          geocodeJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      last_alert {
        id
        tenant
        timestamp
        alertID
        alerttype
        vehicleID
        violationJSON
        message
        status
        lat
        lng
        placeID
        tz_offset
        routeday
        eventID
        createdAt
        updatedAt
        situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alert {
          id
          alertname
          tenant
          is_active
          alerttype
          alertJSON
          timestamp
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteGcadimeVehicle = /* GraphQL */ `
  mutation DeleteGcadimeVehicle(
    $input: DeleteGcadimeVehicleInput!
    $condition: ModelgcadimeVehicleConditionInput
  ) {
    deleteGcadimeVehicle(input: $input, condition: $condition) {
      id
      vehiclename
      tenant
      vin
      driver
      make
      model
      year
      icon
      alerttype
      fleet_assignment
      last_updatetime
      createdAt
      updatedAt
      device {
        serialno
        MINTnbr
        REEDnbr
        tenant
        devicename
        default_fleetID
        lastcheckin
        lastcheckinS3
        last_MINTnbr
        last_REEDnbr
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
      }
      last_situation {
        situationid
        timestamp
        tenant
        situationtype
        deviceserialno
        vehicleID
        lat
        lng
        heading
        altitude
        tz_offset
        routeday
        datapoints
        speed
        speed_unit
        amberlamp
        max_speed
        max_gforce
        max_rotation
        vin
        s3key
        s3timestamp
        createdAt
        updatedAt
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alerts {
          nextToken
        }
        place {
          placeID
          speedlimit
          speedlimitunit
          address
          geocodeJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      last_alert {
        id
        tenant
        timestamp
        alertID
        alerttype
        vehicleID
        violationJSON
        message
        status
        lat
        lng
        placeID
        tz_offset
        routeday
        eventID
        createdAt
        updatedAt
        situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alert {
          id
          alertname
          tenant
          is_active
          alerttype
          alertJSON
          timestamp
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createGcadimeFleetVehicle = /* GraphQL */ `
  mutation CreateGcadimeFleetVehicle(
    $input: CreateGcadimeFleetVehicleInput!
    $condition: ModelgcadimeFleetVehicleConditionInput
  ) {
    createGcadimeFleetVehicle(input: $input, condition: $condition) {
      id
      tenant
      createdAt
      updatedAt
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateGcadimeFleetVehicle = /* GraphQL */ `
  mutation UpdateGcadimeFleetVehicle(
    $input: UpdateGcadimeFleetVehicleInput!
    $condition: ModelgcadimeFleetVehicleConditionInput
  ) {
    updateGcadimeFleetVehicle(input: $input, condition: $condition) {
      id
      tenant
      createdAt
      updatedAt
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteGcadimeFleetVehicle = /* GraphQL */ `
  mutation DeleteGcadimeFleetVehicle(
    $input: DeleteGcadimeFleetVehicleInput!
    $condition: ModelgcadimeFleetVehicleConditionInput
  ) {
    deleteGcadimeFleetVehicle(input: $input, condition: $condition) {
      id
      tenant
      createdAt
      updatedAt
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createGcadimeFleetUser = /* GraphQL */ `
  mutation CreateGcadimeFleetUser(
    $input: CreateGcadimeFleetUserInput!
    $condition: ModelgcadimeFleetUserConditionInput
  ) {
    createGcadimeFleetUser(input: $input, condition: $condition) {
      id
      tenant
      createdAt
      updatedAt
      user {
        id
        email
        username
        tenant
        userhash
        is_active
        usertype
        logins
        alert_email
        alert_sms
        sms_nbr
        lastlogin
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
      }
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
    }
  }
`;
export const updateGcadimeFleetUser = /* GraphQL */ `
  mutation UpdateGcadimeFleetUser(
    $input: UpdateGcadimeFleetUserInput!
    $condition: ModelgcadimeFleetUserConditionInput
  ) {
    updateGcadimeFleetUser(input: $input, condition: $condition) {
      id
      tenant
      createdAt
      updatedAt
      user {
        id
        email
        username
        tenant
        userhash
        is_active
        usertype
        logins
        alert_email
        alert_sms
        sms_nbr
        lastlogin
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
      }
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
    }
  }
`;
export const deleteGcadimeFleetUser = /* GraphQL */ `
  mutation DeleteGcadimeFleetUser(
    $input: DeleteGcadimeFleetUserInput!
    $condition: ModelgcadimeFleetUserConditionInput
  ) {
    deleteGcadimeFleetUser(input: $input, condition: $condition) {
      id
      tenant
      createdAt
      updatedAt
      user {
        id
        email
        username
        tenant
        userhash
        is_active
        usertype
        logins
        alert_email
        alert_sms
        sms_nbr
        lastlogin
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
      }
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
    }
  }
`;
export const createGcadimeFleetAlert = /* GraphQL */ `
  mutation CreateGcadimeFleetAlert(
    $input: CreateGcadimeFleetAlertInput!
    $condition: ModelgcadimeFleetAlertConditionInput
  ) {
    createGcadimeFleetAlert(input: $input, condition: $condition) {
      id
      tenant
      createdAt
      updatedAt
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
      alert {
        id
        alertname
        tenant
        is_active
        alerttype
        alertJSON
        timestamp
        createdAt
        updatedAt
        fleet {
          nextToken
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const updateGcadimeFleetAlert = /* GraphQL */ `
  mutation UpdateGcadimeFleetAlert(
    $input: UpdateGcadimeFleetAlertInput!
    $condition: ModelgcadimeFleetAlertConditionInput
  ) {
    updateGcadimeFleetAlert(input: $input, condition: $condition) {
      id
      tenant
      createdAt
      updatedAt
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
      alert {
        id
        alertname
        tenant
        is_active
        alerttype
        alertJSON
        timestamp
        createdAt
        updatedAt
        fleet {
          nextToken
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const deleteGcadimeFleetAlert = /* GraphQL */ `
  mutation DeleteGcadimeFleetAlert(
    $input: DeleteGcadimeFleetAlertInput!
    $condition: ModelgcadimeFleetAlertConditionInput
  ) {
    deleteGcadimeFleetAlert(input: $input, condition: $condition) {
      id
      tenant
      createdAt
      updatedAt
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
      alert {
        id
        alertname
        tenant
        is_active
        alerttype
        alertJSON
        timestamp
        createdAt
        updatedAt
        fleet {
          nextToken
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const createGcadimeAlertDefault = /* GraphQL */ `
  mutation CreateGcadimeAlertDefault(
    $input: CreateGcadimeAlertDefaultInput!
    $condition: ModelgcadimeAlertDefaultConditionInput
  ) {
    createGcadimeAlertDefault(input: $input, condition: $condition) {
      id
      alertname
      alerttype
      alertJSON
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateGcadimeAlertDefault = /* GraphQL */ `
  mutation UpdateGcadimeAlertDefault(
    $input: UpdateGcadimeAlertDefaultInput!
    $condition: ModelgcadimeAlertDefaultConditionInput
  ) {
    updateGcadimeAlertDefault(input: $input, condition: $condition) {
      id
      alertname
      alerttype
      alertJSON
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteGcadimeAlertDefault = /* GraphQL */ `
  mutation DeleteGcadimeAlertDefault(
    $input: DeleteGcadimeAlertDefaultInput!
    $condition: ModelgcadimeAlertDefaultConditionInput
  ) {
    deleteGcadimeAlertDefault(input: $input, condition: $condition) {
      id
      alertname
      alerttype
      alertJSON
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const createGcadimeAlert = /* GraphQL */ `
  mutation CreateGcadimeAlert(
    $input: CreateGcadimeAlertInput!
    $condition: ModelgcadimeAlertConditionInput
  ) {
    createGcadimeAlert(input: $input, condition: $condition) {
      id
      alertname
      tenant
      is_active
      alerttype
      alertJSON
      timestamp
      createdAt
      updatedAt
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateGcadimeAlert = /* GraphQL */ `
  mutation UpdateGcadimeAlert(
    $input: UpdateGcadimeAlertInput!
    $condition: ModelgcadimeAlertConditionInput
  ) {
    updateGcadimeAlert(input: $input, condition: $condition) {
      id
      alertname
      tenant
      is_active
      alerttype
      alertJSON
      timestamp
      createdAt
      updatedAt
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteGcadimeAlert = /* GraphQL */ `
  mutation DeleteGcadimeAlert(
    $input: DeleteGcadimeAlertInput!
    $condition: ModelgcadimeAlertConditionInput
  ) {
    deleteGcadimeAlert(input: $input, condition: $condition) {
      id
      alertname
      tenant
      is_active
      alerttype
      alertJSON
      timestamp
      createdAt
      updatedAt
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createGcadimeAlertMessage = /* GraphQL */ `
  mutation CreateGcadimeAlertMessage(
    $input: CreateGcadimeAlertMessageInput!
    $condition: ModelgcadimeAlertMessageConditionInput
  ) {
    createGcadimeAlertMessage(input: $input, condition: $condition) {
      id
      tenant
      timestamp
      alertID
      alerttype
      vehicleID
      violationJSON
      message
      status
      lat
      lng
      placeID
      tz_offset
      routeday
      eventID
      createdAt
      updatedAt
      situation {
        situationid
        timestamp
        tenant
        situationtype
        deviceserialno
        vehicleID
        lat
        lng
        heading
        altitude
        tz_offset
        routeday
        datapoints
        speed
        speed_unit
        amberlamp
        max_speed
        max_gforce
        max_rotation
        vin
        s3key
        s3timestamp
        createdAt
        updatedAt
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alerts {
          nextToken
        }
        place {
          placeID
          speedlimit
          speedlimitunit
          address
          geocodeJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
      alert {
        id
        alertname
        tenant
        is_active
        alerttype
        alertJSON
        timestamp
        createdAt
        updatedAt
        fleet {
          nextToken
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const updateGcadimeAlertMessage = /* GraphQL */ `
  mutation UpdateGcadimeAlertMessage(
    $input: UpdateGcadimeAlertMessageInput!
    $condition: ModelgcadimeAlertMessageConditionInput
  ) {
    updateGcadimeAlertMessage(input: $input, condition: $condition) {
      id
      tenant
      timestamp
      alertID
      alerttype
      vehicleID
      violationJSON
      message
      status
      lat
      lng
      placeID
      tz_offset
      routeday
      eventID
      createdAt
      updatedAt
      situation {
        situationid
        timestamp
        tenant
        situationtype
        deviceserialno
        vehicleID
        lat
        lng
        heading
        altitude
        tz_offset
        routeday
        datapoints
        speed
        speed_unit
        amberlamp
        max_speed
        max_gforce
        max_rotation
        vin
        s3key
        s3timestamp
        createdAt
        updatedAt
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alerts {
          nextToken
        }
        place {
          placeID
          speedlimit
          speedlimitunit
          address
          geocodeJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
      alert {
        id
        alertname
        tenant
        is_active
        alerttype
        alertJSON
        timestamp
        createdAt
        updatedAt
        fleet {
          nextToken
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const deleteGcadimeAlertMessage = /* GraphQL */ `
  mutation DeleteGcadimeAlertMessage(
    $input: DeleteGcadimeAlertMessageInput!
    $condition: ModelgcadimeAlertMessageConditionInput
  ) {
    deleteGcadimeAlertMessage(input: $input, condition: $condition) {
      id
      tenant
      timestamp
      alertID
      alerttype
      vehicleID
      violationJSON
      message
      status
      lat
      lng
      placeID
      tz_offset
      routeday
      eventID
      createdAt
      updatedAt
      situation {
        situationid
        timestamp
        tenant
        situationtype
        deviceserialno
        vehicleID
        lat
        lng
        heading
        altitude
        tz_offset
        routeday
        datapoints
        speed
        speed_unit
        amberlamp
        max_speed
        max_gforce
        max_rotation
        vin
        s3key
        s3timestamp
        createdAt
        updatedAt
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alerts {
          nextToken
        }
        place {
          placeID
          speedlimit
          speedlimitunit
          address
          geocodeJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
      alert {
        id
        alertname
        tenant
        is_active
        alerttype
        alertJSON
        timestamp
        createdAt
        updatedAt
        fleet {
          nextToken
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const createGcadimeGps = /* GraphQL */ `
  mutation CreateGcadimeGps(
    $input: CreateGcadimeGPSInput!
    $condition: ModelgcadimeGPSConditionInput
  ) {
    createGcadimeGPS(input: $input, condition: $condition) {
      gpskey
      lat
      lng
      address
      geocodeJSON
      timestamp
      placeID
      createdAt
      updatedAt
    }
  }
`;
export const updateGcadimeGps = /* GraphQL */ `
  mutation UpdateGcadimeGps(
    $input: UpdateGcadimeGPSInput!
    $condition: ModelgcadimeGPSConditionInput
  ) {
    updateGcadimeGPS(input: $input, condition: $condition) {
      gpskey
      lat
      lng
      address
      geocodeJSON
      timestamp
      placeID
      createdAt
      updatedAt
    }
  }
`;
export const deleteGcadimeGps = /* GraphQL */ `
  mutation DeleteGcadimeGps(
    $input: DeleteGcadimeGPSInput!
    $condition: ModelgcadimeGPSConditionInput
  ) {
    deleteGcadimeGPS(input: $input, condition: $condition) {
      gpskey
      lat
      lng
      address
      geocodeJSON
      timestamp
      placeID
      createdAt
      updatedAt
    }
  }
`;
export const createGcadimePlace = /* GraphQL */ `
  mutation CreateGcadimePlace(
    $input: CreateGcadimePlaceInput!
    $condition: ModelgcadimePlaceConditionInput
  ) {
    createGcadimePlace(input: $input, condition: $condition) {
      placeID
      speedlimit
      speedlimitunit
      address
      geocodeJSON
      timestamp
      createdAt
      updatedAt
      situtation {
        items {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateGcadimePlace = /* GraphQL */ `
  mutation UpdateGcadimePlace(
    $input: UpdateGcadimePlaceInput!
    $condition: ModelgcadimePlaceConditionInput
  ) {
    updateGcadimePlace(input: $input, condition: $condition) {
      placeID
      speedlimit
      speedlimitunit
      address
      geocodeJSON
      timestamp
      createdAt
      updatedAt
      situtation {
        items {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteGcadimePlace = /* GraphQL */ `
  mutation DeleteGcadimePlace(
    $input: DeleteGcadimePlaceInput!
    $condition: ModelgcadimePlaceConditionInput
  ) {
    deleteGcadimePlace(input: $input, condition: $condition) {
      placeID
      speedlimit
      speedlimitunit
      address
      geocodeJSON
      timestamp
      createdAt
      updatedAt
      situtation {
        items {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createGcadimeReed = /* GraphQL */ `
  mutation CreateGcadimeReed(
    $input: CreateGcadimeREEDInput!
    $condition: ModelgcadimeREEDConditionInput
  ) {
    createGcadimeREED(input: $input, condition: $condition) {
      REEDnbr
      REEDname
      MINTnbr
      tenant
      is_active
      filetype
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateGcadimeReed = /* GraphQL */ `
  mutation UpdateGcadimeReed(
    $input: UpdateGcadimeREEDInput!
    $condition: ModelgcadimeREEDConditionInput
  ) {
    updateGcadimeREED(input: $input, condition: $condition) {
      REEDnbr
      REEDname
      MINTnbr
      tenant
      is_active
      filetype
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteGcadimeReed = /* GraphQL */ `
  mutation DeleteGcadimeReed(
    $input: DeleteGcadimeREEDInput!
    $condition: ModelgcadimeREEDConditionInput
  ) {
    deleteGcadimeREED(input: $input, condition: $condition) {
      REEDnbr
      REEDname
      MINTnbr
      tenant
      is_active
      filetype
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const createGcadimePid = /* GraphQL */ `
  mutation CreateGcadimePid(
    $input: CreateGcadimePIDInput!
    $condition: ModelgcadimePIDConditionInput
  ) {
    createGcadimePID(input: $input, condition: $condition) {
      globalID
      uPIDid
      uPIDname
      scale
      offset
      units
      safeunits
      createdAt
      updatedAt
    }
  }
`;
export const updateGcadimePid = /* GraphQL */ `
  mutation UpdateGcadimePid(
    $input: UpdateGcadimePIDInput!
    $condition: ModelgcadimePIDConditionInput
  ) {
    updateGcadimePID(input: $input, condition: $condition) {
      globalID
      uPIDid
      uPIDname
      scale
      offset
      units
      safeunits
      createdAt
      updatedAt
    }
  }
`;
export const deleteGcadimePid = /* GraphQL */ `
  mutation DeleteGcadimePid(
    $input: DeleteGcadimePIDInput!
    $condition: ModelgcadimePIDConditionInput
  ) {
    deleteGcadimePID(input: $input, condition: $condition) {
      globalID
      uPIDid
      uPIDname
      scale
      offset
      units
      safeunits
      createdAt
      updatedAt
    }
  }
`;
