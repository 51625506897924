/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGcadimeDbConfig = /* GraphQL */ `
  query GetGcadimeDbConfig($key: String!) {
    getGcadimeDBConfig(key: $key) {
      key
      value
      timestamp
      notes
      createdAt
      updatedAt
    }
  }
`;
export const listGcadimeDbConfigs = /* GraphQL */ `
  query ListGcadimeDbConfigs(
    $key: String
    $filter: ModelgcadimeDBConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGcadimeDBConfigs(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        value
        timestamp
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGcadimeClient = /* GraphQL */ `
  query GetGcadimeClient($tenant: String!) {
    getGcadimeClient(tenant: $tenant) {
      tenant
      tenantname
      mapctr_lat
      mapctr_lng
      mapctr_zoom
      createdAt
      updatedAt
      users {
        items {
          id
          email
          username
          tenant
          userhash
          is_active
          usertype
          logins
          alert_email
          alert_sms
          sms_nbr
          lastlogin
          createdAt
          updatedAt
        }
        nextToken
      }
      devices {
        items {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listGcadimeClients = /* GraphQL */ `
  query ListGcadimeClients(
    $tenant: String
    $filter: ModelgcadimeClientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGcadimeClients(
      tenant: $tenant
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenant
        tenantname
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        createdAt
        updatedAt
        users {
          nextToken
        }
        devices {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeUser = /* GraphQL */ `
  query GetGcadimeUser($id: ID!) {
    getGcadimeUser(id: $id) {
      id
      email
      username
      tenant
      userhash
      is_active
      usertype
      logins
      alert_email
      alert_sms
      sms_nbr
      lastlogin
      createdAt
      updatedAt
      client {
        tenant
        tenantname
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        createdAt
        updatedAt
        users {
          nextToken
        }
        devices {
          nextToken
        }
      }
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listGcadimeUsers = /* GraphQL */ `
  query ListGcadimeUsers(
    $filter: ModelgcadimeUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGcadimeUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        username
        tenant
        userhash
        is_active
        usertype
        logins
        alert_email
        alert_sms
        sms_nbr
        lastlogin
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const queryUserbyEmail = /* GraphQL */ `
  query QueryUserbyEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelgcadimeUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryUserbyEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        username
        tenant
        userhash
        is_active
        usertype
        logins
        alert_email
        alert_sms
        sms_nbr
        lastlogin
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const querygcadimeUserbyTenant = /* GraphQL */ `
  query QuerygcadimeUserbyTenant(
    $tenant: String
    $usertype: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelgcadimeUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    querygcadimeUserbyTenant(
      tenant: $tenant
      usertype: $usertype
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        username
        tenant
        userhash
        is_active
        usertype
        logins
        alert_email
        alert_sms
        sms_nbr
        lastlogin
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeDevice = /* GraphQL */ `
  query GetGcadimeDevice($serialno: String!) {
    getGcadimeDevice(serialno: $serialno) {
      serialno
      MINTnbr
      REEDnbr
      tenant
      devicename
      default_fleetID
      lastcheckin
      lastcheckinS3
      last_MINTnbr
      last_REEDnbr
      createdAt
      updatedAt
      client {
        tenant
        tenantname
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        createdAt
        updatedAt
        users {
          nextToken
        }
        devices {
          nextToken
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listGcadimeDevices = /* GraphQL */ `
  query ListGcadimeDevices(
    $serialno: String
    $filter: ModelgcadimeDeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGcadimeDevices(
      serialno: $serialno
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        serialno
        MINTnbr
        REEDnbr
        tenant
        devicename
        default_fleetID
        lastcheckin
        lastcheckinS3
        last_MINTnbr
        last_REEDnbr
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeSituation = /* GraphQL */ `
  query GetGcadimeSituation($situationid: String!) {
    getGcadimeSituation(situationid: $situationid) {
      situationid
      timestamp
      tenant
      situationtype
      deviceserialno
      vehicleID
      lat
      lng
      heading
      altitude
      tz_offset
      routeday
      datapoints
      speed
      speed_unit
      amberlamp
      max_speed
      max_gforce
      max_rotation
      vin
      s3key
      s3timestamp
      createdAt
      updatedAt
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
      alerts {
        items {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
      place {
        placeID
        speedlimit
        speedlimitunit
        address
        geocodeJSON
        timestamp
        createdAt
        updatedAt
        situtation {
          nextToken
        }
      }
    }
  }
`;
export const listGcadimeSituations = /* GraphQL */ `
  query ListGcadimeSituations(
    $situationid: String
    $filter: ModelgcadimeSituationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGcadimeSituations(
      situationid: $situationid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        situationid
        timestamp
        tenant
        situationtype
        deviceserialno
        vehicleID
        lat
        lng
        heading
        altitude
        tz_offset
        routeday
        datapoints
        speed
        speed_unit
        amberlamp
        max_speed
        max_gforce
        max_rotation
        vin
        s3key
        s3timestamp
        createdAt
        updatedAt
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alerts {
          nextToken
        }
        place {
          placeID
          speedlimit
          speedlimitunit
          address
          geocodeJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const querySituationByVehRouteDay = /* GraphQL */ `
  query QuerySituationByVehRouteDay(
    $vehicleID: ID
    $routeday: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelgcadimeSituationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    querySituationByVehRouteDay(
      vehicleID: $vehicleID
      routeday: $routeday
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        situationid
        timestamp
        tenant
        situationtype
        deviceserialno
        vehicleID
        lat
        lng
        heading
        altitude
        tz_offset
        routeday
        datapoints
        speed
        speed_unit
        amberlamp
        max_speed
        max_gforce
        max_rotation
        vin
        s3key
        s3timestamp
        createdAt
        updatedAt
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alerts {
          nextToken
        }
        place {
          placeID
          speedlimit
          speedlimitunit
          address
          geocodeJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeRouteDay = /* GraphQL */ `
  query GetGcadimeRouteDay($routehash: String!) {
    getGcadimeRouteDay(routehash: $routehash) {
      routehash
      tenant
      vehicleID
      routeday
      timestamp_start
      timestamp_end
      odometer_start
      odometer_end
      odometer_unit
      SAFcount
      SAFcount_speed
      deviceserialno
      MINTnbr
      REEDnbr
      createdAt
      updatedAt
    }
  }
`;
export const listGcadimeRouteDays = /* GraphQL */ `
  query ListGcadimeRouteDays(
    $routehash: String
    $filter: ModelgcadimeRouteDayFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGcadimeRouteDays(
      routehash: $routehash
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        routehash
        tenant
        vehicleID
        routeday
        timestamp_start
        timestamp_end
        odometer_start
        odometer_end
        odometer_unit
        SAFcount
        SAFcount_speed
        deviceserialno
        MINTnbr
        REEDnbr
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryVehicleByRouteDay = /* GraphQL */ `
  query QueryVehicleByRouteDay(
    $vehicleID: ID
    $routeday: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelgcadimeRouteDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryVehicleByRouteDay(
      vehicleID: $vehicleID
      routeday: $routeday
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        routehash
        tenant
        vehicleID
        routeday
        timestamp_start
        timestamp_end
        odometer_start
        odometer_end
        odometer_unit
        SAFcount
        SAFcount_speed
        deviceserialno
        MINTnbr
        REEDnbr
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGcadimeFleet = /* GraphQL */ `
  query GetGcadimeFleet($id: ID!) {
    getGcadimeFleet(id: $id) {
      id
      fleetname
      tenant
      mapctr_lat
      mapctr_lng
      mapctr_zoom
      is_prime
      createdAt
      updatedAt
      vehicle {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      alert {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listGcadimeFleets = /* GraphQL */ `
  query ListGcadimeFleets(
    $filter: ModelgcadimeFleetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGcadimeFleets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const queryFleetNames = /* GraphQL */ `
  query QueryFleetNames(
    $tenant: String
    $fleetname: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelgcadimeFleetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryFleetNames(
      tenant: $tenant
      fleetname: $fleetname
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeVehicle = /* GraphQL */ `
  query GetGcadimeVehicle($id: ID!) {
    getGcadimeVehicle(id: $id) {
      id
      vehiclename
      tenant
      vin
      driver
      make
      model
      year
      icon
      alerttype
      fleet_assignment
      last_updatetime
      createdAt
      updatedAt
      device {
        serialno
        MINTnbr
        REEDnbr
        tenant
        devicename
        default_fleetID
        lastcheckin
        lastcheckinS3
        last_MINTnbr
        last_REEDnbr
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
      }
      last_situation {
        situationid
        timestamp
        tenant
        situationtype
        deviceserialno
        vehicleID
        lat
        lng
        heading
        altitude
        tz_offset
        routeday
        datapoints
        speed
        speed_unit
        amberlamp
        max_speed
        max_gforce
        max_rotation
        vin
        s3key
        s3timestamp
        createdAt
        updatedAt
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alerts {
          nextToken
        }
        place {
          placeID
          speedlimit
          speedlimitunit
          address
          geocodeJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      last_alert {
        id
        tenant
        timestamp
        alertID
        alerttype
        vehicleID
        violationJSON
        message
        status
        lat
        lng
        placeID
        tz_offset
        routeday
        eventID
        createdAt
        updatedAt
        situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alert {
          id
          alertname
          tenant
          is_active
          alerttype
          alertJSON
          timestamp
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listGcadimeVehicles = /* GraphQL */ `
  query ListGcadimeVehicles(
    $filter: ModelgcadimeVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGcadimeVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryVehicleByVin = /* GraphQL */ `
  query QueryVehicleByVin(
    $vin: String
    $tenant: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelgcadimeVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryVehicleByVIN(
      vin: $vin
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryTenantFleetAssignment = /* GraphQL */ `
  query QueryTenantFleetAssignment(
    $tenant: String
    $fleet_assignment: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelgcadimeVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryTenantFleetAssignment(
      tenant: $tenant
      fleet_assignment: $fleet_assignment
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeFleetVehicle = /* GraphQL */ `
  query GetGcadimeFleetVehicle($id: ID!) {
    getGcadimeFleetVehicle(id: $id) {
      id
      tenant
      createdAt
      updatedAt
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listGcadimeFleetVehicles = /* GraphQL */ `
  query ListGcadimeFleetVehicles(
    $filter: ModelgcadimeFleetVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGcadimeFleetVehicles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        createdAt
        updatedAt
        fleet {
          id
          fleetname
          tenant
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          is_prime
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeFleetUser = /* GraphQL */ `
  query GetGcadimeFleetUser($id: ID!) {
    getGcadimeFleetUser(id: $id) {
      id
      tenant
      createdAt
      updatedAt
      user {
        id
        email
        username
        tenant
        userhash
        is_active
        usertype
        logins
        alert_email
        alert_sms
        sms_nbr
        lastlogin
        createdAt
        updatedAt
        client {
          tenant
          tenantname
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
      }
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
    }
  }
`;
export const listGcadimeFleetUsers = /* GraphQL */ `
  query ListGcadimeFleetUsers(
    $filter: ModelgcadimeFleetUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGcadimeFleetUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        createdAt
        updatedAt
        user {
          id
          email
          username
          tenant
          userhash
          is_active
          usertype
          logins
          alert_email
          alert_sms
          sms_nbr
          lastlogin
          createdAt
          updatedAt
        }
        fleet {
          id
          fleetname
          tenant
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          is_prime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeFleetAlert = /* GraphQL */ `
  query GetGcadimeFleetAlert($id: ID!) {
    getGcadimeFleetAlert(id: $id) {
      id
      tenant
      createdAt
      updatedAt
      fleet {
        id
        fleetname
        tenant
        mapctr_lat
        mapctr_lng
        mapctr_zoom
        is_prime
        createdAt
        updatedAt
        vehicle {
          nextToken
        }
        user {
          nextToken
        }
        alert {
          nextToken
        }
      }
      alert {
        id
        alertname
        tenant
        is_active
        alerttype
        alertJSON
        timestamp
        createdAt
        updatedAt
        fleet {
          nextToken
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const listGcadimeFleetAlerts = /* GraphQL */ `
  query ListGcadimeFleetAlerts(
    $filter: ModelgcadimeFleetAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGcadimeFleetAlerts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        createdAt
        updatedAt
        fleet {
          id
          fleetname
          tenant
          mapctr_lat
          mapctr_lng
          mapctr_zoom
          is_prime
          createdAt
          updatedAt
        }
        alert {
          id
          alertname
          tenant
          is_active
          alerttype
          alertJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeAlertDefault = /* GraphQL */ `
  query GetGcadimeAlertDefault($id: ID!) {
    getGcadimeAlertDefault(id: $id) {
      id
      alertname
      alerttype
      alertJSON
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listGcadimeAlertDefaults = /* GraphQL */ `
  query ListGcadimeAlertDefaults(
    $filter: ModelgcadimeAlertDefaultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGcadimeAlertDefaults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alertname
        alerttype
        alertJSON
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGcadimeAlert = /* GraphQL */ `
  query GetGcadimeAlert($id: ID!) {
    getGcadimeAlert(id: $id) {
      id
      alertname
      tenant
      is_active
      alerttype
      alertJSON
      timestamp
      createdAt
      updatedAt
      fleet {
        items {
          id
          tenant
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listGcadimeAlerts = /* GraphQL */ `
  query ListGcadimeAlerts(
    $filter: ModelgcadimeAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGcadimeAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        alertname
        tenant
        is_active
        alerttype
        alertJSON
        timestamp
        createdAt
        updatedAt
        fleet {
          nextToken
        }
        messages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeAlertMessage = /* GraphQL */ `
  query GetGcadimeAlertMessage($id: ID!) {
    getGcadimeAlertMessage(id: $id) {
      id
      tenant
      timestamp
      alertID
      alerttype
      vehicleID
      violationJSON
      message
      status
      lat
      lng
      placeID
      tz_offset
      routeday
      eventID
      createdAt
      updatedAt
      situation {
        situationid
        timestamp
        tenant
        situationtype
        deviceserialno
        vehicleID
        lat
        lng
        heading
        altitude
        tz_offset
        routeday
        datapoints
        speed
        speed_unit
        amberlamp
        max_speed
        max_gforce
        max_rotation
        vin
        s3key
        s3timestamp
        createdAt
        updatedAt
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alerts {
          nextToken
        }
        place {
          placeID
          speedlimit
          speedlimitunit
          address
          geocodeJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      vehicle {
        id
        vehiclename
        tenant
        vin
        driver
        make
        model
        year
        icon
        alerttype
        fleet_assignment
        last_updatetime
        createdAt
        updatedAt
        device {
          serialno
          MINTnbr
          REEDnbr
          tenant
          devicename
          default_fleetID
          lastcheckin
          lastcheckinS3
          last_MINTnbr
          last_REEDnbr
          createdAt
          updatedAt
        }
        last_situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        fleet {
          nextToken
        }
        last_alert {
          id
          tenant
          timestamp
          alertID
          alerttype
          vehicleID
          violationJSON
          message
          status
          lat
          lng
          placeID
          tz_offset
          routeday
          eventID
          createdAt
          updatedAt
        }
      }
      alert {
        id
        alertname
        tenant
        is_active
        alerttype
        alertJSON
        timestamp
        createdAt
        updatedAt
        fleet {
          nextToken
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const listGcadimeAlertMessages = /* GraphQL */ `
  query ListGcadimeAlertMessages(
    $id: ID
    $filter: ModelgcadimeAlertMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGcadimeAlertMessages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tenant
        timestamp
        alertID
        alerttype
        vehicleID
        violationJSON
        message
        status
        lat
        lng
        placeID
        tz_offset
        routeday
        eventID
        createdAt
        updatedAt
        situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alert {
          id
          alertname
          tenant
          is_active
          alerttype
          alertJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryAlertByAlertIdRouteDay = /* GraphQL */ `
  query QueryAlertByAlertIdRouteDay(
    $alertID: String
    $routeday: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelgcadimeAlertMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAlertByAlertIDRouteDay(
      alertID: $alertID
      routeday: $routeday
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        timestamp
        alertID
        alerttype
        vehicleID
        violationJSON
        message
        status
        lat
        lng
        placeID
        tz_offset
        routeday
        eventID
        createdAt
        updatedAt
        situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alert {
          id
          alertname
          tenant
          is_active
          alerttype
          alertJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryAlertByVehicleIdRouteDay = /* GraphQL */ `
  query QueryAlertByVehicleIdRouteDay(
    $vehicleID: String
    $routeday: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelgcadimeAlertMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAlertByVehicleIDRouteDay(
      vehicleID: $vehicleID
      routeday: $routeday
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        timestamp
        alertID
        alerttype
        vehicleID
        violationJSON
        message
        status
        lat
        lng
        placeID
        tz_offset
        routeday
        eventID
        createdAt
        updatedAt
        situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alert {
          id
          alertname
          tenant
          is_active
          alerttype
          alertJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryAlertByVehAlertRouteDay = /* GraphQL */ `
  query QueryAlertByVehAlertRouteDay(
    $vehicleID: String
    $alertIDRouteday: ModelgcadimeAlertMessageQueryAlertByVehAlertRouteDayCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelgcadimeAlertMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAlertByVehAlertRouteDay(
      vehicleID: $vehicleID
      alertIDRouteday: $alertIDRouteday
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        timestamp
        alertID
        alerttype
        vehicleID
        violationJSON
        message
        status
        lat
        lng
        placeID
        tz_offset
        routeday
        eventID
        createdAt
        updatedAt
        situation {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        vehicle {
          id
          vehiclename
          tenant
          vin
          driver
          make
          model
          year
          icon
          alerttype
          fleet_assignment
          last_updatetime
          createdAt
          updatedAt
        }
        alert {
          id
          alertname
          tenant
          is_active
          alerttype
          alertJSON
          timestamp
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeGps = /* GraphQL */ `
  query GetGcadimeGps($gpskey: String!) {
    getGcadimeGPS(gpskey: $gpskey) {
      gpskey
      lat
      lng
      address
      geocodeJSON
      timestamp
      placeID
      createdAt
      updatedAt
    }
  }
`;
export const listGcadimeGpSs = /* GraphQL */ `
  query ListGcadimeGpSs(
    $gpskey: String
    $filter: ModelgcadimeGPSFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGcadimeGPSs(
      gpskey: $gpskey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        gpskey
        lat
        lng
        address
        geocodeJSON
        timestamp
        placeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryGpSbyTimestamp = /* GraphQL */ `
  query QueryGpSbyTimestamp(
    $timestamp: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelgcadimeGPSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryGPSbyTimestamp(
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        gpskey
        lat
        lng
        address
        geocodeJSON
        timestamp
        placeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGcadimePlace = /* GraphQL */ `
  query GetGcadimePlace($placeID: String!) {
    getGcadimePlace(placeID: $placeID) {
      placeID
      speedlimit
      speedlimitunit
      address
      geocodeJSON
      timestamp
      createdAt
      updatedAt
      situtation {
        items {
          situationid
          timestamp
          tenant
          situationtype
          deviceserialno
          vehicleID
          lat
          lng
          heading
          altitude
          tz_offset
          routeday
          datapoints
          speed
          speed_unit
          amberlamp
          max_speed
          max_gforce
          max_rotation
          vin
          s3key
          s3timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listGcadimePlaces = /* GraphQL */ `
  query ListGcadimePlaces(
    $placeID: String
    $filter: ModelgcadimePlaceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGcadimePlaces(
      placeID: $placeID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        placeID
        speedlimit
        speedlimitunit
        address
        geocodeJSON
        timestamp
        createdAt
        updatedAt
        situtation {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getGcadimeReed = /* GraphQL */ `
  query GetGcadimeReed($REEDnbr: String!) {
    getGcadimeREED(REEDnbr: $REEDnbr) {
      REEDnbr
      REEDname
      MINTnbr
      tenant
      is_active
      filetype
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listGcadimeReeDs = /* GraphQL */ `
  query ListGcadimeReeDs(
    $REEDnbr: String
    $filter: ModelgcadimeREEDFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGcadimeREEDs(
      REEDnbr: $REEDnbr
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        REEDnbr
        REEDname
        MINTnbr
        tenant
        is_active
        filetype
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGcadimePid = /* GraphQL */ `
  query GetGcadimePid($globalID: Int!) {
    getGcadimePID(globalID: $globalID) {
      globalID
      uPIDid
      uPIDname
      scale
      offset
      units
      safeunits
      createdAt
      updatedAt
    }
  }
`;
export const listGcadimePiDs = /* GraphQL */ `
  query ListGcadimePiDs(
    $globalID: Int
    $filter: ModelgcadimePIDFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGcadimePIDs(
      globalID: $globalID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        globalID
        uPIDid
        uPIDname
        scale
        offset
        units
        safeunits
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
