// get/set to put state in session storage 
//   we like sessionStorage here so the user/login info only persists as long as the browser window is open
//   (use localStorage below if you want to persist the data forever)
export const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
}; 

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};

export const clearState = (state) => {
    try {
        sessionStorage.removeItem('state');
    } catch {
        // ignore write errors
    }
};

// Same code, but for LOCAL STORAGE  (persists forever in browser) 
/*
export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
}; 

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};

export const clearState = (state) => {
    try {
        localStorage.removeItem('state');
    } catch {
        // ignore write errors
    }
*/