//  Build src to map icon based on Type and Alert
//   - defaults to Green / Normal marker
//   - removed "IDLE" by commenting out all IDLE and CYAN (to add back, uncomment those)


import {MAIN_LANG} from './main_lang';
const msg_main = MAIN_LANG.EN; 

export const vehicleIcon = {
    // matches db enum gcadimeICONTYPE
    "BUS": {file: "bus.png", name: msg_main.MAP_ICON_BUS},
    "CAR": {file: "car.png", name: msg_main.MAP_ICON_CAR},
    "FXF": {file: "4x4.png", name: msg_main.MAP_ICON_4X4},
    "PICKUP": {file: "pickup.png", name: msg_main.MAP_ICON_PICKUP},
    "RESCUE": {file: "rescue.png", name: msg_main.MAP_ICON_RESCUE},
    "SPORTU": {file: "sportu.png", name: msg_main.MAP_ICON_SPORTU},
    "TRACTOR": {file: "tractor.png", name: msg_main.MAP_ICON_TRACTOR},
    "TRUCK": {file: "truck.png", name: msg_main.MAP_ICON_TRUCK},
    "VAN": {file: "van.png", name: msg_main.MAP_ICON_VAN},
};
export const vehicleAlertFolder = {
    // matches db enum gcadimeALERTTYPE
    "MAXSPEED": "warning",   //NOTE: color must match /images/map/icons/[COLORNAME] 
    "SPEEDING": "warning",
    "GEOFENCE": "warning",  // TEMPORARY FOR TESTING, change to "alert" so we can see different colors
    "HOURS": "warning",
    "HARD": "warning",
    "FAULT": "alert",
    "ACCIDENT": "alert",
//    "IDLE": "cyan",
    "NORMAL": "normal",
//    "OFF": "gray"
}
export const iconAlertColorImages = {
    // matches db enum gcadimeALERTTYPE to a color  
    "yellow": "yellow.png", 
//    "brown":  "brown.png",
    "alert":  "alert.png",
//    "gray":   "gray.png",
    "green":  "green.png",
//    "cyan":   "cyan.png",
}
export const iconAlertColorNames = {
    // matches colors used above in iconAlertColors
    "yellow": msg_main.MAP_ICON_COLOR_YELLOW, 
//    "brown":  msg_main.MAP_ICON_COLOR_BROWN,
    "alert":  msg_main.MAP_ICON_COLOR_ALERT,
//    "gray":   msg_main.MAP_ICON_COLOR_GRAY,
    "green":  msg_main.MAP_ICON_COLOR_GREEN,
//    "cyan":   msg_main.MAP_ICON_COLOR_CYAN,
}
export const iconAlertColorLegend = {
    // matches colors used above in iconAlertColors
    "yellow": msg_main.MAP_ICON_LEGEND_YELLOW, 
//    "brown":  msg_main.MAP_ICON_LEGEND_BROWN,
    "alert":  msg_main.MAP_ICON_LEGEND_ALERT,
//    "gray":   msg_main.MAP_ICON_LEGEND_GRAY,
    "green":  msg_main.MAP_ICON_LEGEND_GREEN,
//    "cyan":   msg_main.MAP_ICON_LEGEND_CYAN,
}
export const iconAlertColorValues = {
    // matches colors used above in iconAlertColors
    "yellow": "#ffaa00",  // ffaa00 is a bit darker  (old ffd900)
//    "brown":  "#d2691e",
    "alert":  "#f00a00",  // f00a00 a bit redder  (old ff1a00)
//    "gray":   "#AAAAAA",
    "green":  "#008000",
//    "cyan":   "#008b8b",
}
export const iconAlertColors = {
    // matches db enum gcadimeALERTTYPE
    "MAXSPEED": "yellow",   //NOTE: color must match /images/map/icons/[COLORNAME] 
    "SPEEDING": "yellow",
    "GEOFENCE": "yellow",  // TEMPORARY FOR TESTING, change to "alert" so we can see different colors
    "HOURS": "yellow",
    "HARD": "yellow",
    "FAULT": "alert",
    "ACCIDENT": "alert",
//    "IDLE": "cyan",
    "NORMAL": "green",
//    "OFF": "gray"
}
export const iconAlertTypeNames = {
    // matches db enum gcadimeALERTTYPE
    "MAXSPEED": msg_main.ALERT_TYPE_MAXSPEED,   //NOTE: color must match /images/map/icons/[COLORNAME] 
    "SPEEDING": msg_main.ALERT_TYPE_SPEEDING,
    "GEOFENCE": msg_main.ALERT_TYPE_GEOFENCE,  // TEMPORARY FOR TESTING, change to "alert" so we can see different colors
    "HOURS": msg_main.ALERT_TYPE_HOURS,
    "HARD": msg_main.ALERT_TYPE_HARD,
    "FAULT": msg_main.ALERT_TYPE_FAULT,
    "ACCIDENT": msg_main.ALERT_TYPE_ACCIDENT,
//    "IDLE": "Idle",
    "NORMAL": "",
//    "OFF": "Off"
}
export const iconAlertTypeNamesShort = {
    // matches db enum gcadimeALERTTYPE
    "MAXSPEED": msg_main.ALERT_TYPE_MAXSPEED_SHORT,   //NOTE: color must match /images/map/icons/[COLORNAME] 
    "SPEEDING": msg_main.ALERT_TYPE_SPEEDING_SHORT,
    "GEOFENCE": msg_main.ALERT_TYPE_GEOFENCE_SHORT,  // TEMPORARY FOR TESTING, change to "alert" so we can see different colors
    "HOURS": msg_main.ALERT_TYPE_HOURS_SHORT,
    "HARD": msg_main.ALERT_TYPE_HARD_SHORT,
    "FAULT": msg_main.ALERT_TYPE_FAULT_SHORT,
    "ACCIDENT": msg_main.ALERT_TYPE_ACCIDENT_SHORT,
//    "IDLE": "Idle",
    "NORMAL": "",
//    "OFF": "Off"
}

export const iconAlertColorSort = {
    // matches colors used above in iconAlertColors
    "yellow": 4, 
//    "brown":  3,
    "alert":  5,
//    "gray":   0,
    "green":  2,
//    "cyan":   1,
}
export const iconValidColors = {
    "alert":"alert",
//    "blue":"blue",
//    "brown":"brown",
//    "cyan":"cyan",
    "default":"default",
//    "gray":"gray",
    "green":"green",
//    "purple":"purple",
    "red":"red",
    "yellow":"yellow"
};
function getMapIcon(alert){
    //alert = values from gcadimeALERTTYPE
    const image = (iconAlertColors[alert]) ? iconAlertColorImages[iconAlertColors[alert]] : "default.png";
    return "/images/map/status/" + image;
}
export function getMapIconOrDark(alert, isDark){
    //alert = values from gcadimeALERTTYPE
    const image = (iconAlertColors[alert]) ? iconAlertColorImages[iconAlertColors[alert]] : "default.png";
    //const ordark_image = (isDark) ? "dark_" + image : image;
    //const ordark_image = (isDark) ? "gray_" + image : image;
    //the ridiculous golf-ball things
    const ordark_image = (isDark) ? "glfbl_" + image : image;
    return "/images/map/status/" + ordark_image;
}
export function getMapLegendIcon(color){
    //alert = values from gcadimeALERTTYPE
    const image = (iconAlertColorImages[color]) ? iconAlertColorImages[color] : "default.png";
    return "/images/map/legend/" + image;
}
//extra legend for crappy golf-ball things
export function getMapLegendDarkIcon(color){
    //alert = values from gcadimeALERTTYPE
    const image = (iconAlertColorImages[color]) ? iconAlertColorImages[color] : "default.png";
    return "/images/map/legend/glfbl_" + image;
}
export function getMapVehicleIcon(type, alert, dark){
    //type = values from icons/gcadimeICONTYPE
    //alert = values from gcadimeALERTTYPE
    //dark = (boolean) when vehicle dormant, get a darker version of the icon
    const icon = (vehicleIcon[type]) ? vehicleIcon[type].file : "default.png";
    const parentfolder = (dark===true) ? "dark" : "light";
    const statusfolder = (vehicleAlertFolder[alert]) ? vehicleAlertFolder[alert] : "default";
    return "/images/map/vehicons/" + parentfolder + "/" + statusfolder + "/" + icon;
}
// export function getMapIconByColor(type, color, reverse){
//     //type = values from icons/gcadimeICONTYPE
//     //color = send in a valid color from icons_validcolors (this becomes the sub-directory)
//     //reverse = (boolean) false gets you the maps overlay icon, true gets the image itself as the icon
//     const icon = (icons[type]) ? icons[type].file : "default.png";
//     const color2 = (iconValidColors[color]) ? iconValidColors[color] : "default";
//     const folder = (reverse) ? "reverse" : "icons";
//     return "/images/map/" + folder + "/" + color2 + "/" + icon;
// }
export function getMapIconStatusText(alert){
    //alert = values from gcadimeALERTTYPE
    const color = (iconAlertColors[alert]) ? iconAlertColors[alert] : "";
    const alerttext = (iconAlertColorNames[color]) ? iconAlertColorNames[color] : "";
    return alerttext;
}

export function getMapIconAlertTypeText(alert, short){
    //alert = values from gcadimeALERTTYPE
    if (short===true){
        const alerttextshort = (iconAlertTypeNamesShort[alert]) ? iconAlertTypeNamesShort[alert] : "";
        return alerttextshort;
    
    } else {
        const alerttext = (iconAlertTypeNames[alert]) ? iconAlertTypeNames[alert] : "";
        return alerttext;    
    }
}

// Other functions, for lack of a better place to put them
export function parseAlertJSONMessage(alert, alertJSON){
    //console.log("parseAlertJSONMessage", alertJSON);

    //alert = values from gcadimeALERTTYPE
    //
    // parse the JSON and return the condition message 
    //  note:  the "message" field in the DB is the full description sent in the email, 
    //         this is a shortened version customizable for the webapp.

    // JSON examples:  origin json format in gcadimeparse.saveFault, gcadimeparse.processAlert (SAM lambda functions)
    // MAXSPEED:  {"elid": 1, "rptmode": 0, "event_time": "2021-05-25 21:43:56.200536", "event": 1, "hour": 21, "min": 43, "sec": 56, "millisec": 200.5364135726458, "lat": 42.772545, "lng": -83.00951, "gpstime": "2021-05-25 21:43:56", "altitudeM": 225, "gspeedKPH": 2.2, "heading": 0.5, "satellites": 8, "hdop": 1.1, "tz_offset": -4.0, "trigger_id": 0, "trigger_descr": "Vehicle Speed over 75.1859 MPH, non-repeating", "trigger_alerttype": "MAXSPEED"}
    // SPEEDING:  TBD
    // GEOFENCE:
        // {'fencetype': 'IN', 'jsonname': 'knoxville', 'polygon': [{ 'lat': 36.181642, 'lng': -84.25259 }, { 'lat': 36.137998, 'lng': -83.478943 },{'lat': 35.788979, 'lng': -83.565466 }, {'lat': 35.792486, 'lng': -84.262889 } ] }
        // {'fencetype': 'OUT', 'jsonname': 'knoxville', 'polygon': [{ 'lat': 36.181642, 'lng': -84.25259 }, { 'lat': 36.137998, 'lng': -83.478943 },{'lat': 35.788979, 'lng': -83.565466 }, {'lat': 35.792486, 'lng': -84.262889 } ] }
    // HOURS:
        // {'hourtype': 'DAYLIMIT', 'limit': 8.0}
        // {'hourtype': 'ALLOW', 'hours': [8,9,10,11,12,13,14,15,16,17], 'condition': 3}    
    // HARD:  {"elid": 1, "rptmode": 0, "event_time": "2021-05-25 21:43:56.200536", "event": 1, "hour": 21, "min": 43, "sec": 56, "millisec": 200.5364135726458, "lat": 42.772545, "lng": -83.00951, "gpstime": "2021-05-25 21:43:56", "altitudeM": 225, "gspeedKPH": 2.2, "heading": 0.5, "satellites": 8, "hdop": 1.1, "tz_offset": -4.0, "trigger_id": 2, "trigger_descr": "RPM over 4000, non-repeating", "trigger_alerttype": "HARD"}
    // FAULT:
        // {'elid': elid, 'network': network, 'message': message, 'sm_message': sm_message, 'eventid': eventid}
    // ACCIDENT:  TBD
    
    if (!alertJSON){
        return "";
    }

    try {
        var msg="";
        const ajson = JSON.parse(alertJSON);
        //console.log("parseAlertJSONMessage ajson", ajson);
        

        switch (alert) {
            case 'GEOFENCE':  // see gcadimeparse.processAlert
                switch(ajson.fencetype){
                    case 'IN':
                        // {'fencetype': 'IN', 'jsonname': 'knoxville', 'polygon': [{ 'lat': 36.181642, 'lng': -84.25259 }, { 'lat': 36.137998, 'lng': -83.478943 },{'lat': 35.788979, 'lng': -83.565466 }, {'lat': 35.792486, 'lng': -84.262889 } ] }
                        if (ajson.condition){  //this is a violationJSON for a vehicle (because of the condition object)
                            msg = msg_main.ALERT_MGS_GEOFENCE_INSIDE + ": " + ajson.jsonname;
                        } else {
                            msg = msg_main.ALERT_MGS_GEOFENCE_INSIDE + ": " + ajson.jsonname;
                            if (ajson.circle){
                                const rad = metersToMilesKM(ajson.circle.radius, msg_main.DISTANCE_UNIT);
                                msg += ", \n" + msg_main.ALERT_MGS_GEOFENCE_RADIUS + ": " + rad + msg_main.DISTANCE_UNIT;
                            }
                        }
                        break;
                    case 'OUT':
                        // {'fencetype': 'OUT', 'jsonname': 'knoxville', 'polygon': [{ 'lat': 36.181642, 'lng': -84.25259 }, { 'lat': 36.137998, 'lng': -83.478943 },{'lat': 35.788979, 'lng': -83.565466 }, {'lat': 35.792486, 'lng': -84.262889 } ] }
                        if (ajson.condition){  //this is a violationJSON for a vehicle (because of the condition object)
                            msg = msg_main.ALERT_MGS_GEOFENCE_OUTSIDE + ": " + ajson.jsonname;
                        } else {
                            msg = msg_main.ALERT_MGS_GEOFENCE_OUTSIDE + ": " + ajson.jsonname;
                            if (ajson.circle){
                                const rad = metersToMilesKM(ajson.circle.radius, msg_main.DISTANCE_UNIT);
                                msg += ", \n" + msg_main.ALERT_MGS_GEOFENCE_RADIUS + ": " + rad + msg_main.DISTANCE_UNIT;
                            }                            
                        }
                        break;
                    default:
                        break;
                }
                break;
            
            case 'HOURS':  // see gcadimeparse.processAlert
            
                switch(ajson.hourtype){
                    case 'DAYLIMIT':
                        // {'hourtype': 'DAYLIMIT', 'limit': 8.0}
                        if (ajson.condition){  //this is a violationJSON for a vehicle (because of the condition object)
                            msg = msg_main.ALERT_MGS_HOURS_DAYLIMIT + ": " + Math.round(ajson.limit, 1) + " " + msg_main.ALERT_MGS_HOURS_DAYLIMIT_HOURS;
                        } else {
                            msg = msg_main.ALERT_MGS_HOURS_DAYLIMIT_SHRT + ": " + Math.round(ajson.limit, 1) + " " + msg_main.ALERT_MGS_HOURS_DAYLIMIT_HOURS;
                        }
                        break;
                    case 'ALLOW':
                        // {'hourtype': 'ALLOW', 'hours': [8,9,10,11,12,13,14,15,16,17], 'condition': 3}  
                        if (ajson.condition){  //this is a violationJSON for a vehicle (because of the condition object)
                            const intHour = parseInt(ajson.condition);
                            const vhour = (msg_main.FORMAT_HOUR_1224 === '12')
                                    ? ( (intHour > 12) ? (intHour-12) + "pm" : intHour + "am")
                                    : intHour ;
                            msg = msg_main.ALERT_MGS_HOURS_ALLOW + ": " + vhour;    
                        }  else {
                            msg = msg_main.ALERT_MGS_HOURS_SAFE + ": " + ajson.hours;
                        }
                        break;
                    default:
                        break;
                }                
                break;
          
            case 'FAULT':  // see gcadimeparse.saveFault
                // {'elid': elid, 'network': network, 'message': message, 'sm_message': sm_message, 'eventid': eventid}
                if (ajson.sm_message){
                    msg = msg_main.ALERT_MGS_FAULT + ": " + ajson.sm_message;  //sm_message is the shorter text-message desciption
                } else if (ajson.trigger_descr){
                    msg = msg_main.ALERT_MGS_TRIGGER + ": " + ajson.trigger_descr;  //sm_message is the shorter text-message desciption
                } else {
                    msg = msg_main.ALERT_MGS_FAULT;  //sm_message is the shorter text-message desciption
                }
                
                break;

            case 'MAXSPEED':
                //these mainly come from Triggers
                if (ajson.sm_message){
                    msg = msg_main.ALERT_TYPE_MAXSPEED + ": " + ajson.sm_message;  //sm_message is the shorter text-message desciption
                } else if (ajson.trigger_descr){
                    msg = msg_main.ALERT_TYPE_MAXSPEED + ": " + ajson.trigger_descr + " (Tr#" + ajson.trigger_id + ")";  //the main trigger text
                } else {
                    msg = msg_main.ALERT_TYPE_MAXSPEED;  
                }
                
                break;

            case 'HARD':
                //these mainly come from Triggers
                if (ajson.sm_message){
                    msg = msg_main.ALERT_TYPE_HARD + ": " + ajson.sm_message;  //sm_message is the shorter text-message desciption
                } else if (ajson.trigger_descr){
                    msg = msg_main.ALERT_TYPE_HARD + ": " + ajson.trigger_descr + " (Tr#" + ajson.trigger_id + ")";   //the main trigger text
                } else {
                    msg = msg_main.ALERT_TYPE_HARD; 
                }
                
                break;

            // TO DO
            case 'SPEEDING':
            case 'ACCIDENT':
            default:
                return "";
                //break;
        };

        return msg;

    } catch(e){
        console.log(e);
        return "";
    }
}

export function parseAlertJSONMessage2(alert, alertJSON, origAlertMsg){
    //console.log("parseAlertJSONMessage2", alert, alertJSON, origAlertMsg);

    // used on LiveMap popup
    //   pretty much the same as parseAlertJSONMessage, but includes the alert name, and original message if it can't figure something out

    //alert = values from gcadimeALERTTYPE
    //
    // parse the JSON and return the condition message 
    //  note:  the "message" field in the DB is the full description sent in the email, 
    //         this is a shortened version customizable for the webapp.

    // JSON examples:  origin json format in gcadimeparse.saveFault, gcadimeparse.processAlert (SAM lambda functions)
    // MAXSPEED:  {"elid": 1, "rptmode": 0, "event_time": "2021-05-25 21:43:56.200536", "event": 1, "hour": 21, "min": 43, "sec": 56, "millisec": 200.5364135726458, "lat": 42.772545, "lng": -83.00951, "gpstime": "2021-05-25 21:43:56", "altitudeM": 225, "gspeedKPH": 2.2, "heading": 0.5, "satellites": 8, "hdop": 1.1, "tz_offset": -4.0, "trigger_id": 0, "trigger_descr": "Vehicle Speed over 75.1859 MPH, non-repeating", "trigger_alerttype": "MAXSPEED"}
    // SPEEDING:  TBD
    // GEOFENCE:
        // {'fencetype': 'IN', 'jsonname': 'knoxville', 'polygon': [{ 'lat': 36.181642, 'lng': -84.25259 }, { 'lat': 36.137998, 'lng': -83.478943 },{'lat': 35.788979, 'lng': -83.565466 }, {'lat': 35.792486, 'lng': -84.262889 } ] }
        // {'fencetype': 'OUT', 'jsonname': 'knoxville', 'polygon': [{ 'lat': 36.181642, 'lng': -84.25259 }, { 'lat': 36.137998, 'lng': -83.478943 },{'lat': 35.788979, 'lng': -83.565466 }, {'lat': 35.792486, 'lng': -84.262889 } ] }
    // HOURS:
        // {'hourtype': 'DAYLIMIT', 'limit': 8.0}
        // {'hourtype': 'ALLOW', 'hours': [8,9,10,11,12,13,14,15,16,17], 'condition': 3}    
    // HARD:  {"elid": 1, "rptmode": 0, "event_time": "2021-05-25 21:43:56.200536", "event": 1, "hour": 21, "min": 43, "sec": 56, "millisec": 200.5364135726458, "lat": 42.772545, "lng": -83.00951, "gpstime": "2021-05-25 21:43:56", "altitudeM": 225, "gspeedKPH": 2.2, "heading": 0.5, "satellites": 8, "hdop": 1.1, "tz_offset": -4.0, "trigger_id": 2, "trigger_descr": "RPM over 4000, non-repeating", "trigger_alerttype": "HARD"}
    // FAULT:
        // {'elid': elid, 'network': network, 'message': message, 'sm_message': sm_message, 'eventid': eventid}
    // ACCIDENT:  TBD
    
    if (!alertJSON){
        return origAlertMsg;
    }

    try {
        var msg="";
        const ajson = JSON.parse(alertJSON);
        //console.log("parseAlertJSONMessage2 ajson", ajson);
        

        switch (alert) {
            case 'GEOFENCE':  // see gcadimeparse.processAlert
                switch(ajson.fencetype){
                    case 'IN':
                        // {'fencetype': 'IN', 'jsonname': 'knoxville', 'polygon': [{ 'lat': 36.181642, 'lng': -84.25259 }, { 'lat': 36.137998, 'lng': -83.478943 },{'lat': 35.788979, 'lng': -83.565466 }, {'lat': 35.792486, 'lng': -84.262889 } ] }
                        if (ajson.condition){  //this is a violationJSON for a vehicle (because of the condition object)
                            msg = msg_main.ALERT_MGS_GEOFENCE_INSIDE + ": " + ajson.jsonname;
                        } else {
                            msg = msg_main.ALERT_MGS_GEOFENCE_INSIDE + ": " + ajson.jsonname;
                            if (ajson.circle){
                                const rad = metersToMilesKM(ajson.circle.radius, msg_main.DISTANCE_UNIT);
                                msg += ", \n" + msg_main.ALERT_MGS_GEOFENCE_RADIUS + ": " + rad + msg_main.DISTANCE_UNIT;
                            }
                        }
                        break;
                    case 'OUT':
                        // {'fencetype': 'OUT', 'jsonname': 'knoxville', 'polygon': [{ 'lat': 36.181642, 'lng': -84.25259 }, { 'lat': 36.137998, 'lng': -83.478943 },{'lat': 35.788979, 'lng': -83.565466 }, {'lat': 35.792486, 'lng': -84.262889 } ] }
                        if (ajson.condition){  //this is a violationJSON for a vehicle (because of the condition object)
                            msg = msg_main.ALERT_MGS_GEOFENCE_OUTSIDE + ": " + ajson.jsonname;
                        } else {
                            msg = msg_main.ALERT_MGS_GEOFENCE_OUTSIDE + ": " + ajson.jsonname;
                            if (ajson.circle){
                                const rad = metersToMilesKM(ajson.circle.radius, msg_main.DISTANCE_UNIT);
                                msg += ", \n" + msg_main.ALERT_MGS_GEOFENCE_RADIUS + ": " + rad + msg_main.DISTANCE_UNIT;
                            }                            
                        }
                        break;
                    default:
                        break;
                }
                break;
            
            case 'HOURS':  // see gcadimeparse.processAlert
            
                switch(ajson.hourtype){
                    case 'DAYLIMIT':
                        // {'hourtype': 'DAYLIMIT', 'limit': 8.0}
                        msg = (ajson.jsonname) ? ajson.jsonname + " \n" : "";
                        if (ajson.condition){  //this is a violationJSON for a vehicle (because of the condition object)
                            msg += msg_main.ALERT_MGS_HOURS_DAYLIMIT + ": " + Math.round(ajson.limit, 1) + " " + msg_main.ALERT_MGS_HOURS_DAYLIMIT_HOURS;
                        } else {
                            msg += msg_main.ALERT_MGS_HOURS_DAYLIMIT_SHRT + ": " + Math.round(ajson.limit, 1) + " " + msg_main.ALERT_MGS_HOURS_DAYLIMIT_HOURS;
                        }
                        break;
                    case 'ALLOW':
                        // {'hourtype': 'ALLOW', 'hours': [8,9,10,11,12,13,14,15,16,17], 'condition': 3}  
                        msg = (ajson.jsonname) ? ajson.jsonname + " \n" : "";
                        if (ajson.condition){  //this is a violationJSON for a vehicle (because of the condition object)
                            const intHour = parseInt(ajson.condition);
                            const vhour = (msg_main.FORMAT_HOUR_1224 === '12')
                                    ? ( (intHour > 12) ? (intHour-12) + "pm" : intHour + "am")
                                    : intHour ;
                            msg += msg_main.ALERT_MGS_HOURS_ALLOW + ": " + vhour;    
                        }  else {
                            msg += msg_main.ALERT_MGS_HOURS_SAFE + ": " + ajson.hours;
                        }
                        break;
                    default:
                        break;
                }                
                break;
          
            case 'FAULT':  // see gcadimeparse.saveFault
                // {'elid': elid, 'network': network, 'message': message, 'sm_message': sm_message, 'eventid': eventid}
                if (ajson.sm_message){
                    //used for Legacy faults (rptmode==1)
                    msg = msg_main.ALERT_MGS_FAULT + ": " + ajson.sm_message;  //sm_message is the shorter text-message desciption
                } else if (ajson.trigger_descr){
                    msg = msg_main.ALERT_MGS_TRIGGER + ": " + ajson.trigger_descr; 
                } else {
                    //ODBII faults (rptmode==0) have dtc_fault_desc field in the violationJSON, but let's just use the main message send to this function (which is really the long message: DTC Code + DTC description)
                    msg = msg_main.ALERT_MGS_FAULT + ": " + origAlertMsg; 
                }
                
                break;

            case 'MAXSPEED':
                //these mainly come from Triggers
                if (ajson.sm_message){
                    msg = msg_main.ALERT_TYPE_MAXSPEED + ": " + ajson.sm_message;  //sm_message is the shorter text-message desciption
                } else if (ajson.trigger_descr){
                    msg = msg_main.ALERT_TYPE_MAXSPEED + ": " + ajson.trigger_descr + " (Tr#" + ajson.trigger_id + ")";  //the main trigger text
                } else {
                    msg = msg_main.ALERT_TYPE_MAXSPEED;  
                }
                
                break;

            case 'HARD':
                //these mainly come from Triggers
                if (ajson.sm_message){
                    msg = msg_main.ALERT_TYPE_HARD + ": " + ajson.sm_message;  //sm_message is the shorter text-message desciption
                } else if (ajson.trigger_descr){
                    msg = msg_main.ALERT_TYPE_HARD + ": " + ajson.trigger_descr + " (Tr#" + ajson.trigger_id + ")";   //the main trigger text
                } else {
                    msg = msg_main.ALERT_TYPE_HARD; 
                }
                
                break;
    
            // TO DO
            case 'SPEEDING':
            case 'ACCIDENT':
            default:
                return origAlertMsg;
                //break;
        };

        return msg;

    } catch(e){
        console.log(e);
        return origAlertMsg
    }
}

export function parseAlertJSONHasBuffer(alert, alertJSON){

    if (alert==='FAULT'){
        return true;
    }

    // all other types, see if there is a buffer
    try{
        const ajson = JSON.parse(alertJSON);
        if (ajson.hasbuffer){
            return true;
        }
    
    } catch(e){
        console.log(e);
    }
    return false;
}

function metersToMilesKM(m, unit){

    if (unit==="mi"){
        // convert meters to miles
        return ((parseFloat(m) * 0.000621371).toFixed(1));
    } else {
        // convert meters to kilometers
        return ((parseFloat(m)/1000.0).toFixed(1));
    }
}

export default getMapIcon;
